import React from 'react';
import {
    ArchiveOutlined,
    ContactSupportOutlined,
    DashboardOutlined,
    EditOutlined,
    FilePresentOutlined,
    PersonOutlineOutlined,
    AccountBoxOutlined,
    EuroOutlined,
    LocalShippingOutlined,
    MailOutlined,
    ListAltOutlined,
    LocalOfferOutlined,
    QuizOutlined,
    PrivacyTipOutlined,
    RuleOutlined,
    InfoOutlined,
    Inventory2Outlined,
    ArticleOutlined, LocationCityOutlined, ContactsOutlined,
} from '@mui/icons-material';
import Dashboard from '../components/pages/Dashboard';
import LayoutRelease from '../components/pages/LayoutRelease';
import Orders from '../components/pages/Orders';
import Account from '../components/pages/Account';
import AccountPersonalInformations from '../components/pages/AccountPersonalInformations';
import AccountBillingAddress from '../components/pages/AccountBillingAddress';
import AccountDeliveryAddress from '../components/pages/AccountDeliveryAddress';
import AccountRecipientEmails from '../components/pages/AccountRecipientEmails';
import ProductSample from '../components/pages/ProductSample';
import Offer from '../components/pages/Offer';
import Contact from '../components/pages/Contact';
import ChooseClient from '../components/pages/ChooseClient';
import PrivacyPolicy from '../components/pages/PrivacyPolicy';
import BadgeCountLayoutRelease from '../components/badgeCount/BadgeCountLayoutRelease';
import InquirySuccess from '../components/pages/InquirySuccess';
import LayoutReleaseDetail from '../components/pages/LayoutReleaseDetail';
import OrdersOpen from '../components/pages/OrdersOpen';
import OrdersClosed from '../components/pages/OrdersClosed';
import ArticleOverview from "../components/pages/ArticleOverview";
import AccountCompanyData from "../components/pages/AccountCompanyData";
import Quotes from "../components/pages/Quotes";
import QuotesOpen from "../components/pages/QuotesOpen";
import QuotesClosed from "../components/pages/QuotesClosed";
import AccountCompanyContacts from '../components/pages/AccountCompanyContacts';

export const routeDashboard = {
    'key': 'dashboard',
    'label': 'customerportal.navigation.dashboard',
    'url': '/dashboard',
    'routingUrl': '/:locale/dashboard',
    'icon': <DashboardOutlined/>,
    'exact': true,
    'component': <Dashboard/>,
};

export const routeLayoutRelease = {
    'key': 'layoutRelease',
    'label': 'customerportal.navigation.layout_release',
    'url': '/layout-release',
    'routingUrl': '/:locale/layout-release',
    'icon': <EditOutlined/>,
    'exact': true,
    'badgeCount': BadgeCountLayoutRelease,
    'component': <LayoutRelease/>,
};

export const routeLayoutReleaseDetail = {
    'key': 'layoutRelease',
    'label': 'customerportal.navigation.layout_release',
    'url': '/layout-release/:correctionDrawingId',
    'routingUrl': '/:locale/layout-release/:correctionDrawingId',
    'icon': <EditOutlined/>,
    'exact': false,
    'badgeCount': BadgeCountLayoutRelease,
    'component': <LayoutReleaseDetail/>,
};

export const routeArticleOverview = {
    'key': 'articleOverview',
    'label': 'customerportal.navigation.article_overview',
    'url': '/article-overview',
    'routingUrl': '/:locale/article-overview',
    'icon': <ArticleOutlined/>,
    'exact': true,
    'component': <ArticleOverview/>,
};

export const routeQuotesClosed = {
    'key': 'quotesClosed',
    'label': 'customerportal.navigation.quotes_closed',
    'url': '/quotes/closed',
    'routingUrl': '/:locale/quotes/closed',
    'icon': <FilePresentOutlined/>,
    'exact': false,
    'component': <QuotesClosed/>,
};

export const routeQuotesOpen = {
    'key': 'quotesOpen',
    'label': 'customerportal.navigation.quotes_open',
    'url': '/quotes/open',
    'routingUrl': '/:locale/quotes/open',
    'icon': <FilePresentOutlined/>,
    'exact': false,
    'component': <QuotesOpen/>,
};

export const routeQuotes = {
    'key': 'quotes',
    'label': 'customerportal.navigation.quotes',
    'url': '/quotes',
    'routingUrl': '/:locale/quotes',
    'icon': <QuizOutlined/>,
    'exact': false,
    'childPages': [routeQuotesOpen, routeQuotesClosed],
    'component': <Quotes/>,
};

export const routeOrdersOpen = {
    'key': 'ordersOpen',
    'label': 'customerportal.navigation.orders_open',
    'url': '/orders/open',
    'routingUrl': '/:locale/orders/open',
    'icon': <ListAltOutlined/>,
    'exact': false,
    'component': <OrdersOpen/>,
};

export const routeOrdersClosed = {
    'key': 'ordersClosed',
    'label': 'customerportal.navigation.orders_closed',
    'url': '/orders/closed',
    'routingUrl': '/:locale/orders/closed',
    'icon': <ArchiveOutlined/>,
    'exact': false,
    'component': <OrdersClosed/>,
};

export const routeOrders = {
    'key': 'orders',
    'label': 'customerportal.navigation.orders',
    'url': '/orders',
    'routingUrl': '/:locale/orders',
    'icon': <FilePresentOutlined/>,
    'exact': false,
    'childPages': [routeOrdersOpen, routeOrdersClosed],
    'component': <Orders/>,
};

export const routeAccountPersonalInformation = {
    'key': 'accountPersonal',
    'label': 'customerportal.account.personal_information',
    'url': '/account/personal',
    'routingUrl': '/:locale/account/personal',
    'icon': <AccountBoxOutlined/>,
    'exact': false,
    'component': <AccountPersonalInformations/>,
};

export const routeAccountDeliveryAddress = {
    'key': 'accountDeliveryAddress',
    'label': 'customerportal.account.delivery_address',
    'url': '/account/company-data/delivery-address',
    'routingUrl': '/:locale/account/company-data/delivery-address',
    'icon': <LocalShippingOutlined/>,
    'exact': false,
    'component': <AccountDeliveryAddress/>,
};

export const routeAccountBillingAddress = {
    'key': 'accountBillingAddress',
    'label': 'customerportal.account.billing_address',
    'url': '/account/company-data/billing-address',
    'routingUrl': '/:locale/account/company-data/billing-address',
    'icon': <EuroOutlined/>,
    'exact': false,
    'component': <AccountBillingAddress/>,
};

export const routeAccountCompanyContacts = {
    'key': 'accountContacts',
    'label': 'customerportal.account.contacts',
    'url': '/account/company-data/contacts',
    'routingUrl': '/:locale/account/company-data/contacts',
    'icon': <ContactsOutlined/>,
    'exact': false,
    'component': <AccountCompanyContacts/>,
};

export const routeAccountRecipientEmails = {
    'key': 'accountRecipientEmails',
    'label': 'customerportal.account.recipient_emails',
    'url': '/account/recipient-emails',
    'routingUrl': '/:locale/account/recipient-emails',
    'icon': <MailOutlined/>,
    'exact': false,
    'component': <AccountRecipientEmails/>,
};

export const routeProductSample = {
    'key': 'productSample',
    'label': 'customerportal.navigation.product_sample',
    'url': '/product-sample',
    'routingUrl': '/:locale/product-sample',
    'icon': <Inventory2Outlined/>,
    'exact': false,
    'component': <ProductSample/>,
};

export const routeOffer = {
    'key': 'offer',
    'label': 'customerportal.navigation.offer',
    'url': '/offer',
    'routingUrl': '/:locale/offer',
    'icon': <LocalOfferOutlined/>,
    'exact': false,
    'component': <Offer/>,
};

export const routeContact = {
    'key': 'contact',
    'label': 'customerportal.navigation.contact',
    'url': '/contact',
    'routingUrl': '/:locale/contact',
    'icon': <ContactSupportOutlined/>,
    'exact': false,
    'component': <Contact/>,
};

export const routeAccountCompanyData = {
    'key': 'companyData',
    'label': 'customerportal.navigation.company_data',
    'url': '/account/company-data',
    'routingUrl': '/:locale/account/company-data',
    'icon': <LocationCityOutlined/>,
    'exact': false,
    'component': <AccountCompanyData/>,
};

export const routeAccount = {
    'key': 'account',
    'label': 'customerportal.navigation.account',
    'url': '/account',
    'routingUrl': '/:locale/account',
    'icon': <PersonOutlineOutlined/>,
    'exact': true,
    'childPages': [routeAccountPersonalInformation, routeAccountCompanyData],
    'component': <Account/>,
};

export const routeChooseClient = {
    'key': 'chooseClient',
    'label': 'customerportal.navigation.choose_client',
    'url': '/choose-client',
    'routingUrl': '/:locale/choose-client',
    'icon': <RuleOutlined/>,
    'exact': false,
    'component': <ChooseClient/>,
};

export const routePrivacyPolicy = {
    'key': 'privacyPolicy',
    'label': 'customerportal.navigation.privacy_policy',
    'url': 'customerportal.privacy_policy',
    'icon': <PrivacyTipOutlined/>,
    'exact': false,
    'component': <PrivacyPolicy/>,
};

export const routeImprint = {
    'key': 'imprint',
    'label': 'customerportal.navigation.imprint',
    'url': 'customerportal.imprint',
    'icon': <InfoOutlined/>,
    'exact': false,
    'component': <Account/>,
};

export const routeInquirySuccess = {
    'key': 'inquirySuccess',
    'label': 'customerportal.navigation.inquiry_success',
    'url': '/inquiry-success',
    'routingUrl': '/:locale/inquiry-success',
    'icon': false,
    'exact': false,
    'component': <InquirySuccess/>,
};

export const groupSideNav = [routeDashboard, routeLayoutRelease, routeArticleOverview, routeQuotes, routeOrders, routeAccount, routeProductSample, routeOffer, routeContact];
export const groupSideNavMore = [routePrivacyPolicy, routeImprint];
export const groupBottomNav = [routeDashboard, routeLayoutRelease, routeOrders, routeAccount];
export const groupBottomNavMore = [routeArticleOverview, routeQuotes, routeProductSample, routeOffer, routeContact];
export const groupBottomNavMoreExternal = [routePrivacyPolicy, routeImprint];
export const groupDashboardSubSites = [routeLayoutRelease, routeArticleOverview, routeQuotes, routeOrders, routeAccount, routeProductSample, routeOffer ];
export const groupAccountSubSites = [routeAccountPersonalInformation, routeAccountCompanyData];
export const groupAccountCompanyDataSubSites = [routeAccountCompanyContacts, routeAccountDeliveryAddress, routeAccountBillingAddress, routeAccountRecipientEmails];
export const groupOrderSubSites = [routeOrdersOpen, routeOrdersClosed];
export const groupQuotesSubSites = [routeQuotesOpen, routeQuotesClosed];
export const allRoutes = [routeChooseClient, routeInquirySuccess, routeLayoutReleaseDetail].concat(groupSideNav, groupSideNavMore, groupAccountSubSites, groupAccountCompanyDataSubSites, groupOrderSubSites, groupQuotesSubSites);
