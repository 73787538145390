import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './locales/de.json';
import en from './locales/en.json';

export const supportedLngs = ['de', 'en', 'cs', 'pl'];
export const fallbackLng = 'en';

let translations = {
    de: {
        translation: de,
    },
    en: {
        translation: en,
    },
    cs: {
        translation: en,
    },
    pl: {
        translation: en,
    }
};

const decodeLinks = {
    type: 'postProcessor',
    name: 'decodeLinks',
    process(value) {
        return value.replace(/\[(\/?\d+)]/g, "<$1>");
    },
};

export function init(trans = translations) {
    i18n
        .use(initReactI18next)
        .use(decodeLinks)
        .init({
            load: 'languageOnly',
            fallbackLng: fallbackLng,
            lng: supportedLngs.includes(navigator.language?.split('-')[0]) ? navigator.language.split('-')[0] : fallbackLng,
            supportedLngs: supportedLngs,
            interpolation: {
                escapeValue: false,
                formatSeparator: '.',
            },
            react: {
                transEmptyNodeValue: '',
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'a'],
            },
            resources: trans,
            postProcess: ['decodeLinks']
        });
}

export default i18n;
