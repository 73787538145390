import React from 'react';
import {Grid, Typography, Link, Paper} from "@mui/material";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {Link as RouterLink} from "react-router-dom";
import i18n, {t} from "i18next";

function IconLinkList(props) {
    return (
        <Grid container
              spacing={1.25}
        >
            {props.items.map((item, i) => (
                <Grid item
                      key={item.key}
                      xs={6}
                >
                    <Box component={item.badgeCount ? item.badgeCount : 'div'} sx={{
                        height: '100%'
                    }}>
                        <Paper elevation={1}
                               sx={{
                                   height: '100%',
                                   '&:hover': {
                                       boxShadow: 3
                                   }
                               }}
                        >
                            <Link component={RouterLink}
                                  to={`/${i18n.language}${item.url}`}
                                  underline="none"
                                  sx={{
                                      display: 'flex',
                                      flexDirection: {
                                          xs: 'column',
                                          lg: 'row'
                                      },
                                      alignItems: {
                                          lg: 'center'
                                      },
                                      justifyContent: {
                                          xs: 'center',
                                          lg: 'flex-start'
                                      },
                                      px: {
                                          xs: 1,
                                          md: 2
                                      },
                                      py: 1,
                                      height: '100%',
                                  }}
                            >
                                <Box sx={{
                                    marginRight: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: {
                                        xs: 0.5,
                                        lg: 0
                                    },
                                    '& svg': {
                                        backgroundColor: 'primary.main',
                                        color: 'common.white',
                                        padding: 0.25,
                                        borderRadius: 0.5,
                                        width: 30,
                                        height: 30
                                    }
                                }}>
                                    {item.icon}
                                </Box>
                                <Typography variant="subtitle1" sx={{
                                    wordBreak: 'break-word'
                                }}>
                                    {t(item.label)}
                                </Typography>
                            </Link>
                        </Paper>
                    </Box>
                </Grid>
            ))}
        </Grid>
    )
}

IconLinkList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        icon: PropTypes.element.isRequired,
        label: PropTypes.string.isRequired
    })).isRequired
}

export default IconLinkList;