import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link, Typography} from '@mui/material';
import {ArrowRightAltOutlined} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {useMsal} from '@azure/msal-react';
import Box from '@mui/material/Box';

function SwitchClient() {
    const {t, i18n} = useTranslation();
    const {accounts} = useMsal();

    if (accounts[0] && accounts[0].idTokenClaims.extension_Mandanten?.split('|').length > 1) {
        return (
            <Box sx={(theme) => ({
                position: 'absolute',
                right: theme.spacing(3.5),
                top: theme.spacing(1.5),
                display: {
                    xs: 'none',
                    md: 'block'
                }
            })}>
                <Link underline="hover"
                      to={`/${i18n.language}/choose-client`}
                      component={RouterLink}
                      sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                      }}
                >
                    <Typography variant={'body2'}
                                sx={{
                                    paddingRight: 0.5,
                                }}
                    >
                        {t('customerportal.switch_client.label')}
                    </Typography>
                    <ArrowRightAltOutlined/>
                </Link>
            </Box>
        );
    }
}

export default SwitchClient;