import React, {useState} from 'react';
import {Box, Button, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import OrderArticleTableHead from './OrderArticleTableHead';
import OrderArticleItem from './OrderArticleItem';
import colors from '../../theme/colors';
import {FilePresentOutlined, InfoOutlined} from '@mui/icons-material';
import DialogAddress from './DialogAddress';
import DialogGetOffer from './DialogGetOffer';
import {ORDER_TYPE_CLOSE, ORDER_TYPE_OPEN, QUOTE_TYPE_CLOSED, QUOTE_TYPE_OPEN} from '../../config/orderConfig';
import DialogDocuments from './DialogDocuments';
import DialogChangeDeliveryDate from './DialogChangeDeliveryDate';

function OrderItemDetails({orderItem, orderType, searchValue}) {
    const {t} = useTranslation();
    const articles = orderItem.articles;
    const [deliveryAddressDialogOpen, setDeliveryAddressDialogOpen] = useState(false);
    const [billingAddressDialogOpen, setBillingAddressDialogOpen] = useState(false);
    const [changeDeliveryDateDialogOpen, setChangeDeliveryDateDialogOpen] = useState(false);
    const [dialogDocumentsOpen, setDialogDocumentsOpen] = useState(false);
    const [getOfferDialogOpen, setGetOfferDialogOpen] = useState(false);

    const handleClickDocuments = () => {
        setDialogDocumentsOpen(true);
    };

    const handleDialogDocumentsClose = () => {
        setDialogDocumentsOpen(false);
    };

    const handleClickDeliveryAddress = () => {
        setDeliveryAddressDialogOpen(true);
    };

    const handleDeliveryAddressDialogClose = () => {
        setDeliveryAddressDialogOpen(false);
    };

    const handleClickBillingAddress = () => {
        setBillingAddressDialogOpen(true);
    };

    const handleBillingAddressDialogClose = () => {
        setBillingAddressDialogOpen(false);
    };

    const handleClickChangeDeliveryDate = () => {
        setChangeDeliveryDateDialogOpen(true);
    };

    const handleChangeDeliveryDateDialogClose = () => {
        setChangeDeliveryDateDialogOpen(false);
    };

    const handleClickGetOffer = () => {
        setGetOfferDialogOpen(true);
    };

    const handleGetOfferDialogClose = () => {
        setGetOfferDialogOpen(false);
    };

    const border = (
        <Box sx={{marginY: 1, borderBottom: `1px solid ${colors.white}`}}>
            <></>
        </Box>
    );

    return (
        <>
            <Grid container sx={{flexWrap: 'nowrap'}}>

                <Grid item xs={12} sx={{flexGrow: 1, minWidth: '100%'}}>

                    {articles && articles.length > 0 ? (
                        <>
                            <OrderArticleTableHead orderType={orderType}/>

                            {border}

                            <Grid container rowSpacing={1}>
                                {articles.map((article, key) => (
                                    <Grid key={key}
                                          item
                                          xs={12}>
                                        <Box sx={{
                                            backgroundColor: searchValue === article.articleNumber ? 'secondary.main' : 'transparent'
                                        }}>
                                            <OrderArticleItem article={article}
                                                              orderType={orderType}/>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    ) : (
                        <Typography variant={'body2'}>{t('customerportal.order.no_articles')}</Typography>
                    )}

                    {border}

                    <Box sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            lg: 'row',
                        },
                        gap: {
                            xs: 1.75,
                            lg: 0.75,
                        },
                        justifyContent: 'space-between',
                    }}>
                        <Box>
                            <Button onClick={handleClickDeliveryAddress} startIcon={<InfoOutlined/>} sx={{marginRight: 1}}>
                                {t('customerportal.order.delivery_address')}
                            </Button>

                            <Button onClick={handleClickBillingAddress} startIcon={<InfoOutlined/>}>
                                {t('customerportal.order.billing_address')}
                            </Button>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                lg: 'row',
                            },
                            gap: 0.75,
                        }}>
                            {orderType === ORDER_TYPE_OPEN && (
                                <Button onClick={handleClickChangeDeliveryDate}
                                        color={'secondary'}
                                        variant={'contained'}>
                                    {t('customerportal.order.change_delivery_date')}
                                </Button>
                            )}

                            {(orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) && (
                                <Button onClick={handleClickDocuments} startIcon={<FilePresentOutlined/>}
                                        color={'secondary'}
                                        variant={'contained'}>
                                    {t('customerportal.order.show_documents')}
                                </Button>
                            )}


                            {(orderType === ORDER_TYPE_OPEN || orderType === ORDER_TYPE_CLOSE || orderType === QUOTE_TYPE_CLOSED) && (
                                <Button onClick={handleClickGetOffer}
                                        color={'secondary'}
                                        variant={'contained'}>
                                    {t('customerportal.order.get_offer')}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Grid>

                <Grid item>
                    <Box sx={{width: '24px'}}>
                        <>
                            {/*icon placeholder*/}
                        </>
                    </Box>
                </Grid>

            </Grid>


            {dialogDocumentsOpen && (
                <DialogDocuments order={orderItem}
                                 open={dialogDocumentsOpen}
                                 orderType={orderType}
                                 handleClose={handleDialogDocumentsClose}/>
            )}

            {deliveryAddressDialogOpen && (
                <DialogAddress
                    title={t('customerportal.order.delivery_address')}
                    address={orderItem.deliveryAddress}
                    open={deliveryAddressDialogOpen}
                    handleClose={handleDeliveryAddressDialogClose}/>
            )}

            {billingAddressDialogOpen && (
                <DialogAddress
                    title={t('customerportal.order.billing_address')}
                    address={orderItem.billingAddress}
                    open={billingAddressDialogOpen}
                    handleClose={handleBillingAddressDialogClose}/>
            )}


            {changeDeliveryDateDialogOpen && (
                <DialogChangeDeliveryDate
                    title={t('customerportal.order.change_delivery_date')}
                    item={orderItem}
                    open={changeDeliveryDateDialogOpen}
                    handleClose={handleChangeDeliveryDateDialogClose}/>
            )}

            {getOfferDialogOpen && (
                <DialogGetOffer
                    title={t('customerportal.order.get_offer')}
                    item={orderItem}
                    open={getOfferDialogOpen}
                    handleClose={handleGetOfferDialogClose}/>
            )}

        </>

    );
}

export default OrderItemDetails;
