import {createAsyncThunk} from '@reduxjs/toolkit';
import Api from '../../api/Api';

export const sendFeedback = createAsyncThunk('pimcore-custom/send-feedback', async (payload = false, {rejectWithValue}) => {
    try {
        await Api.sendFeedback(payload.formData);
    } catch (err) {
        return rejectWithValue({
            errorMessage: err.response.data.detail,
        }, {});
    }
});
