import React from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {Box, Paper} from '@mui/material';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import Api from "../../api/Api";

function PdfThumbnail({file, fileUrl}) {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const handleClickLayout = () => {
        window.location.assign(`${Api.endpoints.getFileContent}&DocumentURL=${fileUrl}`);
    };


    return (
        <Paper elevation={1}
               sx={{
                   overflow: 'hidden',
                   cursor: 'pointer',
                   '&:hover': {
                       boxShadow: 3
                   }
               }}
        >
            <Document file={`data:application/pdf;base64,${file}`} onClick={handleClickLayout}>
                <Box component={Page}
                     pageNumber={1}
                     sx={{
                         'canvas': {
                             width: '100% !important',
                             height: '100% !important',
                         },
                     }}
                     renderTextLayer={false}/>
            </Document>
        </Paper>
    );
}

export default PdfThumbnail;