import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import colors from '../../theme/colors';
import {ORDER_TYPE_CLOSE, ORDER_TYPE_OPEN, QUOTE_TYPE_CLOSED, QUOTE_TYPE_OPEN} from '../../config/orderConfig';

function OrderTableHead({orderType}) {
    const {t} = useTranslation();

    return (
        <Grid container sx={{paddingX: 1}}>
            <Grid item sx={{flexGrow: 1}}>

                <Grid container columnSpacing={2} alignItems="flex-end">

                    <Grid item lg={2.5} xl={2}>
                        <Typography variant="body2" color={colors.primary900}>
                            {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_title' : 'customerportal.order.order_title')}
                        </Typography>
                    </Grid>

                    <Grid item lg={1.5}>
                        <Typography variant="body2" color={colors.primary900}>
                            {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_date' : 'customerportal.order.order_date')}
                        </Typography>
                    </Grid>

                    <Grid item lg={1.5}>
                        <Typography variant="body2" color={colors.primary900}>
                            {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_number' : 'customerportal.order.order_number')}
                        </Typography>
                    </Grid>

                    {orderType === ORDER_TYPE_OPEN &&
                        <Grid item lg={1.5} xl={1.5}>
                            <Typography variant="body2" color={colors.primary900} sx={{textAlign: 'right'}}>
                                {t('customerportal.order.order_total')}
                            </Typography>
                        </Grid>
                    }

                    {(orderType === ORDER_TYPE_OPEN || orderType === ORDER_TYPE_CLOSE) ?
                        <Grid item lg={2} xl={1.5} sx={{alignSelf: 'end', marginLeft: 'auto'}}>
                            <Typography variant="body2" color={colors.primary900}>
                                {t('customerportal.order.track_delivery.legend')}
                            </Typography>
                        </Grid> :
                        <Grid item lg={1.5} xl={4} sx={{display: {xs: 'none', lg: 'block'}}}>
                            <></>
                        </Grid>
                    }

                    <Grid item lg={2} xl={1.5} sx={{alignSelf: 'end'}}>
                        <Typography variant="body2" color={colors.primary900}>
                            {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.valid_till' : 'customerportal.order.show_documents.legend')}
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>
            <Grid item>
                <Box sx={{width: '24px'}}>
                    <>
                        {/*icon placeholder*/}
                    </>
                </Box>
            </Grid>
        </Grid>
    );
}

export default OrderTableHead;
