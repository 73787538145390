import React from "react";
import PropTypes from 'prop-types';
import {formRowTypes} from "../../config/form/formRowTypes";
import FormText from "./FormText";
import FormSelect from "./FormSelect";
import FormCheckbox from "./FormCheckbox";
import FormFileUpload from "./FormFileUpload";
import FormDatePicker from './FormDatePicker';

function GenerateFormField({field}) {
    switch (field.type) {
        case formRowTypes.text:
        case formRowTypes.number:
            return (
                <FormText field={field} />
            )
        case formRowTypes.select:
            return (
                <FormSelect field={field} />
            )
        case formRowTypes.checkbox:
            return (
                <FormCheckbox field={field} />
            )
        case formRowTypes.fileUpload:
            return (
                <FormFileUpload field={field} />
            )
        case formRowTypes.datePicker:
            return (
                <FormDatePicker field={field} />
            )
        default:
            return;
    }
}

GenerateFormField.propTypes = {
    field: PropTypes.shape({
        rowSize: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        labelExtended: PropTypes.string,
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
            PropTypes.any
        ]),
        disabled: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.any.isRequired
        }))
    }).isRequired
}

export default GenerateFormField;