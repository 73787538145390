import {createSlice} from "@reduxjs/toolkit";
import {getPwaAdBanner, getPwaMessage} from "../actions/data";

const initialState = {
    pwaMessage: {
        loading: false,
        message:  null,
        error: {}
    },
    pwaAdBanner: {
        loading: false,
        banner: null,
        error: {}
    }
};

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getPwaMessage.pending, (state) => {
                state.pwaMessage.loading = true;
            })
            .addCase(getPwaMessage.fulfilled, (state, action) => {
                state.pwaMessage.loading = false;
                state.pwaMessage.message = action.payload.message;
            })
            .addCase(getPwaMessage.rejected, (state, action) => {
                state.pwaMessage.loading = false;
                state.pwaMessage.error = action.payload.error;
            })
            .addCase(getPwaAdBanner.pending, (state) => {
                state.pwaAdBanner.loading = true;
            })
            .addCase(getPwaAdBanner.fulfilled, (state, action) => {
                state.pwaAdBanner.loading = false;
                state.pwaAdBanner.banner = action.payload.banner;
            })
            .addCase(getPwaAdBanner.rejected, (state, action) => {
                state.pwaAdBanner.loading = false;
                state.pwaAdBanner.error = action.payload.error;
            });
    }
});

export default dataSlice.reducer;