import React from "react";
import {Box, Divider, Grid, Paper, Skeleton} from "@mui/material";

function ContactPersonSkeletonItem({bigImg, col}) {
    return (
        <Grid item
              xs={12}
              md={col ? 6 : 12}
        >
            <Paper elevation={1} sx={{
                paddingY: 1.25,
                paddingX: 1.5
            }}>
                <Grid container spacing={1.25}>
                    <Grid item lg={12} xl={'auto'}>
                        <Skeleton variant='circular'
                                  animation={'wave'}
                                  sx={{
                                      width: {
                                          xs: 80,
                                          lg: bigImg ? 80 : 40
                                      },
                                      height: {
                                          xs: 80,
                                          lg: bigImg ? 80 : 40
                                      }
                                  }}
                        />
                    </Grid>
                    <Grid item sx={{
                        flex: 1
                    }}>
                        <div>
                            <Skeleton variant="text" animation={'wave'} sx={{fontSize: 'body1'}}/>
                            <Skeleton variant="text" animation={'wave'} sx={{fontSize: 'body1'}}/>
                        </div>
                        <Skeleton variant="text"
                                  animation={'wave'}
                                  sx={{
                                      fontSize: 'body1',
                                      marginTop: 0.5
                                  }}
                        />
                        <Skeleton variant="text" animation={'wave'} sx={{fontSize: 'body1'}}/>
                    </Grid>
                </Grid>
                <Divider sx={{
                    paddingTop: 1.25
                }}/>
                <Box sx={{
                    paddingTop: 1.25,
                    textAlign: 'center'
                }}>
                    <Skeleton variant="text" animation={'wave'} sx={{fontSize: 'body1'}}/>
                </Box>
            </Paper>
        </Grid>
    )
}

export default ContactPersonSkeletonItem;