export function isIos() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
}

export function isAndroid() {
    return /(android)/.test(navigator.userAgent);
}

export function isInStandaloneMode() {
    return ('standalone' in window.navigator) && (window.navigator.standalone);
}

export function getAppVersion() {
    return process.env.REACT_APP_VERSION || '';
}

export function getBuildDate() {
    return process.env.REACT_APP_BUILD_TIME || '';
}

export function getBrowserLanguage() {
    return window.navigator.language;
}

export function getOS() {
    return window.navigator.platform;
}