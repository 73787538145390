import colors from './colors';
import {createTheme} from "@mui/material/styles";

export const themeName = 'default theme';

export const spacing = 16;

export const breakpoints = {
    values: {
        xs: 0,
        ty: 480,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1600,
    }
};

const defaultTheme = createTheme({breakpoints});

export const typography = {
    fontFamily: [
        '"Garet-Regular"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
    ].join(','),
    htmlFontSize: 16,
    button: {
        fontWeight: 400,
        textTransform: 'none'
    },
    h1: {
        fontSize: 49,
        [defaultTheme.breakpoints.down('sm')]: {
            fontSize: 30
        },
        fontWeight: 500,
        lineHeight: 1.25,
        fontFamily: [
            '"Garet-Bold"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),    },
    h2: {
        fontSize: 28,
        [defaultTheme.breakpoints.down('sm')]: {
            fontSize: 22
        },
        fontWeight: 400,
        lineHeight: 1.25,
        fontFamily: [
            '"Garet-Bold"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    },
    h3: {
        fontSize: 21,
        fontWeight: 400,
        lineHeight: 1.25,
        fontFamily: [
            '"Garet-Bold"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    },
    subtitle1: {
        fontFamily: [
            '"Garet-Bold"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
        fontSize: 16,
        [defaultTheme.breakpoints.down('sm')]: {
            fontSize: 14
        },
        fontWeight: 400,
        lineHeight: 1.5
    },
    subtitle2: {
        fontFamily: [
            '"Garet-Bold"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
        fontWeight: 400,
        fontSize: 14
    },
    subtitle3: {
        fontFamily: [
            '"Garet-Regular"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
        fontWeight: 600,
        fontSize: 16,
        lineHeight: 1.5
    },
    body1: {
        fontSize: 16
    },
    body2: {
        fontSize: 14
    },
    fileUploadText: {
        fontSize: 12,
        color: colors.primary700
    },
    bottomNav: {
        fontSize: 14
    }
};

export const palette = (client) => ({
    type: 'light',
    primary: {
        main: colors.primary.main,
        dark: colors.primary.dark,
        600: colors.primary600,
        700: colors.primary700,
        800: colors.primary800,
    },
    white: {
        main: colors.white
    },
    secondary: {
        main: client === 'rathgeber' ? colors.secondary : colors.yellow,
        contrastText: client === 'rathgeber' ? colors.white : colors.black,
    },
    grey: colors.grey,
    greyBlue: colors.greyBlue,
    blue: colors.blue,
    success: colors.success,
    warning: colors.warning,
    error: colors.error,
    background: {default: colors.white}
});

export const shape = {
};

export const props = {
};
