import {createSlice} from '@reduxjs/toolkit';
import {
    changeClient,
    getContactPersons,
    getCorrectionDrawingDetail,
    getCorrectionDrawings,
    getCorrectionDrawingsCount,
    getCustomerAddresses,
    getOrders,
    getRecipientEmails,
    getUser,
    sendCorrectionDrawingDetail,
    getOrderDocuments,
    getOrderDeliveries,
    getPossibleSalutations,
    getPossiblePositions,
    updateUser, getCompanyData,
} from '../actions/azure';
import {QUOTE_TYPE_OPEN, QUOTE_TYPE_CLOSED, ORDER_TYPE_OPEN, ORDER_TYPE_CLOSE} from '../../config/orderConfig';

const initialState = {
    client: '',
    azureClient: '',
    contactPersons: [],
    user: null,
    userFormState: {
        isLoading: false,
        success: false,
        hasError: false,
        errorMessage: '',
    },
    customerAddresses: {
        deliveryAddress: null,
        billingAddress: null,
    },
    correctionDrawings: {
        items: null,
        loading: false,
        count: 0,
    },
    correctionDrawingsForm: [
        {
            key: 'layout',
            data: null,
            isValid: true,
        },
        {
            key: 'description',
            data: null,
            isValid: false,
        },
        {
            key: 'release',
            data: null,
            isValid: false,
        },
    ],
    correctionDrawingDetail: {
        redirect: false,
        loading: false,
        item: null,
        error: null,
        sending: true,
        sentSuccess: false,
    },
    recipientEmails: {
        items: null,
        loading: false,
    },
    clientShouldRedirect: false,
    clientLoading: false,
    orders: {
        [QUOTE_TYPE_OPEN]: {
            items: null,
            loading: false,
            count: 0,
        },
        [QUOTE_TYPE_CLOSED]: {
            items: null,
            loading: false,
            count: 0,
        },
        [ORDER_TYPE_OPEN]: {
            items: null,
            loading: false,
            count: 0,
        },
        [ORDER_TYPE_CLOSE]: {
            items: null,
            loading: false,
            count: 0,
        },
    },
    orderDocuments: {
        items: [],
        loading: false,
        count: 0,
    },
    orderDeliveries: {
        items: [],
        loading: false,
        count: 0,
    },
    getOfferForm: {
        loading: false,
        success: false,
        hasError: false,
        errorMessage: '',
    },
    companyData: null,
    possibleSalutations: [],
    possiblePositions: [],
};

const azureSlice = createSlice({
    name: 'azure',
    initialState,
    reducers: {
        setClient: (state, action) => {
            state.client = action.payload.client;
            state.azureClient = action.payload.azureClient;
        },
        resetChooseClient: (state, action) => {
            state.clientShouldRedirect = false;
        },
        resetCorrectionDrawingDetail: (state, action) => {
            state.correctionDrawingDetail.item = null;
            state.correctionDrawingDetail.loading = false;
            state.correctionDrawingDetail.redirect = false;
            state.correctionDrawingDetail.sending = false;
            state.correctionDrawingDetail.sentSuccess = false;
            state.correctionDrawingDetail.error = null;
        },
        resetCorrectionDrawingDetailError: (state, action) => {
            state.correctionDrawingDetail.error = null;
        },
        resetUserForm: (state, action) => {
            state.userFormState.isLoading = false;
            state.userFormState.success = false;
            state.userFormState.hasError = false;
            state.userFormState.errorMessage = '';
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getContactPersons.pending, (state) => {
            })
            .addCase(getContactPersons.fulfilled, (state, action) => {
                state.contactPersons = action.payload.contactPersons;
            })
            .addCase(getContactPersons.rejected, (state) => {
                state.contactPersons = [];
            })
            .addCase(getUser.pending, (state) => {
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.user = action.payload.user;
            })
            .addCase(getUser.rejected, (state) => {
                state.user = null;
            })
            .addCase(getCustomerAddresses.pending, (state) => {
            })
            .addCase(getCustomerAddresses.fulfilled, (state, action) => {
                state.customerAddresses.deliveryAddress = action.payload.customerAddresses.deliveryAddress;
                state.customerAddresses.billingAddress = action.payload.customerAddresses.billingAddress;
            })
            .addCase(getCustomerAddresses.rejected, (state) => {
                state.customerAddresses.deliveryAddress = null;
                state.customerAddresses.billingAddress = null;
            })
            .addCase(getRecipientEmails.pending, (state) => {
                state.recipientEmails.loading = true;
            })
            .addCase(getRecipientEmails.fulfilled, (state, action) => {
                state.recipientEmails.items = action.payload.recipientEmails;
                state.recipientEmails.loading = false;
            })
            .addCase(getRecipientEmails.rejected, (state) => {
                state.recipientEmails.items = null;
                state.recipientEmails.loading = false;
            })
            .addCase(changeClient.pending, (state) => {
                state.clientLoading = true;
            })
            .addCase(changeClient.fulfilled, (state, action) => {
                state.clientLoading = false;
                state.clientShouldRedirect = true;
                state.client = action.payload;
            })
            .addCase(changeClient.rejected, (state) => {
                state.clientLoading = false;
            })
            .addCase(getCorrectionDrawingsCount.pending, (state) => {
                state.correctionDrawings.loading = true;
            })
            .addCase(getCorrectionDrawingsCount.fulfilled, (state, action) => {
                state.correctionDrawings.loading = false;
                state.correctionDrawings.count = action.payload;
            })
            .addCase(getCorrectionDrawingsCount.rejected, (state) => {
                state.correctionDrawings.loading = false;
                state.correctionDrawings.count = 0;
            })
            .addCase(getCorrectionDrawings.pending, (state) => {
                state.correctionDrawings.loading = true;
            })
            .addCase(getCorrectionDrawings.fulfilled, (state, action) => {
                state.correctionDrawings.loading = false;
                state.correctionDrawings.items = action.payload;
            })
            .addCase(getCorrectionDrawings.rejected, (state) => {
                state.correctionDrawings.loading = false;
                state.correctionDrawings.items = null;
            })
            .addCase(getCorrectionDrawingDetail.pending, (state) => {
                state.correctionDrawingDetail.loading = true;
            })
            .addCase(getCorrectionDrawingDetail.fulfilled, (state, action) => {
                state.correctionDrawingDetail.loading = false;
                state.correctionDrawingDetail.redirect = false;
                state.correctionDrawingDetail.item = action.payload;
            })
            .addCase(getCorrectionDrawingDetail.rejected, (state) => {
                state.correctionDrawingDetail.loading = false;
                state.correctionDrawingDetail.redirect = true;
                state.correctionDrawingDetail.item = null;
            })
            .addCase(sendCorrectionDrawingDetail.pending, (state) => {
                state.correctionDrawingDetail.sending = true;
            })
            .addCase(sendCorrectionDrawingDetail.fulfilled, (state, action) => {
                state.correctionDrawingDetail.sending = false;
                state.correctionDrawingDetail.sentSuccess = true;
                state.correctionDrawingDetail.error = null;
            })
            .addCase(sendCorrectionDrawingDetail.rejected, (state, action) => {
                state.correctionDrawingDetail.sending = false;
                state.correctionDrawingDetail.sentSuccess = false;
                state.correctionDrawingDetail.error = action.payload;
            })
            .addCase(getOrders.pending, (state, action) => {
                state.orders[action.meta.arg.orderType].loading = true;
            })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.orders[action.meta.arg.orderType].items = action.payload;
                state.orders[action.meta.arg.orderType].loading = false;
                state.orders[action.meta.arg.orderType].count = action.payload.length;
            })
            .addCase(getOrders.rejected, (state, action) => {
                state.orders[action.meta.arg.orderType].items = [];
                state.orders[action.meta.arg.orderType].loading = false;
                state.orders[action.meta.arg.orderType].count = 0;
            })
            .addCase(getOrderDocuments.pending, (state, action) => {
                state.orderDocuments.loading = true;
            })
            .addCase(getOrderDocuments.fulfilled, (state, action) => {
                state.orderDocuments.items.push(action.payload);
                state.orderDocuments.loading = false;
                state.orderDocuments.count = action.payload.length;
            })
            .addCase(getOrderDocuments.rejected, (state, action) => {
                state.orderDocuments.items = [];
                state.orderDocuments.loading = false;
                state.orderDocuments.count = 0;
            })
            .addCase(getOrderDeliveries.pending, (state, action) => {
                state.orderDeliveries.loading = true;
            })
            .addCase(getOrderDeliveries.fulfilled, (state, action) => {
                state.orderDeliveries.items.push(action.payload);
                state.orderDeliveries.loading = false;
                state.orderDeliveries.count = action.payload.length;
            })
            .addCase(getOrderDeliveries.rejected, (state, action) => {
                state.orderDeliveries.items = [];
                state.orderDeliveries.loading = false;
                state.orderDeliveries.count = 0;
            })
            .addCase(getPossibleSalutations.fulfilled, (state, action) => {
                state.possibleSalutations = action.payload.salutations;
            })
            .addCase(getPossiblePositions.fulfilled, (state, action) => {
                state.possiblePositions = action.payload.positions;
            })
            .addCase(getCompanyData.fulfilled, (state, action) => {
                state.companyData = action.payload;
            })
            .addCase(updateUser.pending, (state, action) => {
                state.userFormState.loading = true;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.userFormState.loading = false;
                state.userFormState.errorMessage = '';
                state.userFormState.hasError = false;
                state.userFormState.success = true;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.userFormState.loading = false;
                state.userFormState.errorMessage = action.payload.error;
                state.userFormState.hasError = true;
                state.userFormState.success = false;
            });
    },
});

export const {
    setClient,
    resetChooseClient,
    resetCorrectionDrawingDetail,
    resetCorrectionDrawingDetailError,
    resetUserForm,
} = azureSlice.actions;
export default azureSlice.reducer;
