import React, {useMemo} from 'react';
import {MsalAuthenticationTemplate, useMsal, useMsalAuthentication} from '@azure/msal-react';
import {InteractionStatus, InteractionType} from '@azure/msal-browser';
import {loginRequest} from '../config/authConfig';
import {Provider} from 'react-redux';
import {store} from '../redux/store';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import {useTranslation} from 'react-i18next';
import generateTheme from "../theme/theme";

function AppWrapper() {
    const {instance, inProgress} = useMsal();
    const [client, setClient] = React.useState('rathgeber');
    const {i18n} = useTranslation();
    const {error} = useMsalAuthentication(
        InteractionType.Redirect,
    );

    const theme = useMemo(() => generateTheme(client), [client])

    if (error) {
        if (inProgress === InteractionStatus.None) {
            instance.loginRedirect(loginRequest(i18n.language));
        }
    } else {
        return (
            <ThemeProvider theme={theme}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}
                                            authenticationRequest={loginRequest(navigator.language || i18n.language)}>
                    <Provider store={store}>
                        <CssBaseline/>
                        <Router>
                            <App updateClient={(client) => setClient(client || 'rathgeber')}/>
                        </Router>
                    </Provider>
                </MsalAuthenticationTemplate>
            </ThemeProvider>
        );
    }
}

export default AppWrapper;