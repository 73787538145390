import React from 'react';
import {Grid} from '@mui/material';
import PageWrapper from '../utils/PageWrapper';
import IconLinkList from '../ui/IconLinkList';
import {groupAccountSubSites} from '../../config/routeItems';
import Supervisor from '../contactPersons/Supervisor';
import LogoutButton from '../ui/LogoutButton';

function Account() {

    return (
        <PageWrapper siteTitle={'customerportal.account.site_title'} switchClient={true}>
            <Grid container
                  columnSpacing={5}
                  rowSpacing={2.5}
            >
                <Grid item
                      xs={12}
                      lg={8}
                >
                    <IconLinkList items={groupAccountSubSites}/>
                </Grid>
                <Grid item
                      xs={12}
                      lg={4}
                      sx={{
                          position: 'relative',
                      }}
                >
                    <Supervisor/>
                </Grid>

                <LogoutButton/>

            </Grid>
        </PageWrapper>
    );
}

export default Account;
