import React from 'react';
import {Grid, Typography} from '@mui/material';
import PageWrapper from '../utils/PageWrapper';
import OrderList from '../orders/OrderList';
import {ORDER_TYPE_CLOSE, ORDER_VIEW_ACCORDION} from '../../config/orderConfig';
import ContactPersonList from '../contactPersons/ContactPersonList';
import {useTranslation} from 'react-i18next';
import {groupOrderSubSites, routeOrders} from '../../config/routeItems';
import SubpageNavigation from '../ui/SubpageNavigation';

function OrdersClosed() {
    const {t} = useTranslation();

    return (
        <PageWrapper siteTitle={'customerportal.navigation.orders_closed'} backRoute={routeOrders}>
            <Grid container columnSpacing={2} rowSpacing={4}>
                <Grid item xs={12} xl={9}>
                    <SubpageNavigation items={groupOrderSubSites}/>
                    <OrderList orderType={ORDER_TYPE_CLOSE} view={ORDER_VIEW_ACCORDION}/>
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                    <Typography variant="h3" sx={{marginBottom: 1}}>
                        {t('customerportal.account.my_contact')}
                    </Typography>
                    <ContactPersonList
                        selection={['supervisor']}
                        bigImg={true}
                        skeletonCount={1}
                    />
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export default OrdersClosed;
