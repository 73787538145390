import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import FormSection from '../form/FormSection';
import FormRow from '../form/FormRow';
import {formRowSizes} from '../../config/form/formRowSizes';
import FormFieldsSkeleton from '../skeletons/FormFieldSkeleton';
import {formRowTypes} from '../../config/form/formRowTypes';
import {FormProvider, useForm} from 'react-hook-form';
import {getCompanyData} from '../../redux/actions/azure';
import Box from '@mui/material/Box';
import Api from '../../api/Api';
import {useMsal} from '@azure/msal-react';

function CompanyDataForm(props) {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const methods = useForm({});
    const companyData = useSelector((state) => state.azure.companyData);
    const billingAddress = useSelector((state) => state.azure.customerAddresses.billingAddress);
    const deliveryAddress = useSelector((state) => state.azure.customerAddresses.deliveryAddress);

    const {instance, accounts} = useMsal();

    useEffect(() => {
        Api.acquireToken(instance, accounts[0]).then((res) => {
            dispatch(getCompanyData(i18n.language));
        });
        // eslint-disable-next-line
    }, []);

    if (companyData) {
        return (
            <>
                <FormProvider {...methods}>
                    <form noValidate onSubmit={null}>
                        <Box sx={{
                            mb: 0.4,
                            mt: 2,
                        }}>
                            <Typography variant={'subtitle2'}>
                                {t('customerportal.form.company_data')}
                            </Typography>
                        </Box>
                        <FormSection>
                            <FormRow field={{
                                rowSize: formRowSizes.small,
                                label: 'customerportal.form.debtor_number',
                                defaultValue: companyData.debtorNumber,
                                disabled: true,
                                id: 'debtorNumber',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: formRowSizes.small,
                                label: 'customerportal.form.delivery_number',
                                defaultValue: companyData.deliveryNumber,
                                disabled: true,
                                id: 'deliveryNumber',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.company_name',
                                defaultValue: companyData.companyName,
                                disabled: true,
                                id: 'companyName',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.street',
                                defaultValue: companyData.street,
                                disabled: true,
                                id: 'street',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: 3,
                                label: 'customerportal.form.country',
                                defaultValue: companyData.country,
                                disabled: true,
                                id: 'country',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: 2,
                                label: 'customerportal.form.zip',
                                defaultValue: companyData.zip,
                                disabled: true,
                                id: 'zip',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: 7,
                                label: 'customerportal.form.place',
                                defaultValue: companyData.city,
                                disabled: true,
                                id: 'city',
                                type: formRowTypes.text,
                            }}/>

                        </FormSection>

                        <Box sx={{
                            mb: 0.4,
                            mt: 2,
                        }}>
                            <Typography variant={'subtitle2'}>
                                {t('customerportal.form.contact_person')}
                            </Typography>
                        </Box>
                        <FormSection>
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.main_contact',
                                defaultValue: companyData.contactPerson.name,
                                disabled: true,
                                id: 'contactPersonName',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.email',
                                defaultValue: companyData.contactPerson.email,
                                disabled: true,
                                id: 'contactPersonEmail',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.website',
                                defaultValue: companyData.website,
                                disabled: true,
                                id: 'contactPersonWebsite',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.phone',
                                defaultValue: companyData.phone,
                                disabled: true,
                                id: 'contactPersonPhone',
                                type: formRowTypes.text,
                            }}/>
                        </FormSection>

                        <Box sx={{
                            mb: 0.4,
                            mt: 2,
                        }}>
                            <Typography variant={'subtitle2'}>
                                {t('customerportal.form.delivery')}
                            </Typography>
                        </Box>
                        <FormSection>
                            <FormRow field={{
                                rowSize: formRowSizes.half,
                                label: 'customerportal.form.standard_delivery_way',
                                defaultValue: companyData.deliveryData.standardDeliveryWay,
                                disabled: true,
                                id: 'standardDeliveryWay',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: formRowSizes.half,
                                label: 'customerportal.form.standard_delivery_condition',
                                defaultValue: companyData.deliveryData.standardDeliveryCondition,
                                disabled: true,
                                id: 'standardDeliveryCondition',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.company_name',
                                defaultValue: companyData.companyName,
                                disabled: true,
                                id: 'companyName',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.street',
                                defaultValue: deliveryAddress.street,
                                disabled: true,
                                id: 'street',
                                type: formRowTypes.text,
                            }}/>

                            {deliveryAddress.country && (
                                <FormRow field={{
                                    rowSize: 3,
                                    label: 'customerportal.form.country',
                                    defaultValue: deliveryAddress.country,
                                    disabled: true,
                                    id: 'country',
                                    type: formRowTypes.text,
                                }}/>
                            )}

                            <FormRow field={{
                                rowSize: 2,
                                label: 'customerportal.form.zip',
                                defaultValue: deliveryAddress.zip,
                                disabled: true,
                                id: 'zip',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: 7,
                                label: 'customerportal.form.place',
                                defaultValue: deliveryAddress.place,
                                disabled: true,
                                id: 'city',
                                type: formRowTypes.text,
                            }}/>

                        </FormSection>

                        <Box sx={{
                            mb: 0.4,
                            mt: 2,
                        }}>
                            <Typography variant={'subtitle2'}>
                                {t('customerportal.form.billing')}
                            </Typography>
                        </Box>
                        <FormSection>
                            <FormRow field={{
                                rowSize: formRowSizes.half,
                                label: 'customerportal.form.payment_terms',
                                defaultValue: companyData.paymentTerms,
                                disabled: true,
                                id: 'paymentTerms',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.company_name',
                                defaultValue: companyData.companyName,
                                disabled: true,
                                id: 'companyName',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.street',
                                defaultValue: billingAddress.street,
                                disabled: true,
                                id: 'street',
                                type: formRowTypes.text,
                            }}/>

                            {billingAddress.country && (
                                <FormRow field={{
                                    rowSize: 3,
                                    label: 'customerportal.form.country',
                                    defaultValue: billingAddress.country,
                                    disabled: true,
                                    id: 'country',
                                    type: formRowTypes.text,
                                }}/>
                            )}

                            <FormRow field={{
                                rowSize: 2,
                                label: 'customerportal.form.zip',
                                defaultValue: billingAddress.zip,
                                disabled: true,
                                id: 'zip',
                                type: formRowTypes.text,
                            }}/>

                            <FormRow field={{
                                rowSize: 7,
                                label: 'customerportal.form.place',
                                defaultValue: billingAddress.place,
                                disabled: true,
                                id: 'city',
                                type: formRowTypes.text,
                            }}/>

                        </FormSection>
                    </form>
                </FormProvider>
            </>
        );
    } else {
        return (
            <FormFieldsSkeleton count={6}/>
        );
    }
}

export default CompanyDataForm;