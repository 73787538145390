import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {resetAddContactDialog} from "../../redux/slices/companyContacts";
import React, {useEffect} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import Alert from "@mui/material/Alert";
import FormSection from "../form/FormSection";
import FormRow from "../form/FormRow";
import {formRowSizes} from "../../config/form/formRowSizes";
import {formRowTypes} from "../../config/form/formRowTypes";
import {getPossiblePositions, getPossibleSalutations} from "../../redux/actions/azure";
import {addCompanyContact} from "../../redux/actions/companyContacts";

function AddCompanyContactModal({title, open, handleClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {success, isLoading, hasError, errorMessage} = useSelector((state) => state.companyContacts.addContactForm);
    const possiblePositions = useSelector((state) => state.azure.possiblePositions);
    const possibleSalutations = useSelector((state) => state.azure.possibleSalutations);
    const methods = useForm({
        defaultValues: {
            salutation: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            leadership: false,
            position: '',
        },
    });

    const onSubmit = (form) => {
        dispatch(addCompanyContact({
            formData: form,
        }));
    };

    const resetFormState = () => {
        dispatch(resetAddContactDialog());
    };

    useEffect(() => {
        if (!possibleSalutations?.length) {
            dispatch(getPossibleSalutations());
        }
        if (!possiblePositions?.length) {
            dispatch(getPossiblePositions());
        }

        resetFormState();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!open) {
            resetFormState();
        }
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            onClose={handleClose}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {!success ? (
                    <>
                        {hasError && (
                            <Alert severity="error" sx={{width: '100%'}} onClose={resetFormState}>
                                <Typography mr={1} component={'strong'}>{t('customerportal.general.server_error')}</Typography>
                                {errorMessage && (
                                    <Typography component={'code'} style={{fontFamily: 'monospace'}}>{errorMessage}</Typography>
                                )}
                            </Alert>
                        )}
                        <FormProvider {...methods}>
                            <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                                <FormSection>
                                    {possibleSalutations?.length ? (
                                        <FormRow field={{
                                            rowSize: formRowSizes.small,
                                            label: 'customerportal.form.salutation',
                                            disabled: isLoading,
                                            id: 'salutation',
                                            type: formRowTypes.select,
                                            rules: {
                                                required: {
                                                    value: true,
                                                    message: 'customerportal.form.validation.required',
                                                },
                                            },
                                            options: possibleSalutations.map((salutation) => ({
                                                value: salutation.Code,
                                                label: `customerportal.salutation.${salutation.Code}`,
                                            })),
                                        }}/>
                                    ) : <></>}

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.firstname',
                                        disabled: isLoading,
                                        id: 'firstname',
                                        rules: {
                                            required: {
                                                value: true,
                                                message: 'customerportal.form.validation.required',
                                            },
                                        },
                                        type: formRowTypes.text,
                                    }}/>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.lastname',
                                        disabled: isLoading,
                                        id: 'lastname',
                                        rules: {
                                            required: {
                                                value: true,
                                                message: 'customerportal.form.validation.required',
                                            },
                                        },
                                        type: formRowTypes.text,
                                    }}/>

                                    {possiblePositions?.length ? (
                                        <FormRow field={{
                                            rowSize: formRowSizes.half,
                                            label: 'customerportal.form.position',
                                            disabled: isLoading,
                                            id: 'position',
                                            type: formRowTypes.select,
                                            options: possiblePositions.map((position) => ({
                                                value: position.Code,
                                                label: `customerportal.position.${position.Code}`,
                                            })),
                                        }}/>
                                    ) : <></>}

                                    <FormRow field={{
                                        rowSize: formRowSizes.half,
                                        label: 'customerportal.form.leadership',
                                        alignCenter: true,
                                        multiline: false,
                                        disabled: isLoading,
                                        id: 'leadership',
                                        type: formRowTypes.checkbox,
                                    }}/>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.phone',
                                        disabled: isLoading,
                                        id: 'phone',
                                        rules: {
                                            required: {
                                                value: true,
                                                message: 'customerportal.form.validation.required',
                                            },
                                        },
                                        type: formRowTypes.text,
                                    }}/>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.mail',
                                        disabled: isLoading,
                                        id: 'email',
                                        rules: {
                                            required: {
                                                value: true,
                                                message: 'customerportal.form.validation.required',
                                            },
                                        },
                                        type: formRowTypes.text,
                                    }}/>

                                </FormSection>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexWrap: {
                                        xs: 'wrap',
                                        md: 'nowrap',
                                    },
                                    mt: 2,
                                    gap: {
                                        xs: 1,
                                        md: 1.5,
                                    },
                                }}>
                                    <Button variant="contained"
                                            type="submit"
                                            disabled={isLoading}
                                            color="secondary">
                                        {t('customerportal.form.create_new_contact.submit')}
                                    </Button>
                                </Box>
                            </form>
                        </FormProvider>
                    </>
                ) : (
                    <Box>
                        <Typography variant={'h1'}
                                    component={'h2'}>
                            {t('customerportal.company_contacts.add_contact_modal.success.title')}
                        </Typography>
                        <Typography variant={'body1'}>
                            {t('customerportal.company_contacts.add_contact_modal.success.text')}
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            {success && (
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        {t('customerportal.dialog.close')}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}

export default AddCompanyContactModal;