import {configureStore} from '@reduxjs/toolkit';
import layoutSlice from './slices/layout';
import azureSlice from './slices/azure';
import dataSlice from './slices/data';
import inquirySlice from './slices/inquiry';
import feedbackSlice from './slices/feedback';
import offerFormSlice from './slices/offerForm';
import lleFormSlice from './slices/lleForm';
import deliveryDateFormSlice from './slices/deliveryDateForm';
import productsSlice from './slices/products';
import companyContactsSlice from './slices/companyContacts';

export const store = configureStore({
    reducer: {
        layout: layoutSlice,
        azure: azureSlice,
        data: dataSlice,
        inquiry: inquirySlice,
        offerForm: offerFormSlice,
        lleForm: lleFormSlice,
        deliveryDateForm: deliveryDateFormSlice,
        feedback: feedbackSlice,
        products: productsSlice,
        companyContacts: companyContactsSlice,
    },
});
