import React, {useEffect} from 'react';
import {useLocation, Outlet} from 'react-router-dom';
import {motion} from 'framer-motion';

function PageAnimationLayout() {
    const {pathname} = useLocation();

    useEffect(() => {
        document.querySelector('main').scrollTo(0, 0);

        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview'
            });
        }
    }, [pathname]);

    return (
        <motion.div key={pathname}
                    initial={{
                        y: -100,
                        opacity: 0,
                    }}
                    animate={{
                        y: 0,
                        opacity: 1,
                    }}
                    transition={{
                        type: 'easeInOut',
                        duration: 0.3,
                    }}
        >
            <Outlet/>
        </motion.div>
    );
}

export default PageAnimationLayout;