import React, {useEffect} from 'react';
import PageWrapper from "../utils/PageWrapper";
import {useDispatch, useSelector} from "react-redux";
import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import FormSection from "../form/FormSection";
import FormRow from "../form/FormRow";
import {formRowSizes} from "../../config/form/formRowSizes";
import {useMsal} from "@azure/msal-react";
import Api from "../../api/Api";
import {getRecipientEmails} from "../../redux/actions/azure";
import FormFieldsSkeleton from "../skeletons/FormFieldSkeleton";
import {groupAccountCompanyDataSubSites, routeAccount} from "../../config/routeItems";
import {formRowTypes} from "../../config/form/formRowTypes";
import {FormProvider, useForm} from "react-hook-form";
import SubpageNavigation from "../ui/SubpageNavigation";
import Supervisor from "../contactPersons/Supervisor";
import ChangeDataPanel from "../contactPersons/ChangeDataPanel";

function AccountRecipientsEmail(props) {
    const {t, i18n} = useTranslation();
    const recipientEmails = useSelector((state) => state.azure.recipientEmails);
    const dispatch = useDispatch();
    const methods = useForm({});
    const {instance, accounts} = useMsal();

    useEffect(() => {
        if (!recipientEmails.items) {
            Api.acquireToken(instance, accounts[0]).then(() => {
                dispatch(getRecipientEmails(i18n.language));
            });
        }
        // eslint-disable-next-line
    }, [recipientEmails.items]);

    return (
        <PageWrapper siteTitle={'customerportal.account.recipient_emails'} backRoute={routeAccount} switchClient={true}>
            <Grid container
                  spacing={2.5}
            >
                <Grid item
                      xs={12}
                      lg={8}
                >
                    <SubpageNavigation items={groupAccountCompanyDataSubSites} />
                    <Typography variant="body1">
                        {t('customerportal.company_recipient_emails.helper_text')}
                    </Typography>
                    {recipientEmails.loading ?
                        <FormFieldsSkeleton count={7}/> :
                        (recipientEmails.items && Object.keys(recipientEmails.items).length > 0) ?
                            <FormProvider {...methods}>
                                <form noValidate onSubmit={null}>
                                    <FormSection>

                                        {recipientEmails.items.offerEmail &&
                                            <FormRow field={{
                                                rowSize: formRowSizes.full,
                                                label: `customerportal.recipient_emails.${recipientEmails.items.offerEmail.label}`,
                                                defaultValue: recipientEmails.items.offerEmail.email,
                                                disabled: true,
                                                id: 'offer-email',
                                                type: formRowTypes.text
                                            }}/>
                                        }

                                        {recipientEmails.items.orderConfirmationEmail &&
                                            <FormRow field={{
                                                rowSize: formRowSizes.full,
                                                label: `customerportal.recipient_emails.${recipientEmails.items.orderConfirmationEmail.label}`,
                                                defaultValue: recipientEmails.items.orderConfirmationEmail.email,
                                                disabled: true,
                                                id: 'order-confirmation-email',
                                                type: formRowTypes.text
                                            }}/>
                                        }

                                        {recipientEmails.items.deliveryConfirmationEmail &&
                                            <FormRow field={{
                                                rowSize: formRowSizes.full,
                                                label: `customerportal.recipient_emails.${recipientEmails.items.deliveryConfirmationEmail.label}`,
                                                defaultValue: recipientEmails.items.deliveryConfirmationEmail.email,
                                                disabled: true,
                                                id: 'delivery-confirmation-email',
                                                type: formRowTypes.text
                                            }}/>
                                        }

                                        {recipientEmails.items.orderDeliveryConfirmationEmail &&
                                            <FormRow field={{
                                                rowSize: formRowSizes.full,
                                                label: `customerportal.recipient_emails.${recipientEmails.items.orderDeliveryConfirmationEmail.label}`,
                                                defaultValue: recipientEmails.items.orderDeliveryConfirmationEmail.email,
                                                disabled: true,
                                                id: 'order-delivery-confirmation-email',
                                                type: formRowTypes.text
                                            }}/>
                                        }

                                        {recipientEmails.items.billingEmail &&
                                            <FormRow field={{
                                                rowSize: formRowSizes.full,
                                                label: `customerportal.recipient_emails.${recipientEmails.items.billingEmail.label}`,
                                                defaultValue: recipientEmails.items.billingEmail.email,
                                                disabled: true,
                                                id: 'billing-email',
                                                type: formRowTypes.text
                                            }}/>
                                        }

                                        {recipientEmails.items.advancePaymentEmail &&
                                            <FormRow field={{
                                                rowSize: formRowSizes.full,
                                                label: `customerportal.recipient_emails.${recipientEmails.items.advancePaymentEmail.label}`,
                                                defaultValue: recipientEmails.items.advancePaymentEmail.email,
                                                disabled: true,
                                                id: 'advance-payment-email',
                                                type: formRowTypes.text
                                            }}/>
                                        }

                                        {recipientEmails.items.creditEmail &&
                                            <FormRow field={{
                                                rowSize: formRowSizes.full,
                                                label: `customerportal.recipient_emails.${recipientEmails.items.creditEmail.label}`,
                                                defaultValue: recipientEmails.items.creditEmail.email,
                                                disabled: true,
                                                id: 'credit-email',
                                                type: formRowTypes.text
                                            }}/>
                                        }

                                    </FormSection>
                                </form>
                            </FormProvider> :
                            <Typography variant={'body1'}>{t('customerportal.recipient_emails.no_items')}</Typography>
                    }
                </Grid>
                <Grid xs={12}
                      lg={4}
                      sx={{
                          position: 'relative',
                      }}
                      item
                >
                    <Supervisor/>

                    <ChangeDataPanel />
                </Grid>
            </Grid>
        </PageWrapper>
    )
}

export default AccountRecipientsEmail;