import React from 'react';
import {Typography} from "@mui/material";
import ContactPersonList from "./ContactPersonList";
import {useTranslation} from "react-i18next";

function Supervisor() {
    const {t} = useTranslation();

    return (
        <>
            <Typography variant={'h2'} sx={{
                position: {
                    lg: 'absolute'
                },
                top: {
                    lg: -40
                },
                marginBottom: {
                    xs: 1.5,
                    lg: 0
                },
                lineHeight: 1.75
            }}>
                {t('customerportal.account.my_contact')}
            </Typography>
            <ContactPersonList selection={['supervisor']}
                               bigImg={false}
                               skeletonCount={1}
            />
        </>
    )
}

export default Supervisor;
