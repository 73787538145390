import React from 'react';
import {Grid, Skeleton} from '@mui/material';

function CompanyContactsSkeletonTableHead() {
    return (
        <Grid container
              sx={{
                  opacity: 0.5, paddingY: 1, paddingX: 1.5,
                  display: {
                      xs: 'none',
                      lg: 'flex',
                  },
                  paddingLeft: 1,
                  paddingRight: {
                      xs: 4,
                      xl: 3.5,
                  },
              }}
              spacing={0.5}
              alignItems="flex-end">
            <Grid item lg={2}>
                <Skeleton height={15} animation="wave"/>
            </Grid>
            <Grid item lg={3} xl={2}>
                <Skeleton height={15} animation="wave"/>
            </Grid>
            <Grid item lg={3} xl={2}>
                <Skeleton height={15} animation="wave"/>
            </Grid>
            <Grid item lg={3} xl={2}>
                <Skeleton height={15} animation="wave"/>
            </Grid>
            <Grid item
                  sx={{
                      display: {
                          xs: 'none',
                          xl: 'flex',
                      },
                  }}
                  xl={1.5}>
            </Grid>
            <Grid item
                  sx={{
                      display: {
                          xs: 'none',
                          xl: 'flex',
                      },
                  }}
                  xs={12}
                  xl={2.5}>
                <Skeleton height={15} sx={{width: '100%'}} animation="wave"/>
            </Grid>
        </Grid>
    );
}

export default CompanyContactsSkeletonTableHead;
