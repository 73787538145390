import React from 'react';
import {Grid, Skeleton} from "@mui/material";

function FormFieldsSkeleton({count = 0}) {
    if (count > 0) {
        return (
            <Grid container spacing={1.25}>
                {Array.from(Array(count).keys()).map((key) => (
                    <Grid item xs={12} key={key}>
                        <Skeleton variant="rounded" animation={'wave'} height={56}/>
                    </Grid>
                ))}
            </Grid>
        )
    }
}

export default FormFieldsSkeleton;