import React from 'react';
import {Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import colors from '../../theme/colors';
import {ORDER_TYPE_OPEN} from '../../config/orderConfig';

function OrderArticleTableHead({orderType}) {
    const {t} = useTranslation();

    return (
        <Grid container columnSpacing={2} alignItems="flex-end" sx={{marginBottom: 1, display: {xs: 'none', lg: 'flex'}}}>

            <Grid item lg={2.5} xl={3}>
                <Typography variant="body2" color={colors.primary800}>
                    {t('customerportal.order.article_title')}
                </Typography>
            </Grid>

            <Grid item lg={2} xl={2}>
                <Typography variant="body2" color={colors.primary800}>
                    {t('customerportal.order.article_number')}
                </Typography>
            </Grid>

            <Grid item lg={orderType === ORDER_TYPE_OPEN ? 1.5 : 2.25} xl={2}>
                <Typography variant="body2" color={colors.primary800}>
                    {t('customerportal.order.article_number_customer')}
                </Typography>
            </Grid>

            <Grid item lg={orderType === ORDER_TYPE_OPEN ? 0.75 : 2.75} xl={orderType === ORDER_TYPE_OPEN ? 1 : 3}>
                <Typography variant="body2" color={colors.primary800} sx={{textAlign: 'right'}}>
                    {t('customerportal.order.article_quantity')}
                </Typography>
            </Grid>

            <Grid item lg={orderType === ORDER_TYPE_OPEN ? 1 : 2.5} xl={orderType === ORDER_TYPE_OPEN ? 1 : 2}>
                <Typography variant="body2" color={colors.primary800} sx={{textAlign: 'right'}}>
                    {t('customerportal.order.article_price')}
                </Typography>
            </Grid>

            {orderType === ORDER_TYPE_OPEN &&
                <Grid item lg={2} xl={1.5}>
                    <Typography variant="body2" color={colors.primary800}>
                        {t('customerportal.order.article_status')}
                    </Typography>
                </Grid>
            }

            {orderType === ORDER_TYPE_OPEN &&
                <Grid item lg={2} xl={1.5}>
                    <Typography variant="body2" color={colors.primary800}>
                        {t('customerportal.order.delivery_date')}
                    </Typography>
                </Grid>
            }
        </Grid>
    );
}

export default OrderArticleTableHead;
