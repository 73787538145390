import React from "react";
import PropTypes from 'prop-types';
import {Trans} from "react-i18next";
import ReactHookFormRadio from './rhf/ReactHookFormRadio';

function FormRadio({field}) {
    return (
        <ReactHookFormRadio
            id={field.id}
            label={
                <Trans i18nKey={field.label}>
                    {field.labelLink ? field.labelLink : ''}
                </Trans>
            }
            variant="filled"
            items={field.items}
            name={field.id}
            disabled={field.disabled}
            rules={field.rules}
        />
    )
}

FormRadio.propTypes = {
    field: PropTypes.shape({
        label: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        items: PropTypes.array.isRequired
    }).isRequired
}

export default FormRadio;