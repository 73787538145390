import React, {useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import FormRow from '../form/FormRow';
import {formRowSizes} from '../../config/form/formRowSizes';
import {formRowTypes} from '../../config/form/formRowTypes';
import FormSection from '../form/FormSection';
import {LoadingButton} from '@mui/lab';
import Alert from '@mui/material/Alert';
import {sendGetLLEForm} from '../../redux/actions/lleForm';
import {resetGetLLEDialog} from "../../redux/slices/lleForm";

function DialogGetLLE({debtorNumber, open, handleClose}) {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const {user, azureClient, contactPersons} = useSelector((state) => state.azure);
    const {isLoading, success, hasError, errorMessage} = useSelector((state) => state.lleForm);
    const methods = useForm({
        defaultValues: {
            debtorNumber: debtorNumber,
            companyName: user.companyName,
            email: user.email,
            phone: user.phone,
            message: '',
        },
    });

    const onSubmit = (form) => {
        resetFormState();

        dispatch(sendGetLLEForm({
            language: i18n.language,
            client: azureClient,
            customerEmail: user.email,
            customerName: user.companyName,
            supervisorMail: contactPersons.find(p => p.type === 'supervisor')?.email,
            salutation: t(`customerportal.salutation.${user.salutation}`),
            lastname: user.lastname,
            firstname: user.firstname,
            form: form,
        }));
    };

    const resetFormState = () => {
        dispatch(resetGetLLEDialog());
    };

    useEffect(() =>  () =>{
        resetFormState();
        // eslint-disable-next-line
    }, []);

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">
                {t('customerportal.form.get_lle.title')}
            </DialogTitle>
            <DialogContent>
                {!success ? (
                    <>
                        {hasError && (
                            <Alert severity="error" sx={{width: '100%'}} onClose={resetFormState}>
                                <Typography mr={1} component={'strong'}>{t('customerportal.general.server_error')}</Typography>
                                {errorMessage && (
                                    <Typography component={'code'} style={{fontFamily: 'monospace'}}>{errorMessage}</Typography>
                                )}
                            </Alert>
                        )}
                        <FormProvider {...methods}>
                            <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                                <FormSection>
                                    <FormRow field={{
                                        rowSize: formRowSizes.half,
                                        label: 'customerportal.order.debtor_number',
                                        defaultValue: debtorNumber,
                                        disabled: true,
                                        id: 'debtorNumber',
                                        type: formRowTypes.text,
                                    }}/>
                                    <FormRow field={{
                                        rowSize: formRowSizes.half,
                                        label: 'customerportal.form.company_name',
                                        defaultValue: user.companyName,
                                        disabled: true,
                                        id: 'companyName',
                                        type: formRowTypes.text,
                                    }}/>
                                    <FormRow field={{
                                        rowSize: formRowSizes.half,
                                        label: 'customerportal.form.mail',
                                        defaultValue: user.email,
                                        disabled: true,
                                        id: 'email',
                                        type: formRowTypes.text,
                                    }}/>
                                    <FormRow field={{
                                        rowSize: formRowSizes.half,
                                        label: 'customerportal.form.phone',
                                        defaultValue: user.phone,
                                        disabled: true,
                                        id: 'phone',
                                        type: formRowTypes.text,
                                    }}/>

                                    <Grid item xs={12}>
                                        <Typography variant={'body1'}>{t('customerportal.form.get_lle.validity_text')}</Typography>
                                    </Grid>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.message',
                                        multiline: true,
                                        disabled: false,
                                        id: 'message',
                                        type: formRowTypes.text,
                                    }}/>
                                    <Grid item xs={12} sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'start',
                                    }}>
                                        <LoadingButton variant={'contained'}
                                                       color={'secondary'}
                                                       loading={isLoading}
                                                       type={'submit'}
                                        >
                                            <Typography>{t('customerportal.form.send_get_lle_form')}</Typography>
                                        </LoadingButton>
                                    </Grid>
                                </FormSection>
                            </form>
                        </FormProvider>
                    </>
                ) : (
                    <Box>
                        <Typography variant={'h1'}
                                    component={'h2'}>
                            {t('customerportal.form.get_lle_success.title')}
                        </Typography>
                        <Typography variant={'body1'}>
                            {t('customerportal.form.get_lle_success.text')}
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            {success && (
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        {t('customerportal.dialog.close')}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}

export default DialogGetLLE;
