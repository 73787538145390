import React, {useState} from 'react';
import {Button, Grid, Paper, Typography} from '@mui/material';
import PageWrapper from '../utils/PageWrapper';
import Supervisor from '../contactPersons/Supervisor';
import ProductList from '../products/ProductList';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import DialogGetLLE from "../orders/DialogGetLLE";

function ArticleOverview() {
    const {t} = useTranslation();
    const companyNumber = useSelector((state) => state.azure.user?.companyNumber);
    const [getLLEDialogOpen, setGetLLEDialogOpen] = useState(false);

    const handleClickGetLLE = () => {
        setGetLLEDialogOpen(true);
    };

    const handleGetLLEDialogClose = () => {
        setGetLLEDialogOpen(false);
    };
    return (
        <PageWrapper siteTitle={'customerportal.navigation.article_overview'}>
            <Grid container
                  columnSpacing={5}
                  rowSpacing={2.5}
            >
                <Grid item
                      xs={12}
                      lg={8}
                >
                    <ProductList/>
                </Grid>
                <Grid item
                      xs={12}
                      lg={4}
                      sx={{
                          position: 'relative',
                      }}
                >
                    <Supervisor/>

                    {companyNumber && (
                        <Paper elevation={1} sx={{
                            paddingY: 1,
                            paddingX: 1.25,
                            marginTop: 1,
                        }}>
                            <Grid container spacing={1.25}>
                                <Grid item>
                                    <InfoOutlinedIcon sx={{
                                        width: 40,
                                        height: 40,
                                    }}/>
                                </Grid>
                                <Grid item sx={{
                                    flex: 1,
                                    paddingBottom: 0.5,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant="body1">
                                        {t('customerportal.lle.get_lle_text')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Button onClick={handleClickGetLLE}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                    }}
                                    color={'secondary'}
                                    variant={'contained'}>
                                {t('customerportal.product.get_lle')}
                            </Button>
                        </Paper>
                    )}
                </Grid>
            </Grid>

            {getLLEDialogOpen && (
                <DialogGetLLE
                    debtorNumber={companyNumber}
                    open={getLLEDialogOpen}
                    handleClose={handleGetLLEDialogClose}/>
            )}
        </PageWrapper>
    );
}

export default ArticleOverview;
