import React from 'react';
import {useSelector} from "react-redux";
import {Collapse} from "@mui/material";

function AdBanner({banner}) {
    const client = useSelector((state) => state.azure.client);

    return (
        <Collapse in={!!(banner && (banner.client === client || banner.client === '*'))}>
            {(banner && (banner.client === client || banner.client === '*') && (banner.asset && banner.link)) &&
                <a href={banner.link.path} target="_blank" rel="noreferrer" title={banner.link.title}>
                    <img src={banner.asset.path}
                         alt={banner.link.title}
                         style={{
                             width: '100%',
                             height: 'auto',
                             borderRadius: 12
                         }}
                    />
                </a>
            }
        </Collapse>
    )
}

export default AdBanner;
