import React from 'react';
import {Controller} from "react-hook-form";
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";
import {useTranslation} from "react-i18next";

const ReactHookFormCheckbox = ({
                                   name,
                                   label,
                                   control,
                                   defaultValue,
                                   children,
                                   disabled,
                                   rules,
                                   ...props
                               }) => {
    const {t} = useTranslation();

    return (
        <Controller
            rules={rules}
            render={({
                         field: {onChange, onBlur, value, name, ref},
                         fieldState: {invalid, isTouched, isDirty, error},
                         formState,
                     }) => (
                <FormControl error={!!error}>
                    <FormControlLabel control={
                        <Checkbox
                            onBlur={onBlur} // notify when input is touched
                            onChange={onChange} // send value to hook form
                            inputRef={ref}
                            name={name}
                            checked={value || false}
                            disabled={disabled}
                            control={control}
                            defaultValue={defaultValue}
                        />
                    } label={label}/>
                    {error &&
                        <FormHelperText>{t(error.message)}</FormHelperText>
                    }
                </FormControl>
            )}
            name={name}
        />
    );
};

export default ReactHookFormCheckbox;