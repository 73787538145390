import {Box, Grid, Typography, Button, Paper} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {FilePresentOutlined, LocalShippingOutlined} from '@mui/icons-material';
import {NO_DATA_STRING, QUOTE_TYPE_CLOSED, QUOTE_TYPE_OPEN} from '../../config/orderConfig';
import DialogDocuments from './DialogDocuments';
import DialogDelivery from './DialogDelivery';

function OrderItemSimple({orderItem, orderType}) {
    const {t} = useTranslation();
    const [dialogDocumentsOpen, setDialogDocumentsOpen] = useState(false);
    const [dialogDeliveryOpen, setDialogDeliveryOpen] = useState(false);

    const handleClickDocuments = (ev) => {
        ev.stopPropagation();
        setDialogDocumentsOpen(true);
    };

    const handleDialogDocumentsClose = () => {
        setDialogDocumentsOpen(false);
    };

    const handleClickDelivery = () => {
        setDialogDeliveryOpen(true);
    };

    const handleDialogDeliveryClose = () => {
        setDialogDeliveryOpen(false);
    };

    return (
        <Paper sx={{
            paddingY: 0.75,
            paddingX: 1,
            display: 'block',
            textDecoration: 'none',
            '&:hover': {
                boxShadow: 1,
            },
            backgroundColor: 'common.white',
        }}
        >

            <Grid container alignItems="center" columnSpacing={2}>
                <Grid item xs={12} lg={2.5} xl={2}>
                    <Typography variant="subtitle1" sx={{lineHeight: '1.25'}}>
                        <>
                            <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_title' : 'customerportal.order.order_title')}:&ensp;
                            </Box>
                            <Box component="span" sx={{textTransform: 'uppercase', hyphens: 'auto'}}>
                                {orderItem.orderNumberCustomer ?? `${t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_title_fallback' : 'customerportal.order.order_title_fallback')}${orderItem.orderNumber}`}
                            </Box>
                        </>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={2.5} xl={2}>
                    <Typography variant="body2">
                        <>
                            <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_date' : 'customerportal.order.order_date')}:&ensp;
                            </Box>
                            {orderItem.orderDate ? moment(orderItem.orderDate).format('DD.MM.YYYY') : NO_DATA_STRING}
                        </>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={1} xl={2}>
                    <Typography variant="body2">
                        <>
                            <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_number' : 'customerportal.order.order_number')}:&ensp;
                            </Box>
                            {orderItem.orderNumber ?? NO_DATA_STRING}
                        </>
                    </Typography>
                </Grid>

                <Grid item lg={2} xl={1.5} sx={{marginLeft: 'auto', display: {xs: 'none', lg: 'block'}}}>
                    <Button onClick={handleClickDelivery} startIcon={<LocalShippingOutlined/>}>
                        {t('customerportal.order.track_delivery.short')}
                    </Button>
                </Grid>

                <Grid item lg={2} xl={1.5} sx={{alignSelf: 'end', textAlign: 'right', display: {xs: 'none', lg: 'block'}}}>
                    <Button onClick={handleClickDocuments} startIcon={<FilePresentOutlined/>}>
                        {t('customerportal.order.show_documents.short')}
                    </Button>
                </Grid>
                <Grid item lg={2} xl={1.5} sx={{alignSelf: 'end', textAlign: 'right', display: {xs: 'block', lg: 'none'}}}>
                    <Button onClick={handleClickDelivery} startIcon={<LocalShippingOutlined/>}>
                        {t('customerportal.order.track_delivery')}
                    </Button>
                    <Button onClick={handleClickDocuments} startIcon={<FilePresentOutlined/>}>
                        {t('customerportal.order.show_documents')}
                    </Button>
                </Grid>
            </Grid>


            {dialogDocumentsOpen && (
                <DialogDocuments order={orderItem} open={dialogDocumentsOpen} handleClose={handleDialogDocumentsClose}/>
            )}

            {dialogDeliveryOpen && (
                <DialogDelivery order={orderItem} open={dialogDeliveryOpen} orderType={orderType}
                                handleClose={handleDialogDeliveryClose}/>
            )}
        </Paper>
    );
}

export default OrderItemSimple;
