import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from '@mui/material';
import {useTranslation} from 'react-i18next';

function DialogAddress({title, address, open, handleClose}) {
    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="xs"
            onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" variant="body1">
                    {address.company && (
                        <>{address.company}<br/></>
                    )}
                    {address.companyAddition && (
                        <>{address.companyAddition}<br/></>
                    )}
                    {address.address && (
                        <>{address.address}<br/></>
                    )}
                    {address.addressAddition && (
                        <>{address.addressAddition}<br/></>
                    )}
                    {(address.country || address.zip || address.city) && (
                        <>{address.country}-{address.zip} {address.city}<br/></>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    {t('customerportal.dialog.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogAddress;
