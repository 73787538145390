import React from 'react';
import {Grid, Paper, Skeleton} from '@mui/material';

function CompanyContactSkeletonItem() {
    return (
        <Grid item xs={12}>
            <Paper elevation={1} sx={{
                paddingY: 0.5,
                paddingX: 1,
                backgroundColor: 'common.white',
            }}>
                <Grid container alignItems="center" spacing={0.5}>
                    <Grid item xs={12} lg={2}>
                        <Skeleton animation="wave"/>
                    </Grid>
                    <Grid item xs={12} lg={3} xl={2}>
                        <Skeleton animation="wave"/>
                    </Grid>
                    <Grid item xs={12} lg={3} xl={2}>
                        <Skeleton animation="wave"/>
                    </Grid>
                    <Grid item xs={12} lg={3} xl={2}>
                        <Skeleton animation="wave"/>
                    </Grid>
                    <Grid item
                          sx={{
                              display: {
                                  xs: 'none',
                                  xl: 'flex',
                              },
                          }}
                          xs={12}
                          xl={4}>
                        <Grid container spacing={1}>
                            <Grid item
                                  xs={12}
                                  xl={4}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                  }}>
                            </Grid>
                            <Grid item xs={12} xl={8} sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingLeft: 0.5,
                            }}>
                                <Skeleton animation="wave" width={'100%'}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default CompanyContactSkeletonItem;
