import React from 'react';
import {Box, Grid, Paper, Skeleton} from '@mui/material';
import {KeyboardArrowRightOutlined} from '@mui/icons-material';

function CorrectionDrawingsSkeletonItem({bgBlue}) {
    return (
        <Grid item xs={12}>
            <Paper elevation={1} sx={{
                paddingY: 0.75,
                paddingX: 1,
                backgroundColor: bgBlue ? 'blue.100' : 'common.white'
            }}>
                <Grid container spacing={0.75} alignItems={{lg: 'center'}}>
                    <Grid item xs={10} md={11}>
                        <Grid container spacing={0.75} alignItems={{lg: 'center'}}>
                            <Grid item xs={12} lg={7}>
                                <Box sx={{display: 'flex'}}>
                                    <Skeleton variant="text" animation={'wave'} sx={{
                                        fontSize: 'subtitle1',
                                        width: {
                                            xs: 200,
                                            lg: 100,
                                        },
                                        marginRight: 1,
                                    }}/>
                                    <Skeleton variant="text" animation={'wave'} sx={{
                                        fontSize: 'subtitle1',
                                        display: {
                                            xs: 'none',
                                            lg: 'block',
                                        },
                                        width: 100,
                                    }}/>
                                </Box>
                                <Box sx={{
                                    marginTop: 0.5,
                                }}>
                                    <Box sx={{display: 'flex'}}>
                                        <Skeleton variant="text" animation={'wave'} sx={{
                                            fontSize: 'body2',
                                            width: 100,
                                            marginRight: 1,
                                        }}/>
                                        <Skeleton variant="text" animation={'wave'} sx={{
                                            fontSize: 'body2',
                                            width: 75,
                                        }}/>
                                    </Box>
                                    <Box sx={{display: 'flex'}}>
                                        <Skeleton variant="text" animation={'wave'} sx={{
                                            fontSize: 'body2',
                                            width: 95,
                                            marginRight: 1,
                                        }}/>
                                        <Skeleton variant="text" animation={'wave'} sx={{
                                            fontSize: 'body2',
                                            width: 80,
                                        }}/>
                                    </Box>
                                    <Box sx={{display: 'flex'}}>
                                        <Skeleton variant="text" animation={'wave'} sx={{
                                            fontSize: 'body2',
                                            width: 100,
                                            marginRight: 1,
                                        }}/>
                                        <Skeleton variant="text" animation={'wave'} sx={{
                                            fontSize: 'body2',
                                            width: 75,
                                        }}/>
                                    </Box>
                                    <Box sx={{display: 'flex'}}>
                                        <Skeleton variant="text" animation={'wave'} sx={{
                                            fontSize: 'body2',
                                            width: 95,
                                            marginRight: 1,
                                        }}/>
                                        <Skeleton variant="text" animation={'wave'} sx={{
                                            fontSize: 'body2',
                                            width: 80,
                                        }}/>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Skeleton variant="text" animation={'wave'} sx={{
                                            fontSize: 'body1',
                                            width: 60,
                                        }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <Skeleton variant="circular"
                                                      animation={'wave'}
                                                      sx={{
                                                          width: 24,
                                                          height: 24,
                                                          marginRight: 0.625,
                                                      }}
                                            />
                                            <Skeleton variant="text" animation={'wave'} sx={{
                                                fontSize: 'body1',
                                                width: 60,
                                            }}/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} md={1} sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <KeyboardArrowRightOutlined sx={{
                            color: 'grey.400',
                        }}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default CorrectionDrawingsSkeletonItem;