import React from 'react';
import CompanyContactSkeletonItem from './CompanyContactSkeletonItem';

function CompanyContactsSkeletonList({count = 0}) {
    if (count > 0) {
        return Array.from(Array(count).keys()).map((key, i) => (
            <CompanyContactSkeletonItem key={key} />
        ))
    }
}

export default CompanyContactsSkeletonList;
