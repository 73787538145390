import React from 'react';
import {Box, Grid, Paper, Typography} from '@mui/material';
import {CheckOutlined, KeyboardArrowRightOutlined, VisibilityOutlined} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {correctionDrawingStatus} from '../../config/correctionDrawingStatus';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

function CorrectionDrawingsItem({correctionDrawing}) {
    const {t, i18n} = useTranslation();
    const client = useSelector(state => state.azure.client);

    return (
        <Grid item xs={12}>
            <Paper component={correctionDrawing.processStatus === correctionDrawingStatus.EXAMINATION ? Link : 'div'}
                   to={`/${i18n.language}/layout-release/${correctionDrawing.itemNumber}`}
                   sx={{
                       paddingY: 0.75,
                       paddingX: 1,
                       display: 'block',
                       textDecoration: 'none',
                       '&:hover': {
                           boxShadow: correctionDrawing.processStatus === correctionDrawingStatus.EXAMINATION ? 3 : 1,
                       },
                       backgroundColor: correctionDrawing.processStatus === correctionDrawingStatus.EXAMINATION ? 'blue.100' : 'common.white',
                   }}
            >
                <Grid container spacing={0.75} alignItems={{lg: 'center'}}>
                    <Grid item xs={10} md={11}>
                        <Grid container spacing={0.75} alignItems={{lg: 'center'}}>
                            <Grid item xs={12} lg={7}>
                                <Typography variant={'subtitle1'}
                                            color={'primary.main'}>{t('customerportal.order')} {correctionDrawing.originDocumentNumber}<Box
                                    component={'span'} sx={{
                                    display: {
                                        xs: 'none',
                                        lg: 'inline',
                                    },
                                }}>, {t('customerportal.item_number')} {correctionDrawing.itemNumber}</Box></Typography>
                                <Typography variant={'subtitle1'}
                                            color={'primary.main'}
                                            sx={{
                                                display: {
                                                    xs: 'inline',
                                                    lg: 'none',
                                                },
                                            }}>
                                    {t('customerportal.item_number')} {correctionDrawing.itemNumber}
                                </Typography>
                                {(correctionDrawing.clientItemNumber || correctionDrawing.clientOrderNumber || correctionDrawing.clientItemDescription || correctionDrawing.clientDrawingNumber) &&
                                    <Box sx={{
                                        marginTop: 0.5,
                                    }}>
                                        {correctionDrawing.clientOrderNumber &&
                                            <Typography color={'primary.main'}
                                                        variant={'body2'}>
                                                {t('customerportal.correction_drawings.your_ordernumber')} {correctionDrawing.clientOrderNumber}
                                            </Typography>
                                        }
                                        {correctionDrawing.clientItemNumber &&
                                            <Typography color={'primary.main'}
                                                        variant={'body2'}>
                                                {t('customerportal.correction_drawings.your_itemnumber')} {correctionDrawing.clientItemNumber}
                                            </Typography>
                                        }
                                        {correctionDrawing.clientItemDescription &&
                                            <Typography color={'primary.main'}
                                                        variant={'body2'}>
                                                {t('customerportal.correction_drawings.your_description')} {correctionDrawing.clientItemDescription}
                                            </Typography>
                                        }
                                        {correctionDrawing.clientDrawingNumber &&
                                            <Typography color={'primary.main'}
                                                        variant={'body2'}>
                                                {t('customerportal.correction_drawings.your_drawingnumber')} {correctionDrawing.clientDrawingNumber}
                                            </Typography>
                                        }
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                {(correctionDrawing.processStatus && correctionDrawing.processStatus === correctionDrawingStatus.IN_PROGRESS) &&
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <CheckOutlined sx={{
                                            color: 'grey.400',
                                            marginRight: 0.625,
                                        }}/>
                                        <Typography
                                            variant={'body1'}>{t(`customerportal.correction_drawings.status.in_progress.${client}`)}</Typography>
                                    </Box>
                                }
                                {(correctionDrawing.processStatus && correctionDrawing.processStatus === correctionDrawingStatus.EXAMINATION) &&
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <VisibilityOutlined sx={{
                                            color: 'blue.200',
                                            marginRight: 0.625,
                                        }}/>
                                        <Typography
                                            variant={'body1'}>{t('customerportal.correction_drawings.status.examination')}</Typography>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} md={1} sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        {(correctionDrawing.processStatus && correctionDrawing.processStatus === correctionDrawingStatus.EXAMINATION) &&
                            <KeyboardArrowRightOutlined sx={{
                                color: 'grey.400',
                            }}/>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default CorrectionDrawingsItem;
