import React from "react";
import PropTypes from 'prop-types';
import {Trans} from "react-i18next";
import ReactHookFormCheckbox from "./rhf/ReactHookFormCheckbox";

function FormCheckbox({field}) {
    return (
        <ReactHookFormCheckbox
            id={field.id}
            label={
                <Trans i18nKey={field.label}>
                    {field.labelLink ? field.labelLink : ''}
                </Trans>
            }
            defaultValue={field.defaultValue}
            variant="filled"
            name={field.id}
            multiline={field.multiline}
            disabled={field.disabled}
            rules={field.rules}
        />
    )
}

FormCheckbox.propTypes = {
    field: PropTypes.shape({
        rowSize: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        defaultValue: PropTypes.bool,
        multiline: PropTypes.bool,
        disabled: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired
}

export default FormCheckbox;