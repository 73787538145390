import React, {useEffect} from 'react';
import PageWrapper from "../utils/PageWrapper";
import {useDispatch, useSelector} from "react-redux";
import {Button, Grid, Link, Paper, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import FormSection from "../form/FormSection";
import FormRow from "../form/FormRow";
import {formRowSizes} from "../../config/form/formRowSizes";
import {useMsal} from "@azure/msal-react";
import Api from "../../api/Api";
import {getCustomerAddresses} from "../../redux/actions/azure";
import FormFieldsSkeleton from "../skeletons/FormFieldSkeleton";
import {groupAccountCompanyDataSubSites, routeAccount} from "../../config/routeItems";
import {formRowTypes} from "../../config/form/formRowTypes";
import {FormProvider, useForm} from "react-hook-form";
import SubpageNavigation from "../ui/SubpageNavigation";
import AdditionalAddresses from "../address/AdditionalAddresses";
import Supervisor from "../contactPersons/Supervisor";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function AccountDeliveryAddress() {
    const {t, i18n} = useTranslation();
    const deliveryAddress = useSelector((state) => state.azure.customerAddresses.deliveryAddress);
    const dispatch = useDispatch();
    const methods = useForm({});
    const {instance, accounts} = useMsal();
    const contactPersons = useSelector((state) => state.azure.contactPersons);

    useEffect(() => {
        if (!deliveryAddress) {
            Api.acquireToken(instance, accounts[0]).then(() => {
                dispatch(getCustomerAddresses(i18n.language))
            });
        }

        // eslint-disable-next-line
    }, [deliveryAddress]);

    return (
        <PageWrapper siteTitle={'customerportal.account.delivery_address'} backRoute={routeAccount} switchClient={true}>
            <Grid container
                  spacing={2.5}
            >
                <Grid item
                      xs={12}
                      lg={8}
                >
                    <SubpageNavigation items={groupAccountCompanyDataSubSites} />
                    <Typography variant="body1" sx={{marginBottom: 2.5}}>
                        {t('customerportal.company_delivery_address.helper_text')}
                    </Typography>
                    {deliveryAddress ?
                        <FormProvider {...methods}>
                            <form noValidate onSubmit={null}>
                                <FormSection>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.street',
                                        defaultValue: deliveryAddress.street,
                                        disabled: true,
                                        id: 'street',
                                        type: formRowTypes.text
                                    }}/>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.zip',
                                        defaultValue: deliveryAddress.zip,
                                        disabled: true,
                                        id: 'zip',
                                        type: formRowTypes.text
                                    }}/>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.place',
                                        defaultValue: deliveryAddress.place,
                                        disabled: true,
                                        id: 'place',
                                        type: formRowTypes.text
                                    }}/>


                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.country',
                                        defaultValue: deliveryAddress.country,
                                        disabled: true,
                                        id: 'country',
                                        type: formRowTypes.text
                                    }}/>

                                </FormSection>

                                {deliveryAddress?.additionalAddresses && (
                                    <AdditionalAddresses addressType="delivery"
                                                         addresses={deliveryAddress.additionalAddresses}/>
                                )}
                            </form>
                        </FormProvider> :
                        <FormFieldsSkeleton count={4}/>
                    }
                </Grid>
                <Grid xs={12}
                      lg={4}
                      sx={{
                          position: 'relative',
                      }}
                      item
                >
                    <Supervisor/>

                    <Paper elevation={1} sx={{
                        paddingY: 1,
                        paddingX: 1.25,
                        marginTop: 1,
                    }}>
                        <Grid container spacing={1.25}>
                            <Grid item>
                                <InfoOutlinedIcon sx={{
                                    width: 40,
                                    height: 40,
                                }}/>
                            </Grid>
                            <Grid item sx={{
                                flex: 1,
                                paddingBottom: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <Typography variant="body1">
                                    {t('customerportal.contact_persons.change_company_data.text')}
                                </Typography>
                            </Grid>
                        </Grid>
                        {contactPersons.find(p => p.type === 'orderManager')?.phone && (
                            <Button variant={'contained'}
                                    sx={{
                                        width: '100%',
                                        marginBottom: 0.5,
                                    }}
                                    component={Link}
                                    href={`tel:${contactPersons.find(p => p.type === 'orderManager')?.phone}`}
                                    color={'secondary'}>
                                {t('customerportal.contact_persons.change_company_data.phone')}
                            </Button>
                        )}
                        <Button variant={'contained'}
                                sx={{
                                    width: '100%',
                                }}
                                component={Link}
                                href={`mailto:${contactPersons.find(p => p.type === 'orderManager')?.email}`}
                                color={'secondary'}>
                            {t('customerportal.contact_persons.change_company_data')}
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    )
}

export default AccountDeliveryAddress;