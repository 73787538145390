import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    sidebarExpanded: true
};

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {}
});

export default layoutSlice.reducer;