import React from 'react';
import {Alert, Collapse, Typography} from '@mui/material';

function Message({message}) {
    return (
        <Collapse in={!!(message && (message.title || message.text))}>
            {(message && (message.title || message.text)) && (
                <Alert severity={message.messageType}>
                    <Typography variant={'subtitle1'}>{message.title}</Typography>
                    {message.text}
                </Alert>
            )}
        </Collapse>
    );
}

export default Message;