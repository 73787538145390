import React from 'react';
import PageWrapper from "../utils/PageWrapper";

function PrivacyPolicy(props) {
    return (
        <PageWrapper siteTitle={'customerportal.navigation.privacy_policy'}>
            PrivacyPolicy
        </PageWrapper>
    )
}

export default PrivacyPolicy;