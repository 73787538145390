import {createSlice} from '@reduxjs/toolkit';
import {sendGetLLEForm} from "../actions/lleForm";

const initialState = {
    success: false,
    isLoading: false,
    hasError: false,
    errorMessage: '',
};

const lleFormSlice = createSlice({
    name: 'lleForm',
    initialState,
    reducers: {
        resetGetLLEDialog: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(sendGetLLEForm.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendGetLLEForm.fulfilled, (state, action) => {
                state.isLoading = false;
                state.success = true;
            })
            .addCase(sendGetLLEForm.rejected, (state, action) => {
                state.isLoading = false;
                state.success = false;
                state.hasError = true;
                state.errorMessage = action.payload.errorMessage;
            });
    },
});

export const {resetGetLLEDialog} = lleFormSlice.actions;
export default lleFormSlice.reducer;
