export function getGTMId(azureClient) {
    switch (azureClient) {
        case 'rathgeber':
            return process.env.REACT_APP_GTM_RATHGEBER;
        case 'rathgeber-cz':
            return process.env.REACT_APP_GTM_RATHGEBER_CZ;
        case 'rathgeber-pl':
            return process.env.REACT_APP_GTM_RATHGEBER_PL;
        case 'smart-tec':
            return process.env.REACT_APP_GTM_SMART_TEC;
        default:
            return null;
    }
}