import React from 'react';
import PageWrapper from "../utils/PageWrapper";
import {Grid, Typography} from '@mui/material';
import InquiryForm from '../inquiry/InquiryForm';
import {INQUIRY_TYPE_OFFER} from '../../config/customerPartalApi';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Supervisor from '../contactPersons/Supervisor';


function Offer() {
    const {t} = useTranslation();
    const client = useSelector((state) => state.azure.client);

    return (
        <PageWrapper siteTitle={'customerportal.navigation.offer'}>
            <Grid container
                  columnSpacing={5}
                  rowSpacing={2.5}
            >
                <Grid item
                      xs={12}
                      lg={8}
                >
                    <Typography variant="body1" mb={1.25} dangerouslySetInnerHTML={
                        {__html: t(`customerportal.offer${client && `.${client}`}.description`, {interpolation: {escapeValue: false}})}
                    }/>
                    <InquiryForm inquiryType={INQUIRY_TYPE_OFFER}/>
                </Grid>
                <Grid item
                      xs={12}
                      lg={4}
                      sx={{
                          position: 'relative',
                      }}
                >
                    <Supervisor/>
                </Grid>
            </Grid>
        </PageWrapper>
    )
}

export default Offer;
