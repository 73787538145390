import React from "react";
import {MenuItem} from "@mui/material";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import ReactHookFormSelect from "./rhf/ReactHookFormSelect";

function FormSelect({field}) {
    const {t} = useTranslation();

    return (
        <ReactHookFormSelect
            id={field.id}
            name={field.id}
            label={t(field.label)}
            disabled={field.disabled}
            defaultValue={field.defaultValue || ""}
            variant="standard"
            rules={field.rules}
        >
            {field.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>{t(option.label)}</MenuItem>
            ))}
        </ReactHookFormSelect>
    )
}

FormSelect.propTypes = {
    field: PropTypes.shape({
        rowSize: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        defaultValue: PropTypes.string,
        disabled: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.any.isRequired
        })).isRequired
    }).isRequired
}

export default FormSelect;