import React from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FormControl} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import cs from 'date-fns/locale/cs';
import pl from 'date-fns/locale/pl';


const locales = {
    de: de,
    en: enGB,
    cs: cs,
    pl: pl,
}

const ReactHookFormDatePicker = ({
                                     name,
                                     label,
                                     labelExtended,
                                     control,
                                     defaultValue,
                                     children,
                                     multiple,
                                     disabled,
                                     rules,
                                     datePickerRules,
                                     ...props
                                 }) => {
    const {t, i18n} = useTranslation();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locales[i18n.language]}>
            <Controller
                control={control}
                defaultValue=""
                name={name}
                rules={rules}
                render={({
                             field,
                             fieldState: {invalid, isTouched, isDirty, error},
                             formState,
                         }) => {
                    return (
                        <FormControl {...props}
                                     disabled={disabled}
                                     fullWidth
                                     error={!!error}
                                     sx={{
                                         backgroundColor: disabled ? 'transparent' : 'common.white',
                                     }}
                        >
                            <DatePicker {...field}
                                        error={!!error}
                                        label={t(label)}
                                        {...datePickerRules}
                                        disabled={disabled}
                                        slotProps={{
                                            textField: {
                                                helperText: error ? t(error.message) : null,
                                            },
                                        }}
                                        required={rules?.required?.value}
                            />
                        </FormControl>
                    );
                }}
            />
        </LocalizationProvider>
    );
};

export default ReactHookFormDatePicker;