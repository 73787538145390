import React, {useEffect} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {allRoutes} from '../../config/routeItems';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import PageAnimationLayout from './PageAnimationLayout';
import PwaInstallMessage from './PwaInstallMessage';
import TagManager from 'react-gtm-module';
import {useSelector} from 'react-redux';
import {getGTMId} from '../../utils/gtmFunc';
import PwaFeedbackCollector from './PwaFeedbackCollector';

function AppBody(props) {
    const {i18n} = useTranslation();
    const azureClient = useSelector((state) => state.azure.azureClient);

    useEffect(() => {
        if (azureClient) {
            let gtmID = getGTMId(azureClient);

            if (gtmID) {
                TagManager.initialize({
                    gtmId: gtmID,
                });
            }
        }
        // eslint-disable-next-line
    }, [azureClient]);

    return (
        <Box component="main" sx={{
            width: '100%',
            minHeight: '100vh',
            height: '100vh',
            paddingBottom: '50px',
            overflowY: 'auto',
            backgroundColor: 'greyBlue.200',
        }}>
            <PwaInstallMessage/>
            <PwaFeedbackCollector />
            <Routes>
                <Route element={<PageAnimationLayout/>}>
                    {allRoutes.map((item) => {
                        return (
                            <Route key={item.key}
                                   exact={item.exact}
                                   path={item.routingUrl}
                                   element={item.component}
                            />
                        );
                    })}
                    <Route path="*" element={<Navigate to={`/${i18n.language}/dashboard`} replace/>}/>
                </Route>
            </Routes>
        </Box>
    );
}

export default AppBody;
