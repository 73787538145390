import React from 'react';
import {Grid} from '@mui/material';
import PageWrapper from '../utils/PageWrapper';
import Supervisor from '../contactPersons/Supervisor';
import {groupAccountCompanyDataSubSites, routeAccount} from '../../config/routeItems';
import SubpageNavigation from '../ui/SubpageNavigation';
import CompanyDataForm from '../companyData/CompanyDataForm';
import ChangeDataPanel from "../contactPersons/ChangeDataPanel";

function AccountCompanyData() {

    return (
        <PageWrapper siteTitle={'customerportal.navigation.company_data'} backRoute={routeAccount}>
            <Grid container
                  columnSpacing={5}
                  rowSpacing={2.5}
            >
                <Grid item
                      xs={12}
                      lg={8}
                >
                    <SubpageNavigation items={groupAccountCompanyDataSubSites}/>
                    <CompanyDataForm/>
                </Grid>
                <Grid item
                      xs={12}
                      lg={4}
                      sx={{
                          position: 'relative',
                      }}
                >
                    <Supervisor/>
                    <ChangeDataPanel />
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export default AccountCompanyData;
