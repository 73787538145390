import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Collapse} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import List from "@mui/material/List";

function NavItem({navItem, sidebarOpen, isChildPage}) {
    const location = useLocation();
    const [subpagesOpen, setSubpagesOpen] = useState(false);
    const {i18n, t} = useTranslation();
    const navigate = useNavigate();

    const handleNavigationClick = (event, newValue, openSubpages) => {
        event.stopPropagation();
        if (openSubpages) {
            setSubpagesOpen(!subpagesOpen);
        } else {
            navigate(newValue, {replace: true});
        }
    };

    return (
        <ListItem key={navItem.key}
                  disablePadding
                  onClick={(e) => handleNavigationClick(e, `/${i18n.language}${navItem.url}`, navItem.childPages?.length > 0)}
                  sx={{
                      display: 'block',
                      color: location.pathname.includes(`/${i18n.language}${navItem.url}`) ? 'common.white' : 'grey.200',
                      boxShadow: (!isChildPage && location.pathname.includes(`/${i18n.language}${navItem.url}`)) ? 'inset 3px 0px 0px 0px #ffffff' : '',
                      '& svg': {
                          color: location.pathname.includes(`/${i18n.language}${navItem.url}`) ? 'common.white' : 'grey.200',
                      },
                      '&:hover': {
                          backgroundColor: 'primary.dark',
                      },
                  }}
        >
            <ListItemButton
                sx={{
                    minHeight: isChildPage ? 'auto' : 48,
                    justifyContent: sidebarOpen ? 'initial' : 'center',
                    px: 1.25,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: sidebarOpen ? 1.875 : 'auto',
                        height: isChildPage ? 0 : 'auto',
                        visibility: isChildPage ? 'hidden' : 'visible',
                        justifyContent: 'center',
                    }}
                >
                    {navItem.icon}
                </ListItemIcon>
                <ListItemText primary={t(navItem.label)} sx={{
                    opacity: sidebarOpen ? 1 : 0,
                    my: isChildPage ? 0 : 'initial',
                    '& > span': {
                        fontWeight: location.pathname.includes(`/${i18n.language}${navItem.url}`) ? 'bold' : '',
                    },
                }}/>
                {navItem.childPages?.length > 0 && (
                    <>
                        {subpagesOpen ? <ExpandLess/> : <ExpandMore/>}
                    </>
                )}
            </ListItemButton>
            {navItem.childPages?.length > 0 && (
                <Collapse in={subpagesOpen}>
                    <List sx={{
                        backgroundColor: 'primary.main',
                        height: '100%',
                    }}>
                        {navItem.childPages.map((navItem) => (
                            <NavItem navItem={navItem}
                                     isChildPage={true}
                                     sidebarOpen={sidebarOpen}
                                     key={navItem.key}/>
                        ))}
                    </List>
                </Collapse>
            )}
        </ListItem>
    )
}

export default NavItem;
