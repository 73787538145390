import countries from 'i18n-iso-countries';
import deLocale from 'i18n-iso-countries/langs/de.json';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import csLocale from 'i18n-iso-countries/langs/cs.json';

countries.registerLocale(deLocale);
countries.registerLocale(enLocale);
countries.registerLocale(plLocale);
countries.registerLocale(csLocale);

export function getCountry(countryCode, lang) {
    if(countryCode && lang) {
        return countries.getName(countryCode, lang);
    } else {
        return '';
    }
}