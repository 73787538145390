function searchFunc(array, query, searchableProperties) {
    const foundItems = [];

    array.forEach((item) => {
        let itemFound = false;

        searchableProperties.forEach((prop) => {

            if (item[prop]) {
                if (!itemFound) {
                    itemFound = item[prop].toLowerCase().includes(query.toLowerCase());
                }
            }
        });

        if (itemFound) {
            foundItems.push(item);
        }
    });

    return foundItems;
}

export default searchFunc;
