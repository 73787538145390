import React, {useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import colors from '../../theme/colors';
import {Link} from 'react-router-dom';
import {routeQuotesClosed, routeOrdersClosed} from '../../config/routeItems';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogGetOffer from "../orders/DialogGetOffer";
import PdfThumbnail from "../utils/PdfThumbnail";

function ProductItemDetails({productItem}) {
    const {t, i18n} = useTranslation();
    const [getOfferDialogOpen, setGetOfferDialogOpen] = useState(false);
    const handleClickGetOffer = () => {
        setGetOfferDialogOpen(true);
    };

    const handleGetOfferDialogClose = () => {
        setGetOfferDialogOpen(false);
    };

    return (
        <>
            <Grid container sx={{flexWrap: 'nowrap'}}>
                <Grid item xs={12} sx={{flexGrow: 1, minWidth: '100%'}}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            lg: 'row',
                        },
                        flexWrap: 'wrap',
                        gap: 0.75,
                        justifyContent: 'space-between',
                    }}>
                        {(productItem.details?.contentTable?.length > 0 || (productItem.loPath && productItem?.details?.file)) && (
                            <Grid container spacing={1}>
                                {productItem.details?.contentTable?.length > 0 && (
                                    <Grid item xs={12} md={8}>
                                        <Accordion sx={{
                                            '.MuiAccordionSummary-expandIconWrapper': {
                                                alignSelf: 'start',
                                                marginTop: 0.75,
                                            },
                                        }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                <Typography variant={'subtitle3'}>
                                                    {t('customerportal.product.execution')}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {productItem.details?.contentTable.map((item, i) => (
                                                    <Box key={i}
                                                         sx={{
                                                             paddingY: 0.25,
                                                             borderBottom: 1,
                                                             borderBottomStyle: 'solid',
                                                             borderBottomColor: 'primary.600',
                                                         }}
                                                    >
                                                        {item.text}
                                                    </Box>
                                                ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {(productItem.loPath && productItem?.details?.file) && (
                                    <Grid item xs={12} md={4}>
                                        <PdfThumbnail file={productItem.details.file} fileUrl={productItem.loPath}/>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        <Box>
                            <FormControlLabel control={
                                <Checkbox disabled={true}
                                          onChange={() => {
                                          }}
                                          checked={productItem.alreadyProduced}
                                />
                            } label={t('customerportal.product.already_produced')}/>
                            <FormControlLabel control={
                                <Checkbox disabled={true}
                                          onChange={() => {
                                          }}
                                          checked={productItem.blocked}
                                />
                            } label={t('customerportal.product.blocked')}/>
                        </Box>
                        {/*<Box>*/}
                        {/*    {productItem.loPath && (*/}
                        {/*        <Button onClick={handleClickDocuments}*/}
                        {/*                startIcon={<FilePresentOutlined/>}>*/}
                        {/*            {t('customerportal.product.show_documents')}*/}
                        {/*        </Button>*/}
                        {/*    )}*/}
                        {/*</Box>*/}
                        <Grid container sx={{
                            width: '100%',
                            pt: 0.75,
                            borderTop: `1px solid ${colors.white}`,
                        }} spacing={0.25}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Button component={Link}
                                                to={`/${i18n.language}${routeQuotesClosed.url}?q=${productItem.articleNumber}`}
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    textAlign: 'center',
                                                }}
                                                color={'secondary'}
                                                variant={'contained'}>
                                            {t('customerportal.navigation.quotes_closed')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color={'secondary'}
                                                onClick={handleClickGetOffer}
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    textAlign: 'center',
                                                }}
                                                variant={'contained'}>
                                            {t('customerportal.order.get_offer')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button component={Link}
                                                to={`/${i18n.language}${routeOrdersClosed.url}?q=${productItem.articleNumber}`}
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    textAlign: 'center',
                                                }}
                                                color={'secondary'}
                                                variant={'contained'}>
                                            {t('customerportal.navigation.orders_closed')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/*<Button onClick={handleClickGetOffer}*/}
                            {/*        color={'secondary'}*/}
                            {/*        variant={'contained'}>*/}
                            {/*    {t('customerportal.order.get_offer')}*/}
                            {/*</Button>*/}
                        </Grid>
                    </Box>
                </Grid>

                <Grid item>
                    <Box sx={{width: '24px'}}>
                        <>
                            {/*icon placeholder*/}
                        </>
                    </Box>
                </Grid>

            </Grid>

            {getOfferDialogOpen && (
                <DialogGetOffer
                    title={t('customerportal.order.get_offer')}
                    item={{
                        debtorNumber: productItem.customerNumber,
                        articles: [{
                            articleNumber: productItem.articleNumber,
                            description: productItem.notation1,
                            description2: productItem.notation2,
                            quantity: productItem.quantity || 0,
                            isRealArticle: true,
                        }]
                    }}
                    open={getOfferDialogOpen}
                    handleClose={handleGetOfferDialogClose}/>
            )}
        </>

    );
}

export default ProductItemDetails;
