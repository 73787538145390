import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useMsal} from '@azure/msal-react';
import {useDebouncedEffect} from '../../hooks/useDebouncedEffect';
import searchFunc from '../../utils/searchFunc';
import Api from '../../api/Api';
import {getProducts} from '../../redux/actions/products';
import {Grid, Pagination, TextField, Typography} from '@mui/material';
import SearchFieldSkeleton from '../skeletons/SearchFieldSkeleton';
import OrderSkeletonTableHead from '../skeletons/OrderSkeletonTableHead';
import OrderSkeletonList from '../skeletons/OrderSkeletonList';
import {productsPaginationCount} from '../../config/paginationConfig';
import {SearchOutlined} from '@mui/icons-material';
import ProductTableHead from './ProductTableHead';
import ProductItem from './ProductItem';

function ProductList() {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const products = useSelector(state => state.products);
    const {instance, accounts} = useMsal();
    const [page, setPage] = useState(1);
    const [renderedProducts, setRenderedProducts] = useState([]);
    const [internalLoading, setInternalLoading] = useState(true);
    const [searchValueInit, setSearchValueInit] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleSearchInput = (event) => {
        setSearchValue(event.target.value);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useDebouncedEffect(() => {
        if (!searchValueInit) {
            setSearchValueInit(true);
            return;
        }

        if (searchValue.length > 3) {
            setPage(1);
            setRenderedProducts(searchFunc(
                products.items,
                searchValue.trim(),
                [
                    'articleNumber',
                    'referenceNumber',
                    'notation1',
                    'notation2',
                ]),
            );
        } else {
            setRenderedProducts(products.items);
        }
    }, [searchValue], 500);

    useEffect(() => {
        setRenderedProducts(products.items);

        // eslint-disable-next-line
    }, [products]);

    useEffect(() => {
        if (!products.items || products.items.length < 1) {
            Api.acquireToken(instance, accounts[0]).then((res) => {
                dispatch(getProducts());
                setInternalLoading(false);
            });
        } else {
            setInternalLoading(false);
        }

        // eslint-disable-next-line
    }, []);

    return (
        <Grid container spacing={0.625}>
            {(products.loading || internalLoading) ? (
                <>
                    <SearchFieldSkeleton/>
                    <OrderSkeletonTableHead/>
                    <OrderSkeletonList count={productsPaginationCount}/>
                </>
            ) : (
                (products.items && products.items.length > 0) ? (
                    <Grid item xs={12}>
                        <Grid container spacing={0.75}>
                            <Grid item xs={12} sx={{
                                marginBottom: 3,
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}>
                                <SearchOutlined sx={{
                                    marginRight: 1,
                                    color: 'primary',
                                }}/>
                                <TextField label={t('customerportal.form.search')}
                                           value={searchValue}
                                           variant="standard"
                                           onChange={handleSearchInput}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{display: {xs: 'none', lg: 'block'}}}>
                                <ProductTableHead/>
                            </Grid>

                            {(renderedProducts && renderedProducts.length > 0) && renderedProducts.slice(productsPaginationCount * (page - 1), productsPaginationCount * page).map((product) => (
                                <Grid item key={product.articleNumber} xs={12}>
                                    <ProductItem productItem={product}
                                                 initialOpen={product.articleNumber === products.items[0].articleNumber} />
                                </Grid>
                            ))}

                            {renderedProducts && renderedProducts.length > productsPaginationCount && (
                                <Pagination count={Math.ceil(renderedProducts.length / productsPaginationCount)}
                                            disabled={products.loading}
                                            sx={{
                                                marginTop: 3,
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                            }}
                                            page={page}
                                            onChange={handlePageChange}
                                            color={'primary'}
                                />
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item sx={{marginY: 1}}>
                        <Typography variant={'body1'}>{t('customerportal.products.no_items')}</Typography>
                    </Grid>
                )
            )}

            {searchValue.length > 0 && renderedProducts < 1 && (
                <Grid item sx={{marginY: 1}}>
                    <Typography variant={'body1'}>{t('customerportal.products.no_items')}</Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default ProductList;