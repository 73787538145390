import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import moment from 'moment';
import {NO_DATA_STRING, ORDER_TYPE_OPEN} from '../../config/orderConfig';
import {useTranslation} from 'react-i18next';

function OrderArticleItem({article, orderType}) {

    const {t} = useTranslation();

    return (
        <Grid container columnSpacing={2} alignItems="flex-start">

            <Grid item xs={12} lg={2.5} xl={3}>
                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                    {article.description}
                    {article.descriptionAddition && (
                        <Box component="span"> - {article.descriptionAddition}</Box>
                    )}
                </Typography>
            </Grid>

            <Grid item xs={12} lg={2} xl={2}>
                <Typography sx={{display: {xs: 'inline', lg: 'none'}}} variant="body2" component="span">
                    {t('customerportal.order.article_number')}:&ensp;
                </Typography>
                <Typography variant="body2" sx={{display: {xs: 'inline', lg: 'block'}}}>
                    {article.articleNumber}
                </Typography>
            </Grid>

            <Grid item xs={12} lg={orderType === ORDER_TYPE_OPEN ? 1.5 : 2.25} xl={2}>
                <Typography sx={{display: {xs: 'inline', lg: 'none'}}} variant="body2" component="span">
                    {t('customerportal.order.article_number_customer')}:&ensp;
                </Typography>
                <Typography variant="body2" sx={{display: {xs: 'inline', lg: 'block'}}}>
                    {article.articleNumberCustomer ?? NO_DATA_STRING}
                </Typography>
            </Grid>

            <Grid item xs={12} lg={orderType === ORDER_TYPE_OPEN ? 0.75 : 2.75} xl={orderType === ORDER_TYPE_OPEN ? 1 : 3}>
                <Typography sx={{display: {xs: 'inline', lg: 'none'}}} variant="body2" component="span">
                    {t('customerportal.order.article_quantity')}:&ensp;
                </Typography>
                <Typography variant="body2" sx={{display: {xs: 'inline', lg: 'block'}, textAlign: 'right'}}>
                    {article.quantity ?? NO_DATA_STRING}
                </Typography>
            </Grid>

            <Grid item xs={12} lg={orderType === ORDER_TYPE_OPEN ? 1 : 2.5} xl={orderType === ORDER_TYPE_OPEN ? 1 : 2}>
                <Typography sx={{display: {xs: 'inline', lg: 'none'}}} variant="body2" component="span">
                    {t('customerportal.order.article_price')}:&ensp;
                </Typography>
                <Typography variant="body2" sx={{display: {xs: 'inline', lg: 'block'}, textAlign: 'right'}}>
                    {article.price ? (Math.round(article.price * 100) / 100).toFixed(2) : NO_DATA_STRING}
                </Typography>
            </Grid>

            {orderType === ORDER_TYPE_OPEN &&
                <Grid item xs={12} lg={2} xl={1.5}>
                    <Typography sx={{display: {xs: 'inline', lg: 'none'}}} variant="body2" component="span">
                        {t('customerportal.order.article_status')}:&ensp;
                    </Typography>
                    <Typography variant="subtitle2" sx={{display: {xs: 'inline', lg: 'block'}}}>
                        {article.status ? t(`customerportal.order.article.status.${article.status}`) : NO_DATA_STRING}
                    </Typography>
                </Grid>
            }

            {orderType === ORDER_TYPE_OPEN &&
                <Grid item xs={12} lg={2} xl={1.5}>
                    <Typography sx={{display: {xs: 'inline', lg: 'none'}}} variant="body2" component="span">
                        {t('customerportal.order.delivery_date')}:&ensp;
                    </Typography>
                    <Typography variant="body2" sx={{display: {xs: 'inline', lg: 'block'}}}>
                        {article.deliveryDate ? moment(article.deliveryDate).format('DD.MM.YYYY') : NO_DATA_STRING}
                    </Typography>
                </Grid>
            }
        </Grid>
    );
}

export default OrderArticleItem;
