import React from 'react';
import {Controller} from 'react-hook-form';
import {TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';

const ReactHookFormText = ({
                               name,
                               label,
                               defaultValue,
                               multiline,
                               disabled,
                               rules,
                               rows,
                               ...props
                           }) => {
    const {t} = useTranslation();

    return (
        <Controller name={name}
                    rules={rules}
                    render={({
                                 field: {type, onChange, onBlur, value, name, ref},
                                 fieldState: {invalid, isTouched, isDirty, error},
                                 formState,
                             }) => (
                        <TextField
                            error={!!error}
                            type={type}
                            required={rules?.required?.value}
                            helperText={error ? t(error.message) : null}
                            onBlur={onBlur}
                            value={value !== undefined ? value : defaultValue || ''}
                            onChange={onChange}
                            inputRef={ref}
                            name={name}
                            label={t(label)}
                            variant="filled"
                            multiline={multiline}
                            rows={rows}
                            disabled={disabled}
                        />
                    )}
        />
    );
};

export default ReactHookFormText;