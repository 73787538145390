import React from 'react';
import {Grid, Skeleton} from '@mui/material';

function OrderSkeletonTableHead() {
    return (
        <Grid container spacing={1} alignItems={{lg: 'center'}} sx={{display: {xs: 'none', md: 'flex'}, opacity: 0.5, paddingY: 1, paddingX: 1.5}}>

            <Grid item md={2}>
                <Skeleton height={15} animation="wave"/>
            </Grid>
            <Grid item md={2}>
                <Skeleton height={15} animation="wave"/>
            </Grid>
            <Grid item md={2}>
                <Skeleton height={15} animation="wave"/>
            </Grid>
            <Grid item md={2} sx={{marginLeft: 'auto'}}>
                <Skeleton height={15} animation="wave"/>
            </Grid>
            <Grid item md={2}>
                <Skeleton height={15} animation="wave"/>
            </Grid>

        </Grid>
    );
}

export default OrderSkeletonTableHead;
