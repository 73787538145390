import React from 'react';
import PageWrapper from '../utils/PageWrapper';
import {useTranslation} from 'react-i18next';
import {Button, Grid, Typography, Alert} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {routeDashboard} from '../../config/routeItems';

function InquirySuccess(props) {
    const {i18n, t} = useTranslation();
    const navigate = useNavigate();

    const onClickToDashboard = () => {
        navigate(`/${i18n.language}${routeDashboard.url}`);
    };

    const onClickNewInquiry = () => {
        navigate(-1);
    };

    return (
        <PageWrapper siteTitle={'customerportal.inquiry_success.title'}>
            <Alert severity={'success'}>
                <Typography>{t('customerportal.inquiry_success.text')}</Typography>
            </Alert>

            <Grid container spacing={1} mt={3}>
                <Grid item>
                    <Button variant={'contained'}
                            disableElevation={true}
                            color={'secondary'}
                            onClick={onClickToDashboard}>
                        {t('customerportal.inquiry_success.to_dashboard')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant={'contained'}
                            disableElevation={true}
                            color={'primary'}
                            onClick={onClickNewInquiry}>
                        {t('customerportal.inquiry_success.new_inquiry')}
                    </Button>
                </Grid>
            </Grid>

        </PageWrapper>
    );
}

export default InquirySuccess;
