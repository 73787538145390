import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import colors from '../../theme/colors';

function ProductTableHead() {
    const {t} = useTranslation();

    return (
        <Grid container sx={{paddingX: 1}}>
            <Grid item sx={{flexGrow: 1}}>
                <Grid container columnSpacing={2} alignItems="flex-end">
                    <Grid item lg={3}>
                        <Typography variant="body2" color={colors.primary700}>
                            {t('customerportal.product.article_number')}
                        </Typography>
                    </Grid>

                    <Grid item lg={3}>
                        <Typography variant="body2" color={colors.primary700}>
                            {t('customerportal.product.reference_number')}
                        </Typography>
                    </Grid>

                    <Grid item lg={3}>
                        <Typography variant="body2" color={colors.primary700}>
                            {t('customerportal.product.notation_1')}
                        </Typography>
                    </Grid>

                    <Grid item lg={3}>
                        <Typography variant="body2" color={colors.primary700}>
                            {t('customerportal.product.notation_2')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Box sx={{width: '24px'}}>
                    <>
                        {/*icon placeholder*/}
                    </>
                </Box>
            </Grid>
        </Grid>
    );
}

export default ProductTableHead;
