import {createAsyncThunk} from '@reduxjs/toolkit';
import Api from '../../api/Api';

export const getCompanyContacts = createAsyncThunk('company-contacts/get-company-contacts', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getCompanyContacts();

        if (res.value && res.value.length > 0) {
            const items = [];

            res.value.forEach((item) => {
                items.push({
                    contactNumber: item.kontaktnummer,
                    companyNumber: item.unternehmensnummer,
                    contactType: item.kontakttyp,
                    debtorNumber: item.debitorennummer,
                    salutation: item.anrede,
                    firstname: item.vorname,
                    lastname: item.nachname,
                    companyName: item.unternehmensName,
                    companyName2: item.unternehmensName2,
                    street: item.strasse,
                    addressAddition: item.addresszusatz,
                    countryCode: item.laendercode,
                    zip: item.postleitzahl,
                    city: item.ort,
                    mobile: item.telefonMobil,
                    phone: item.telefonFestnetz,
                    email: item.emailadresse,
                    website: item.internetseite,
                    language: item.sprachcode,
                    leadership: item.positionLeitung,
                    position: item.position,
                    blocked: item.blocked,
                    newsletter: item.newsletter,
                    customerportal: item.kundenportal,
                });
            });

            return items;
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const deactivateCompanyContact = createAsyncThunk('company-contacts/deactivate-company-contact', async (payload = false, {
    rejectWithValue,
    dispatch,
}) => {
    try {
        const {id, customerportal} = payload;

        const res = await Api.deactivateCompanyContact({
            kundenportal: customerportal,
        }, id);


        if (!res.error) {
            await dispatch(getCompanyContacts());

            return true;
        } else {
            return rejectWithValue({
                error: res.error.message,
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const addCompanyContact = createAsyncThunk('company-contacts/add-company-contact', async (payload = false, {rejectWithValue, getState, dispatch}) => {
    try {
        const state = getState();

        const formData = {
            kontakttyp: 'Person',
            anrede: payload.formData.salutation,
            emailadresse: payload.formData.email,
            nachname: payload.formData.lastname,
            position: payload.formData.position || '',
            positionLeitung: !!payload.formData.leadership,
            telefonFestnetz: payload.formData.phone,
            vorname: payload.formData.firstname,
            unternehmensNummer: state.azure?.user?.companyNumber,
        };

        const res = await Api.addCompanyContact(formData);

        if (!res.error) {
            await dispatch(getCompanyContacts());

            return {
                positions: res.value,
            };
        } else {
            return rejectWithValue({
                error: res.error.message,
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: 'Server Error',
        }, err);
    }
});
