import React from "react";
import {Grid} from "@mui/material";
import ContactPersonSkeletonItem from "./ContactPersonSkeletonItem";

function ContactPersonSkeletonList({count = 0, bigImg, col}) {
    if (count > 0) {
        return (
            <Grid container
                  spacing={1}
            >
                {Array.from(Array(count).keys()).map((key) => (
                    <ContactPersonSkeletonItem bigImg={bigImg} key={key} col={col}/>
                ))}
            </Grid>
        )
    }
}

export default ContactPersonSkeletonList;