import React from 'react';
import PageWrapper from "../utils/PageWrapper";
import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ContactPersonList from "../contactPersons/ContactPersonList";
import {useSelector} from 'react-redux';

function Contact(props) {
    const {t} = useTranslation();
    const client = useSelector((state) => state.azure.client);

    return (
        <PageWrapper siteTitle={'customerportal.navigation.contact'}>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Typography sx={{
                        marginBottom: 3,
                    }} dangerouslySetInnerHTML={
                        {__html: t(`customerportal.contact${client && `.${client}`}.description`, {interpolation: {escapeValue: false}})}
                    }/>
                    <ContactPersonList col={true} bigImg={true} showHeadlines={true}/>
                </Grid>
            </Grid>
        </PageWrapper>
    )
}

export default Contact;