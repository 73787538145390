import React from 'react';
import {Button, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useMsal} from '@azure/msal-react';

function ChangePasswordButton() {
    const {accounts} = useMsal();
    const {i18n, t} = useTranslation();

    if (accounts[0]?.idTokenClaims?.aud) {
        return (
            <Button variant={'contained'}
                    disableElevation={true}
                    color={'primary'}
                    component={'a'}
                    href={`${process.env.REACT_APP_MSAL_CHANGE_PASSWORD}&client_id=${accounts[0].idTokenClaims.aud}&redirect_uri=${window.location.origin}&lang=${i18n.language}&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login`}>
                <Typography>{t('customerportal.auth.change_password')}</Typography>
            </Button>
        );
    }
}

export default ChangePasswordButton;
