import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {groupSideNav} from '../../config/routeItems';
import rathgeberLogo from '../../assets/logos/rathgeber.svg';
import smartTecLogo from '../../assets/logos/smart-tec.svg';
import {useMsal} from '@azure/msal-react';
import {useTranslation} from 'react-i18next';
import NavItem from './sidebar/NavItem';
import NavGroup from './sidebar/NavGroup';
import {useNavigate} from 'react-router-dom';

const drawerWidth = 300;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    justifyContent: 'space-between',
    '& > div > .MuiBox-root': {
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1.25),
        paddingRight: theme.spacing(1.25),
        '& > img': {
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            width: '100%',
            height: 108,
        },
    },
    backgroundColor: theme.palette.primary.main,
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    justifyContent: 'space-between',
    '& > div > .MuiBox-root': {
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingTop: theme.spacing(0.75),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        '& > img': {
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            width: 42,
            height: 42,
        },
    },
    backgroundColor: theme.palette.primary.main,
    overflowX: 'hidden',
    width: `calc(${theme.spacing(3.625)} + 1px)`,
});

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    }),
);

function AppSidebar() {
    const {i18n, t} = useTranslation();
    const client = useSelector((state) => state.azure.client);
    const {instance} = useMsal();
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);

    const handleLogout = () => {
        instance.logoutRedirect({
            extraQueryParameters: {
                lang: i18n.language,
            },
        });
    };

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const navigateToDashboard = () => {
        navigate(`/${i18n.language}/dashboard`, {replace: true});
    };

    return (
        <Drawer variant="permanent" open={open}>
            <div>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <img src={client === 'smart-tec' ? smartTecLogo : rathgeberLogo}
                         alt="Rathgeber"
                         style={{cursor: 'pointer'}}
                         onClick={navigateToDashboard}/>
                </Box>
                <List sx={{
                    backgroundColor: 'primary.main',
                    height: '100%',
                    paddingTop: 1,
                }}>
                    {groupSideNav.map((item) => (
                        <NavItem key={item.key} navItem={item} sidebarOpen={open}/>
                    ))}

                    <NavGroup sidebarOpen={open}/>

                </List>
            </div>
            <List sx={{
                backgroundColor: 'primary.main',
                height: 'auto',
                py: 1.75,
            }}>
                <ListItem disablePadding
                          onClick={handleDrawerToggle}
                          sx={{
                              display: 'block',
                              color: 'grey.200',
                              '& svg': {
                                  color: 'grey.200',
                              },
                              '&:hover': {
                                  backgroundColor: 'primary.dark',
                              },
                          }}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 1.25,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 1.875 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            {open ? <KeyboardDoubleArrowLeftOutlinedIcon/> : <KeyboardDoubleArrowRightOutlinedIcon/>}
                        </ListItemIcon>
                        <ListItemText primary={t('customerportal.navigation.collapse_sidebar')}
                                      sx={{opacity: open ? 1 : 0}}/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding
                          onClick={handleLogout}
                          sx={{
                              display: 'block',
                              color: 'grey.200',
                              '& svg': {
                                  color: 'grey.200',
                              },
                              '&:hover': {
                                  backgroundColor: 'primary.dark',
                              },
                          }}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 1.25,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 1.875 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <LogoutOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('customerportal.auth.logout')} sx={{opacity: open ? 1 : 0}}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
}

export default AppSidebar;
