import React, {Fragment, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {
    alpha,
    BottomNavigation,
    BottomNavigationAction, Drawer, Link,
} from '@mui/material';
import {
    MoreHorizOutlined,
} from '@mui/icons-material';
import {
    groupBottomNav,
    groupBottomNavMore,
    groupBottomNavMoreExternal,
    routeChooseClient,
} from '../../config/routeItems';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import {useSelector} from 'react-redux';
import {useMsal} from '@azure/msal-react';
import {useTranslation} from 'react-i18next';

function AppBottomNav() {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const client = useSelector((state) => state.azure.client);
    const {i18n, t} = useTranslation();
    const {accounts} = useMsal();

    const [open, setOpen] = useState(false);

    const handleNavigationClick = (newValue) => {
        navigate(newValue, {replace: true});
    };

    const handleMoreClick = () => {
        setOpen(true);
    };

    const handleMoreNavItemClick = (val) => {
        handleNavigationClick(val);
        setOpen(false);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(!open);
    };

    return (
        <Fragment>
            <Drawer anchor={'bottom'}
                    open={open}
                    disablePortal
                    onClose={toggleDrawer(open)}
                    sx={{
                        zIndex: 999,
                    }}
            >
                <List>
                    {groupBottomNavMore.map((navItem) => (
                        <ListItem key={navItem.key}
                                  disablePadding
                                  onClick={() => handleMoreNavItemClick(`/${i18n.language}${navItem.url}`)}
                                  sx={{
                                      display: 'block',
                                      color: pathname.includes(`/${i18n.language}${navItem.url}`) ? 'primary.main' : 'common.black.light',
                                      boxShadow: pathname.includes(`/${i18n.language}${navItem.url}`) ? 'inset 3px 0px 0px 0px #ffffff' : '',
                                      '& svg': {
                                          color: pathname.includes(`/${i18n.language}${navItem.url}`) ? 'primary.main' : 'common.black.light',
                                      },
                                  }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 1.25,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 1.875 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {navItem.icon}
                                </ListItemIcon>
                                <ListItemText primary={t(navItem.label)} sx={{opacity: open ? 1 : 0}}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                    {(accounts[0] && accounts[0].idTokenClaims.extension_Mandanten?.split('|').length > 1) &&
                        <ListItem disablePadding
                                  onClick={() => handleMoreNavItemClick(`/${i18n.language}${routeChooseClient.url}`)}
                                  sx={{
                                      display: 'block',
                                      color: pathname.includes(`/${i18n.language}${routeChooseClient.url}`) ? 'primary.main' : 'common.black.light',
                                      boxShadow: pathname.includes(`/${i18n.language}${routeChooseClient.url}`) ? 'inset 3px 0px 0px 0px #ffffff' : '',
                                      '& svg': {
                                          color: pathname.includes(`/${i18n.language}${routeChooseClient.url}`) ? 'primary.main' : 'common.black.light',
                                      },
                                  }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 1.25,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 1.875 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {routeChooseClient.icon}
                                </ListItemIcon>
                                <ListItemText primary={t(routeChooseClient.label)} sx={{opacity: open ? 1 : 0}}/>
                            </ListItemButton>
                        </ListItem>
                    }
                    {groupBottomNavMoreExternal.map((navItem) => (
                        <ListItem disablePadding key={navItem.key}>
                            <Link href={t(`${navItem.url}.${client}`)}
                                  underline={'none'}
                                  sx={(theme) => ({
                                      display: 'block',
                                      color: alpha(theme.palette.common.black, 0.87),
                                      width: '100%',
                                      '& svg': {
                                          color: alpha(theme.palette.common.black, 0.54),
                                      },
                                  })}
                                  target={'_blank'}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 1.25,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 1.875 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {navItem.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={t(navItem.label)} sx={{opacity: open ? 1 : 0}}/>
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <BottomNavigation value={pathname}
                              sx={{
                                  backgroundColor: 'primary.main',
                                  position: 'fixed',
                                  width: '100%',
                                  bottom: 0,
                                  '& .MuiBottomNavigationAction-root, .Mui-selected, svg': {
                                      color: 'common.white',
                                  },
                                  display: {
                                      md: 'none',
                                  },
                                  zIndex: 99,
                                  height: 70,
                              }}
            >
                {groupBottomNav.map((navItem) => (
                    <BottomNavigationAction
                        key={navItem.key}
                        label={t(navItem.label)}
                        value={`/${i18n.language}${navItem.url}`}
                        icon={navItem.icon}
                        showLabel={pathname.includes(`/${i18n.language}${navItem.url}`)}
                        selected={pathname.includes(`/${i18n.language}${navItem.url}`).toString()}
                        onClick={() => handleNavigationClick(`/${i18n.language}${navItem.url}`)}
                        sx={{
                            px: {
                                xs: 0,
                                ty: 0.75,
                            },
                            '& .MuiBottomNavigationAction-label': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'block',
                                width: '100%',
                                fontSize: '0.685rem !important',
                            },
                        }}
                    />
                ))}
                <BottomNavigationAction
                    key="more"
                    label={t('customerportal.navigation.more')}
                    value="more"
                    icon={<MoreHorizOutlined/>}
                    onClick={handleMoreClick}
                    sx={{
                        '& .MuiBottomNavigationAction-label': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                            width: '100%',
                        },
                        px: {
                            xs: 0,
                            ty: 0.75,
                        },
                    }}
                />
            </BottomNavigation>
        </Fragment>
    );
}

export default AppBottomNav;