import React from "react";
import {Grid} from "@mui/material";
import PropTypes from 'prop-types';
import GenerateFormField from "./GenerateFormField";

function FormRow({field}) {
    return (
        <Grid item xs={12} md={field.rowSize} sx={{
            display: field.alignCenter ? 'flex' : 'block',
            alignItems: 'center'
        }}>
            <GenerateFormField field={field} />
        </Grid>
    )
}

FormRow.propTypes = {
    field: PropTypes.shape({
        rowSize: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
            PropTypes.any
        ]),
        alignCenter: PropTypes.bool,
        disabled: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.any.isRequired
        }))
    }).isRequired
}

export default FormRow;