import React, {useEffect, useState} from 'react';
import Dialog from './Dialog';
import {Trans, useTranslation} from 'react-i18next';
import {isIos, isInStandaloneMode} from '../../service/userAgent.service';
import {IosShareOutlined} from '@mui/icons-material';

function PwaInstallMessage() {
    const {t} = useTranslation();
    const [pwaInstallDialogOpen, setPwaInstallDialogOpen] = useState(false);

    useEffect(() => {
        if (isIos() && !isInStandaloneMode() && !localStorage.getItem('installMessageShown')) {
            setPwaInstallDialogOpen(true);
        }
    }, []);

    return (
        <Dialog open={pwaInstallDialogOpen}
                onClose={() => setPwaInstallDialogOpen(false)}
                title={t('customerportal.pwa_install_dialog.title')}
                text={
                    <Trans i18nKey={'customerportal.pwa_install_dialog.text'}>
                        <>text <IosShareOutlined/> text</>
                    </Trans>
                }
                onConfirm={() => {
                    localStorage.setItem('installMessageShown', 'true');
                    setPwaInstallDialogOpen(false);
                }}
                confirmText={'customerportal.pwa_install_dialog.dont_show_again'}
                cancelText={'customerportal.pwa_install_dialog.confirm'}
        />
    );
}

export default PwaInstallMessage;