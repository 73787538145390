import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import FormSection from '../form/FormSection';
import {formRowSizes} from '../../config/form/formRowSizes';
import FormRow from '../form/FormRow';
import {formRowTypes} from '../../config/form/formRowTypes';
import {FormProvider, useForm} from 'react-hook-form';
import {Grid, Link, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import FormFieldsSkeleton from '../skeletons/FormFieldSkeleton';
import {sendInquiry} from '../../redux/actions/inquiry';
import {useNavigate} from 'react-router-dom';
import {routeInquirySuccess} from '../../config/routeItems';
import {resetInquiry} from '../../redux/slices/inquiry';
import ApiErrorSnackbar from '../utils/ApiErrorSnackbar';
import {getPossibleSalutations} from '../../redux/actions/azure';


function InquiryForm(props) {
    const {inquiryType} = props;
    const dispatch = useDispatch();
    const {user, azureClient, contactPersons} = useSelector((state) => state.azure);
    const {isLoading, success, hasError, errorMessage} = useSelector((state) => state.inquiry);
    const possibleSalutations = useSelector((state) => state.azure.possibleSalutations);
    const methods = useForm({});
    const {i18n, t} = useTranslation();
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (!possibleSalutations?.length) {
            dispatch(getPossibleSalutations());
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (form) => {
        dispatch(resetInquiry());
        setShowError(false);
        form.salutation = t(`customerportal.salutation.${form.salutation}`);

        if (window.dataLayer) {
            window.dataLayer.push({
                event: inquiryType,
                formulartyp: inquiryType,
                login: user.companyName,
                'e-mail': user.email,
                kundennummer: user.companyNumber,
            });
        }

        dispatch(sendInquiry({
            inquiryType: inquiryType,
            language: i18n.language,
            client: azureClient,
            customerNumber: user.companyNumber,
            customerName: user.customerName,
            supervisor: contactPersons.find(p => p.type === 'supervisor'),
            form: form,
        }));
    };

    const handleSnackbarClose = () => {
        setShowError(false);
    };

    useEffect(() => {
        if (success) {
            dispatch(resetInquiry());
            navigate(`/${i18n.language}${routeInquirySuccess.url}`);
        }
        if (hasError) {
            setShowError(true);
        }
        // eslint-disable-next-line
    }, [success, hasError]);

    useEffect(() => {
        if (user) {
            methods.reset({
                salutation: user.salutation,
                firstname: user.firstname,
                lastname: user.lastname,
                company: user.companyName,
                street: user.street,
                zip: user.zip,
                place: user.place,
                email: user.email,
                phone: user.phone,
                inquiry: '',
            });
        }

        // eslint-disable-next-line
    }, [user]);

    return (
        <>
            <ApiErrorSnackbar open={showError} errorMessage={errorMessage} handleClose={handleSnackbarClose}/>
            {user ?
                <FormProvider {...methods}>
                    <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                        <FormSection>
                            {possibleSalutations?.length && (
                                <FormRow field={{
                                    rowSize: formRowSizes.small,
                                    label: 'customerportal.form.salutation',
                                    defaultValue: user.salutation,
                                    disabled: true,
                                    id: 'salutation',
                                    type: formRowTypes.select,
                                    options: possibleSalutations.map((salutation) => ({
                                        value: salutation.Code,
                                        label: `customerportal.salutation.${salutation.Code}`,
                                    })),
                                }}/>
                            )}
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.firstname',
                                defaultValue: user.firstname,
                                disabled: true,
                                id: 'firstname',
                                type: formRowTypes.text,
                            }}/>
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.lastname',
                                defaultValue: user.lastname,
                                disabled: true,
                                id: 'lastname',
                                type: formRowTypes.text,
                            }}/>
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.company',
                                defaultValue: user.companyName,
                                disabled: true,
                                id: 'company',
                                type: formRowTypes.text,
                            }}/>
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.street',
                                defaultValue: user.street,
                                disabled: true,
                                id: 'street',
                                type: formRowTypes.text,
                            }}/>
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.zip',
                                defaultValue: user.zip,
                                disabled: true,
                                id: 'zip',
                                type: formRowTypes.text,
                            }}/>
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.place',
                                defaultValue: user.place,
                                disabled: true,
                                id: 'place',
                                type: formRowTypes.text,
                            }}/>
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.mail',
                                defaultValue: user.email,
                                disabled: true,
                                id: 'email',
                                type: formRowTypes.text,
                            }}/>
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.phone',
                                defaultValue: user.phone,
                                disabled: true,
                                id: 'phone',
                                type: formRowTypes.text,
                            }}/>
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.inquiry',
                                multiline: true,
                                disabled: isLoading,
                                id: 'inquiry',
                                rules: {
                                    required: {
                                        value: true,
                                        message: 'customerportal.form.validation.required',
                                    },
                                },
                                type: formRowTypes.text,
                            }}/>
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.files',
                                labelExtended: 'customerportal.form.files_extended',
                                defaultValue: '',
                                disabled: isLoading,
                                multiple: true,
                                id: 'files',
                                rules: {
                                    required: {
                                        value: false,
                                        message: 'customerportal.form.validation.required',
                                    },
                                },
                                type: formRowTypes.fileUpload,
                            }}/>
                            <Grid item xs={12}>
                                <Grid container spacing={1.25}>
                                    <FormRow field={{
                                        rowSize: formRowSizes.mid,
                                        label: 'customerportal.form.agb',
                                        labelLink: <>Text <Link target="_blank" underline="hover"
                                                                href={t('customerportal.form.agbLink')}>Link</Link><Link
                                            target="_blank" underline="hover"
                                            href={t('customerportal.form.dsgvoLink')}>Link</Link></>,
                                        multiline: true,
                                        disabled: isLoading,
                                        id: 'agb',
                                        rules: {
                                            required: {
                                                value: true,
                                                message: 'customerportal.form.validation.checkbox.required',
                                            },
                                        },
                                        type: formRowTypes.checkbox,
                                    }}/>
                                    <Grid item xs={12} md={4} sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'start',
                                    }}>
                                        <LoadingButton variant={'contained'}
                                                       loading={isLoading}
                                                       disableElevation={true}
                                                       color={'secondary'}
                                                       type={'submit'}
                                        >
                                            <Typography>{t('customerportal.form.send_inquiry')}</Typography>
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormSection>
                    </form>
                </FormProvider> :
                <FormFieldsSkeleton count={11}/>
            }
        </>
    );
}

export default InquiryForm;
