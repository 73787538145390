import React from 'react';
import {Controller} from "react-hook-form";
import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import {useTranslation} from "react-i18next";

const ReactHookFormSelect = ({
                                 name,
                                 label,
                                 control,
                                 defaultValue,
                                 children,
                                 disabled,
                                 rules,
                                 ...props
                             }) => {
    const {t} = useTranslation();

    return (
        <Controller
            rules={rules}
            render={({
                         field: {onChange, onBlur, value, name, ref},
                         fieldState: {invalid, isTouched, isDirty, error},
                         formState,
                     }) => (
                <FormControl {...props}
                             disabled={disabled}
                             fullWidth
                             error={!!error}
                >
                    <InputLabel sx={{
                        left: 12,
                        '&.MuiFormLabel-filled': {
                            top: '8px'
                        }
                    }}>
                        {label}
                    </InputLabel>
                    <Select
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        inputRef={ref}
                        name={name}
                        disabled={disabled}
                        control={control}
                        defaultValue={defaultValue}
                        sx={{
                            marginTop: '0px !important'
                        }}
                    >
                        {children}
                    </Select>
                    {!!error &&
                        <FormHelperText>{t(error.message)}</FormHelperText>
                    }
                </FormControl>
            )}
            name={name}
        />
    );
};
export default ReactHookFormSelect;