import React from 'react';
import {Alert, Button, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

function CorrectionDrawingSuccess({formData}) {
    const {t, i18n} = useTranslation();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={10}>
                <Alert severity={'success'} sx={{
                    marginTop: 2,
                }}>
                    <Typography
                        variant={'subtitle1'}>{t(`customerportal.correction_drawings.success.${formData.layout.correct === 'correct' && formData.description.correct === 'correct' ? 'correct' : 'incorrect'}`)}</Typography>
                </Alert>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button variant={'contained'}
                                disableElevation={true}
                                color={'secondary'}
                                component={Link}
                                to={`/${i18n.language}/dashboard`}
                        >
                            {t('customerportal.correction_drawings.success.to_dashboard')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant={'contained'}
                                disableElevation={true}
                                color={'primary'}
                                component={Link}
                                to={`/${i18n.language}/layout-release`}
                        >
                            {t('customerportal.correction_drawings.success.to_correction_drawings')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CorrectionDrawingSuccess;