import React from 'react';
import PageWrapper from "../utils/PageWrapper";
import CorrectionDrawingsList from '../correctionDrawings/CorrectionDrawingsList';
import {Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import OrderManager from '../contactPersons/OrderManager';
import {useSelector} from 'react-redux';

function LayoutRelease() {
    const {t} = useTranslation();
    const client = useSelector((state) => state.azure.client);

    return (
        <PageWrapper siteTitle={'customerportal.navigation.layout_release'}>
            <Grid container
                  columnSpacing={5}
                  rowSpacing={2.5}
            >
                <Grid item xs={12} lg={8}>
                    <Typography sx={{
                        marginBottom: 3,
                    }} dangerouslySetInnerHTML={
                        {__html: t(`customerportal.correction_drawings${client && `.${client}`}.welcome_description`, {interpolation: {escapeValue: false}})}
                    }/>
                    <CorrectionDrawingsList/>
                </Grid>
                <Grid item
                      xs={12}
                      lg={4}
                      sx={{
                          position: 'relative'
                      }}
                >
                    <OrderManager/>
                </Grid>
            </Grid>
        </PageWrapper>
    )
}

export default LayoutRelease;