import React, {useRef, useCallback, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FormControl, FormHelperText, Link, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Dropzone from 'react-dropzone';
import {DeleteOutlined, FileUploadOutlined} from '@mui/icons-material';

const ReactHookFormFileUpload = ({
                                     name,
                                     label,
                                     labelExtended,
                                     control,
                                     defaultValue,
                                     children,
                                     multiple,
                                     disabled,
                                     rules,
                                     ...props
                                 }) => {
    const {t} = useTranslation();
    const inputFile = useRef(null);
    const [dragOver, setDragOver] = useState(false);

    const onDrop = useCallback((acceptedFiles, previousFiles, onChange) => {
        if (previousFiles.length > 0) {
            onChange([...previousFiles, ...acceptedFiles]);
        } else {
            onChange(acceptedFiles);
        }
        setDragOver(false);
    }, []);

    const deleteFile = useCallback((e, value, index, onChange) => {
        e.stopPropagation();

        const tempValues = [...value];
        tempValues.splice(index, 1);
        onChange(tempValues);
    }, []);

    return (
        <Controller
            control={control}
            defaultValue=""
            name={name}
            rules={rules}
            render={({
                         field: {onChange, onBlur, value, name, ref},
                         fieldState: {invalid, isTouched, isDirty, error},
                         formState,
                     }) => {
                return (
                    <FormControl {...props}
                                 disabled={disabled}
                                 fullWidth
                                 error={!!error}
                    >
                        <Dropzone onDrop={(files) => onDrop(files, value, onChange)} onDragOver={() => setDragOver(true)} onDragLeave={() => setDragOver(false)}>
                            {({getRootProps, getInputProps}) => (
                                <>
                                    <Box {...getRootProps()}
                                         sx={{
                                             backgroundColor: 'common.white',
                                             padding: 1,
                                             display: 'flex',
                                             justifyContent: 'center',
                                             flexDirection: 'column',
                                             alignItems: 'center',
                                             borderWidth: 1,
                                             borderStyle: dragOver ? 'solid' : 'dashed',
                                             borderColor: 'primary',
                                             borderRadius: 1,
                                             cursor: 'pointer',
                                             '&:hover': {
                                                 borderStyle: 'solid'
                                             }
                                         }}
                                    >
                                        <>
                                            <FileUploadOutlined sx={{
                                                width: 35,
                                                height: 35,
                                                marginBottom: 0.5,
                                            }}/>
                                            <Typography variant="body2" sx={{
                                                marginBottom: 0.25,
                                            }}>{t('customerportal.form.drop_file')}</Typography>
                                            <Typography variant="body2" sx={{
                                                marginBottom: 0.25,
                                            }}>{t('customerportal.or')}</Typography>
                                            <Link component="label"
                                                  htmlFor={name}
                                                  sx={{
                                                      cursor: 'pointer',
                                                      flex: 'none',
                                                      display: 'block',
                                                  }}
                                            >
                                                {(value && value.length > 0) ? labelExtended : label}
                                            </Link>
                                            <Box sx={{
                                                marginTop: 1,
                                            }}>
                                                {value && Array.from(value).map((v, i) => (
                                                    <Box key={i}
                                                         sx={{
                                                             display: 'flex',
                                                             alignItems: 'center',
                                                             justifyContent: 'center',
                                                         }}>
                                                        <Typography variant="fileUploadText">{v.name}</Typography>
                                                        <DeleteOutlined onClick={(e) => deleteFile(e, value, i, onChange)}
                                                                        sx={(theme) => ({
                                                                            color: theme.palette.primary[700],
                                                                            marginLeft: 0.5,
                                                                        })}
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                        </>
                                    </Box>
                                    {!!error &&
                                        <FormHelperText>{t(error.message)}</FormHelperText>
                                    }
                                    <input type="file"
                                           id={name}
                                           ref={inputFile}
                                           multiple={multiple}
                                           style={{
                                               display: 'none',
                                           }}
                                           {...getInputProps()}
                                           onChange={e => onChange(e.target.files)}
                                    />
                                </>
                            )}
                        </Dropzone>
                    </FormControl>
                );
            }}
        />
    );
};

export default ReactHookFormFileUpload;