import React from 'react';
import {Button, MobileStepper} from '@mui/material';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';

function CorrectionDrawingMobileStepper({handleNext, handleBack, activeStep, maxSteps, nextDisabled}) {
    const {t} = useTranslation();

    return (
        <MobileStepper variant="text"
                       sx={{
                           display: {
                               xs: 'flex',
                               md: 'none',
                           },
                           position: 'fixed',
                           width: '100%',
                           left: 0,
                           right: 0,
                           bottom: 70,
                           zIndex: 99,
                       }}
                       nextButton={
                           <Button variant={'contained'}
                                   sx={{
                                       width: 'auto !important',
                                       boxShadow: 'none',
                                       '&:hover': {
                                           boxShadow: 'none',
                                       },
                                   }}
                                   color="secondary"
                                   size="small"
                                   onClick={handleNext}
                                   disabled={nextDisabled || activeStep === maxSteps - 1}
                                   endIcon={<KeyboardArrowRight/>}
                           >
                               {t('customerportal.next')}
                           </Button>
                       }
                       backButton={
                           <Button variant={'text'}
                                   size="small"
                                   sx={{
                                       width: 'auto !important',
                                       boxShadow: 'none',
                                       '&:hover': {
                                           boxShadow: 'none',
                                       },
                                   }}
                                   onClick={handleBack}
                                   disabled={activeStep === 0}>
                               <KeyboardArrowLeft/>
                               {t('customerportal.back')}
                           </Button>
                       }
                       steps={maxSteps}
                       position="static"
                       activeStep={activeStep}
        />
    );
}

export default CorrectionDrawingMobileStepper;