import React from 'react';
import {Box, Skeleton, Typography} from '@mui/material';

function CorrectionDrawingDetailSkeleton() {
    return (
        <Box>
            <Typography variant={'h2'}>
                <Skeleton animation="wave"/>
            </Typography>
            <Typography variant={'h3'}
                        sx={{
                            marginTop: 0.375,
                            display: {
                                xs: 'none',
                                sm: 'block',
                            },
                        }}
            >
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
            </Typography>
            <Typography variant={'subtitle2'}
                        sx={{
                            marginTop: 0.375,
                            display: {
                                xs: 'block',
                                sm: 'none',
                            },
                        }}
            >
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
                <Skeleton animation="wave"/>
            </Typography>
            <Typography variant={'h3'}
                        sx={{
                            marginTop: {
                                xs: 0.625,
                                sm: 1.75,
                            },
                            typography: {
                                xs: 'subtitle2',
                                sm: 'h3',
                            },
                        }}
                        color={'grey.500'}
            >
                <Skeleton animation="wave"/>
            </Typography>
        </Box>
    );
}

export default CorrectionDrawingDetailSkeleton;