import React, {useState, useEffect} from 'react';
import CorrectionDrawingMobileStepper from './CorrectionDrawingMobileStepper';
import {Button, Grid, Typography} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import FormRadio from '../form/FormRadio';
import FormRow from '../form/FormRow';
import {formRowSizes} from '../../config/form/formRowSizes';
import {formRowTypes} from '../../config/form/formRowTypes';
import Box from '@mui/material/Box';

function CorrectionDrawingDescription({handleNext, handleBack, activeStep, maxSteps, formData}) {
    const methods = useForm({
        defaultValues: formData,
    });
    const [isValid, setIsValid] = useState(false);
    const {t} = useTranslation();
    const values = methods.watch();
    const correctionDrawing = useSelector((state) => state.azure.correctionDrawingDetail.item);
    const descriptionIncorrect = methods.watch('description.correct') === 'incorrect';

    function onSubmit(data) {
        let tempData = null;

        if (data.description.correct === 'correct') {
            tempData = {
                description: {
                    correct: data.description.correct,
                },
            };
        } else {
            tempData = {
                description: {
                    correct: data.description.correct,
                    changeRequests: data.description.changeRequests,
                },
            };
        }

        handleNext(tempData['description'], 'description');
    }

    useEffect(() => {
        if (values.description) {
            if (values.description.correct === 'correct') {
                setIsValid(true);
            } else {
                if (values.description.changeRequests) {
                    setIsValid(true);
                } else {
                    setIsValid(false);
                }
            }
        } else {
            setIsValid(false);
        }
    }, [values]);

    return (
        <>
            <Grid container
                  spacing={{
                      xs: 2,
                      lg: 1.25,
                  }}
            >
                <Grid item xs={12} lg={8}>
                    <Typography variant={'subtitle1'}
                                sx={{marginBottom: 1}}>{t('customerportal.correction_drawings.description.execution')}</Typography>
                    {(correctionDrawing.contentTable && correctionDrawing.contentTable.length > 0) && correctionDrawing.contentTable.map((item, i) =>
                        <Box key={i}
                             sx={{
                                 paddingY: 0.25,
                                 borderBottom: 1,
                                 borderBottomStyle: 'solid',
                                 borderBottomColor: 'primary.600',
                             }}
                        >
                            {item.text}
                        </Box>,
                    )}
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Box sx={{
                        position: 'sticky',
                        top: 20,
                    }}>
                        <Typography variant={'body2'}>
                            {t('customerportal.correction_drawings.description.form_description')}
                        </Typography>
                        <Box sx={{
                            marginTop: 2,
                        }}>
                            <FormProvider {...methods}>
                                <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormRadio field={{
                                                label: '',
                                                disabled: false,
                                                id: 'description.correct',
                                                rules: {
                                                    required: {
                                                        value: true,
                                                        message: 'customerportal.form.validation.required',
                                                    },
                                                },
                                                items: [
                                                    {
                                                        value: 'correct',
                                                        label: 'customerportal.correction_drawings.description.correct',
                                                    },
                                                    {
                                                        value: 'incorrect',
                                                        label: 'customerportal.correction_drawings.incorrect',
                                                    },
                                                ],
                                            }}/>
                                        </Grid>
                                        {descriptionIncorrect &&
                                            <FormRow field={{
                                                rowSize: formRowSizes.full,
                                                label: 'customerportal.form.corrections',
                                                multiline: true,
                                                disabled: false,
                                                id: 'description.changeRequests',
                                                rules: {
                                                    required: {
                                                        value: true,
                                                        message: 'customerportal.form.validation.required',
                                                    },
                                                },
                                                rows: 3,
                                                type: formRowTypes.text,
                                            }}/>
                                        }
                                        <Grid item xs={12}>
                                            <Button variant={'contained'}
                                                    color={'primary'}
                                                    sx={{
                                                        width: '100%',
                                                        display: {
                                                            xs: 'none',
                                                            md: 'block',
                                                        },
                                                        marginBottom: 0.625,
                                                    }}
                                                    onClick={handleBack}
                                            >
                                                {t('customerportal.back')}
                                            </Button>
                                            <Button variant={'contained'}
                                                    color={'secondary'}
                                                    sx={{
                                                        width: '100%',
                                                        display: {
                                                            xs: 'none',
                                                            md: 'block',
                                                        },
                                                    }}
                                                    disabled={!isValid}
                                                    onClick={methods.handleSubmit(onSubmit)}
                                            >
                                                {t('customerportal.correction_drawings.description.advance')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </FormProvider>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <CorrectionDrawingMobileStepper activeStep={activeStep}
                                            maxSteps={maxSteps}
                                            handleBack={handleBack}
                                            handleNext={methods.handleSubmit(onSubmit)}
                                            nextDisabled={!isValid}
            />
        </>
    );
}

export default CorrectionDrawingDescription;