import React from 'react';
import PageWrapper from '../utils/PageWrapper';
import OrderList from '../orders/OrderList';
import {QUOTE_TYPE_OPEN, ORDER_VIEW_ACCORDION} from '../../config/orderConfig';
import {Box, Grid, Typography} from '@mui/material';
import ContactPersonList from '../contactPersons/ContactPersonList';
import {useTranslation} from 'react-i18next';
import {groupQuotesSubSites, routeQuotes} from '../../config/routeItems';
import SubpageNavigation from '../ui/SubpageNavigation';

function OrdersQuote() {
    const {t} = useTranslation();

    return (
        <PageWrapper siteTitle={'customerportal.navigation.quotes_open'} backRoute={routeQuotes}>
            <Grid container columnSpacing={2} rowSpacing={4}>
                <Grid item xs={12} xl={9}>
                    <SubpageNavigation items={groupQuotesSubSites}/>
                    <Box>
                        <OrderList orderType={QUOTE_TYPE_OPEN} view={ORDER_VIEW_ACCORDION}/>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                    <Typography variant="h3" sx={{marginBottom: 1}}>
                        {t('customerportal.account.my_contact')}
                    </Typography>
                    <ContactPersonList
                        selection={['supervisor']}
                        bigImg={true}
                        skeletonCount={1}
                    />
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export default OrdersQuote;
