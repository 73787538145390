import {correctionDrawingStatus} from '../config/correctionDrawingStatus';

export function getCorrectionDrawingStatus(str) {
    switch (str) {
        case 'interne Bearbeitung RATHGEBER':
            return correctionDrawingStatus.IN_PROGRESS;
        case 'bereit zur Freigabe':
            return correctionDrawingStatus.EXAMINATION
        default:
            return null
    }
}
