import {createSlice} from '@reduxjs/toolkit';
import {
    getProductDetails,
    getProducts
} from '../actions/products';

const initialState = {
    items: null,
    loading: false,
    count: 0,
};

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getProducts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload;
            })
            .addCase(getProducts.rejected, (state) => {
                state.loading = false;
                state.items = null;
            })
            .addCase(getProductDetails.pending, (state) => {
            })
            .addCase(getProductDetails.fulfilled, (state, action) => {
                if (state.items && action.payload.articleNumber && action.payload.details) {
                    const index = state.items.findIndex((item) => item.articleNumber === action.payload.articleNumber);

                    if(index > -1) {
                        state.items[index].details = action.payload.details;
                    }
                }
            })
            .addCase(getProductDetails.rejected, (state) => {
            });
    },
});

// export const {} = productsSlice.actions;
export default productsSlice.reducer;
