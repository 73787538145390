import React from 'react';
import {Step, StepButton, StepConnector, stepConnectorClasses, Stepper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {styled} from '@mui/material/styles';

const Connector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.grey[300],
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.grey[300],
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.grey[300],
    },
}));

function CorrectionDrawingStepper({activeStep, steps, handleBack, disabled}) {
    const {t} = useTranslation();

    return (
        <Stepper activeStep={activeStep}
                 connector={<Connector />}
                 sx={{
                     marginTop: 1.5,
                     display: {
                         xs: 'none',
                         md: 'flex',
                     }
                 }}
        >
            {steps.map((step, index) => (
                <Step key={index} completed={index < activeStep} disabled={index > activeStep}>
                    <StepButton color="inherit" onClick={disabled ? null : () => handleBack(index)}>
                        <Typography variant={index === activeStep ? 'subtitle2' : 'body2'} component={'span'}>{t(step.label)}</Typography>
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    )
}

export default CorrectionDrawingStepper;