import React from 'react';
import {Dialog as MuiDialog, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';

function Dialog(props) {
    const {open, onClose, title, text, onConfirm, confirmText, cancelText} = props;
    const {t} = useTranslation();

    return (
        <MuiDialog
            open={open}
            onClose={onClose}
        >
            {title &&
                <DialogTitle>
                    {title}
                </DialogTitle>
            }
            {text &&
                <DialogContent>
                    <DialogContentText>
                        {text}
                    </DialogContentText>
                </DialogContent>
            }
            <DialogActions>
                <Grid container spacing={0.5} justifyContent={{sm: 'end'}}>
                    <Grid item xs={12} sm={'auto'}>
                        <Button variant={'contained'}
                                disableElevation={true}
                                color={'primary'}
                                onClick={onClose}>
                            {t(cancelText ? cancelText : 'customerportal.dialog.cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={'auto'}>
                        <Button variant={'contained'}
                                disableElevation={true}
                                color={'secondary'}
                                onClick={onConfirm}>
                            {t(confirmText ? confirmText : 'customerportal.dialog.confirm')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </MuiDialog>
    );
}

export default Dialog;