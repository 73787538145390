import React from 'react';
import {Grid, Skeleton} from '@mui/material';

function SearchFieldSkeleton() {
    return (
        <Grid item xs={12} sx={{
            marginBottom: 3.5,
            marginTop: 0.5,
            display: 'flex'
        }}>
            <Skeleton variant="circular" animation={'wave'} sx={{
                height: 24,
                width: 24,
                marginRight: 1
            }} />
            <Skeleton variant="text" animation={'wave'} sx={{
                width: 50,
                fontSize: 'body1'
            }}/>
        </Grid>
    );
}

export default SearchFieldSkeleton;
