import GaretBoldWoff from '../assets/fonts/GaretBold/font.woff';
import GaretBoldWoff2 from '../assets/fonts/GaretBold/font.woff2';
import GaretRegularWoff from '../assets/fonts/GaretRegular/font.woff';
import GaretRegularWoff2 from '../assets/fonts/GaretRegular/font.woff2';

const fonts = {
    GaretBold: {
        fontFamily: '"Garet-Bold"',
        fontStyle: 'normal',
        fontDisplay: 'swap',
        fontWeight: 400,
        src: `
            url(${GaretBoldWoff}) format('woff'),
            url(${GaretBoldWoff2}) format('woff2')
        `
    },
    GaretRegular: {
        fontFamily: '"Garet-Regular"',
        fontStyle: 'normal',
        fontDisplay: 'swap',
        fontWeight: 400,
        src: `
            url(${GaretRegularWoff}) format('woff'),
            url(${GaretRegularWoff2}) format('woff2')
        `
    }
};

export default fonts;
