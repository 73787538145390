import React, {useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Box, IconButton, Typography} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from 'react-i18next';
import {FormProvider, useFieldArray, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import FormRow from '../form/FormRow';
import {formRowSizes} from '../../config/form/formRowSizes';
import {formRowTypes} from '../../config/form/formRowTypes';
import FormSection from '../form/FormSection';
import GenerateFormField from '../form/GenerateFormField';
import {LoadingButton} from '@mui/lab';
import Alert from '@mui/material/Alert';
import {resetGetOfferDialog} from '../../redux/slices/offerForm';
import {sendGetOfferForm} from '../../redux/actions/offerForm';

function DialogGetOffer({title, item, open, handleClose}) {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const {user, azureClient, contactPersons} = useSelector((state) => state.azure);
    const {isLoading, success, hasError, errorMessage} = useSelector((state) => state.offerForm);
    const methods = useForm({
        defaultValues: {
            debtorNumber: item.debtorNumber,
            companyName: user?.companyName,
            email: user.email,
            phone: user.phone,
            message: '',
            articles: item.articles.filter(a => a.isRealArticle).map(article => ({
                articleNumber: article.articleNumber,
                title: `${article.description}${article.descriptionAddition ? ` - ${article.descriptionAddition}` : ''}`,
                quantity: article.quantity || 0,
            })),
        },
    });
    const {fields, remove} = useFieldArray({
        control: methods.control,
        name: 'articles',
    });

    const removeArticle = (index) => {
        remove(index);
    };

    const onSubmit = (form) => {
        resetFormState();

        dispatch(sendGetOfferForm({
            language: i18n.language,
            client: azureClient,
            customerEmail: user?.email,
            customerName: user?.companyName,
            supervisorEmail: contactPersons.find(p => p.type === 'supervisor')?.email,
            salutation: t(`customerportal.salutation.${user.salutation}`),
            lastname: user?.lastname,
            firstname: user?.firstname,
            form: form,
        }));
    };

    const resetFormState = () => {
        dispatch(resetGetOfferDialog());
    };

    useEffect(() =>  () =>{
        resetFormState();
        // eslint-disable-next-line
    }, []);

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {!success ? (
                    <>
                        {hasError && (
                            <Alert severity="error" sx={{width: '100%'}} onClose={resetFormState}>
                                <Typography mr={1} component={'strong'}>{t('customerportal.general.server_error')}</Typography>
                                {errorMessage && (
                                    <Typography component={'code'} style={{fontFamily: 'monospace'}}>{errorMessage}</Typography>
                                )}
                            </Alert>
                        )}
                        <FormProvider {...methods}>
                            <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                                <FormSection>
                                    <FormRow field={{
                                        rowSize: formRowSizes.half,
                                        label: 'customerportal.order.debtor_number',
                                        defaultValue: item.debtorNumber,
                                        disabled: true,
                                        id: 'debtorNumber',
                                        type: formRowTypes.text,
                                    }}/>
                                    <FormRow field={{
                                        rowSize: formRowSizes.half,
                                        label: 'customerportal.form.company_name',
                                        defaultValue: user.companyName,
                                        disabled: true,
                                        id: 'companyName',
                                        type: formRowTypes.text,
                                    }}/>
                                    <FormRow field={{
                                        rowSize: formRowSizes.half,
                                        label: 'customerportal.form.mail',
                                        defaultValue: user.email,
                                        disabled: true,
                                        id: 'email',
                                        type: formRowTypes.text,
                                    }}/>
                                    <FormRow field={{
                                        rowSize: formRowSizes.half,
                                        label: 'customerportal.form.phone',
                                        defaultValue: user.phone,
                                        disabled: true,
                                        id: 'phone',
                                        type: formRowTypes.text,
                                    }}/>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            {fields.map((article, index) => (
                                                <Grid item
                                                      key={article.id}
                                                      xs={12}
                                                      sx={{
                                                          backgroundColor: 'blue.100',
                                                          my: 0.5,
                                                          p: 1,
                                                          borderRadius: 1,
                                                      }}>
                                                    <FormSection>
                                                        <FormRow field={{
                                                            rowSize: formRowSizes.small,
                                                            label: 'customerportal.order.article_number',
                                                            defaultValue: article.articleNumber,
                                                            disabled: true,
                                                            id: `articles.${index}.articleNumber`,
                                                            type: formRowTypes.text,
                                                        }}/>
                                                        <FormRow field={{
                                                            rowSize: formRowSizes.small,
                                                            label: 'customerportal.order.article_title',
                                                            defaultValue: article.title,
                                                            disabled: true,
                                                            id: `articles.${index}.articleTitle`,
                                                            type: formRowTypes.text,
                                                        }}/>

                                                        <Grid item xs={8} md={2} sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            <GenerateFormField field={{
                                                                rowSize: formRowSizes.xs,
                                                                label: 'customerportal.order.article_quantity',
                                                                defaultValue: article.quantity,
                                                                disabled: false,
                                                                id: `articles.${index}.quantity`,
                                                                rules: {
                                                                    valueAsNumber: true,
                                                                    validate: (value) => value > 0 || 'customerportal.form.validation.quantity_min',
                                                                },
                                                                type: formRowTypes.number,
                                                            }}/>
                                                        </Grid>
                                                        <Grid item xs={4} md={2} sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}>
                                                                <IconButton onClick={() => removeArticle(index)}
                                                                            disabled={fields.length === 1}>
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </Box>
                                                        </Grid>
                                                    </FormSection>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.message',
                                        multiline: true,
                                        disabled: false,
                                        id: 'message',
                                        type: formRowTypes.text,
                                    }}/>
                                    <Grid item xs={12} sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'start',
                                    }}>
                                        <LoadingButton variant={'contained'}
                                                       color={'secondary'}
                                                       loading={isLoading}
                                                       type={'submit'}
                                        >
                                            <Typography>{t('customerportal.form.send_get_offer_form')}</Typography>
                                        </LoadingButton>
                                    </Grid>
                                </FormSection>
                            </form>
                        </FormProvider>
                    </>
                ) : (
                    <Box>
                        <Typography variant={'h1'}
                                    component={'h2'}>
                            {t('customerportal.form.get_offer_success.title')}
                        </Typography>
                        <Typography variant={'body1'}>
                            {t('customerportal.form.get_offer_success.text')}
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            {success && (
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        {t('customerportal.dialog.close')}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}

export default DialogGetOffer;
