import { isSunday, isSaturday } from 'date-fns';

export function maxTotalFileSize(files, maxFileSize, errorMessage, required) {
    if(files.length > 0) {
        let totalFileSize = 0;

        for (let i = 0; i < files.length; i++) {
            totalFileSize += files[i].size;
        }

        return totalFileSize < maxFileSize || errorMessage;
    } else {
        return required ? 'customerportal.form.validation.required' : true;
    }
}

export const dateIsWeekend = (date) => {
    return isSunday(date) || isSaturday(date);
}