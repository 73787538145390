export function detectBrowser() {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
        browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
        browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
        browserName = 'edge';
    } else {
        browserName = 'undetected';
    }

    return browserName;
}

export function getReturnUrl(client) {
    if (process.env.NODE_ENV !== 'development') {
        if (client === 'smart-TEC GmbH & Co. KG') {
            let url = window.location.origin.replace('rathgeber', 'smart-tec');
            url = url.replace(/\.eu|\.pl|\.cz/, ".com");

            return url;
        } else {
            return window.location.origin.replace('smart-tec.com', 'rathgeber.eu');
        }
    } else {
        return window.location.origin;
    }
}