import React, {useEffect, useState} from 'react';
import PageWrapper from '../utils/PageWrapper';
import {useDispatch, useSelector} from 'react-redux';
import {Alert, Button, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import FormSection from '../form/FormSection';
import FormRow from '../form/FormRow';
import {formRowSizes} from '../../config/form/formRowSizes';
import FormFieldsSkeleton from '../skeletons/FormFieldSkeleton';
import {routeAccount} from '../../config/routeItems';
import {formRowTypes} from '../../config/form/formRowTypes';
import {FormProvider, useForm} from 'react-hook-form';
import {getPossibleSalutations, getPossiblePositions, updateUser} from '../../redux/actions/azure';
import Box from '@mui/material/Box';
import ApiErrorSnackbar from '../utils/ApiErrorSnackbar';
import {resetUserForm} from '../../redux/slices/azure';
import Supervisor from '../contactPersons/Supervisor';
import ChangePasswordButton from "../ui/ChangePasswordButton";

function AccountPersonalInformations(props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const methods = useForm({});
    const user = useSelector((state) => state.azure.user);
    const possibleSalutations = useSelector((state) => state.azure.possibleSalutations);
    const {isLoading, success, hasError, errorMessage} = useSelector((state) => state.azure.userFormState);
    const possiblePositions = useSelector((state) => state.azure.possiblePositions);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (!possibleSalutations?.length) {
            dispatch(getPossibleSalutations());
        }
        if (!possiblePositions?.length) {
            dispatch(getPossiblePositions());
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (form) => {
        dispatch(updateUser({
            formData: form,
        }));
    };

    const handleSnackbarClose = () => {
        setShowError(false);
        dispatch(resetUserForm());
    };

    useEffect(() => {
        if (success) {
            setShowError(false);
        }
        if (hasError) {
            setShowError(true);
        }
        // eslint-disable-next-line
    }, [success, hasError]);

    useEffect(() => {
        if (user) {
            methods.reset({
                salutation: user.salutation,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                phone: user.phone,
                leadership: user.leadership,
                position: user.position,
            });
        }

        // eslint-disable-next-line
    }, [user]);

    useEffect(() => () => {
        dispatch(resetUserForm());
        // eslint-disable-next-line
    }, []);

    return (
        <PageWrapper siteTitle={'customerportal.account.personal_information'} backRoute={routeAccount} switchClient={true}>
            <ApiErrorSnackbar open={showError} errorMessage={errorMessage} handleClose={handleSnackbarClose}/>

            <Grid container
                  spacing={2.5}
            >
                <Grid xs={12} lg={8}
                      item
                >

                    {success && (
                        <Alert severity={'success'} sx={{}}>
                            <Typography>{t('customerportal.form.save_success')}</Typography>
                        </Alert>
                    )}
                </Grid>
                <Grid xs={12} lg={8}
                      item
                >
                    {user ?
                        <FormProvider {...methods}>
                            <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                                <FormSection>
                                    {possibleSalutations?.length ? (
                                        <FormRow field={{
                                            rowSize: formRowSizes.small,
                                            label: 'customerportal.form.salutation',
                                            defaultValue: user.salutation,
                                            disabled: isLoading,
                                            id: 'salutation',
                                            type: formRowTypes.select,
                                            rules: {
                                                required: {
                                                    value: true,
                                                    message: 'customerportal.form.validation.required',
                                                },
                                            },
                                            options: possibleSalutations.map((salutation) => ({
                                                value: salutation.Code,
                                                label: `customerportal.salutation.${salutation.Code}`,
                                            })),
                                        }}/>
                                    ) : <></>}

                                    <FormRow field={{
                                        rowSize: formRowSizes.mid,
                                        label: 'customerportal.form.client_number',
                                        defaultValue: user.contactNumber,
                                        disabled: true,
                                        id: 'client-number',
                                        type: formRowTypes.text,
                                    }}/>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.firstname',
                                        defaultValue: user.firstname,
                                        disabled: isLoading,
                                        id: 'firstname',
                                        rules: {
                                            required: {
                                                value: true,
                                                message: 'customerportal.form.validation.required',
                                            },
                                        },
                                        type: formRowTypes.text,
                                    }}/>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.lastname',
                                        defaultValue: user.lastname,
                                        disabled: isLoading,
                                        id: 'lastname',
                                        rules: {
                                            required: {
                                                value: true,
                                                message: 'customerportal.form.validation.required',
                                            },
                                        },
                                        type: formRowTypes.text,
                                    }}/>

                                    {possiblePositions?.length ? (
                                        <FormRow field={{
                                            rowSize: formRowSizes.half,
                                            label: 'customerportal.form.position',
                                            defaultValue: user.position,
                                            disabled: isLoading,
                                            id: 'position',
                                            type: formRowTypes.select,
                                            options: possiblePositions.map((position) => ({
                                                value: position.Code,
                                                label: `customerportal.position.${position.Code}`,
                                            })),
                                        }}/>
                                    ) : <></>}

                                    <FormRow field={{
                                        rowSize: formRowSizes.half,
                                        label: 'customerportal.form.leadership',
                                        defaultValue: user.leadership,
                                        alignCenter: true,
                                        multiline: false,
                                        disabled: isLoading,
                                        id: 'leadership',
                                        type: formRowTypes.checkbox,
                                    }}/>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.phone',
                                        defaultValue: user.phone,
                                        disabled: isLoading,
                                        id: 'phone',
                                        rules: {
                                            required: {
                                                value: true,
                                                message: 'customerportal.form.validation.required',
                                            },
                                        },
                                        type: formRowTypes.text,
                                    }}/>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.mail',
                                        defaultValue: user.email,
                                        disabled: true,
                                        id: 'email',
                                        type: formRowTypes.text,
                                    }}/>

                                </FormSection>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexWrap: {
                                        xs: 'wrap',
                                        md: 'nowrap',
                                    },
                                    mt: 2,
                                    gap: {
                                        xs: 1,
                                        md: 1.5,
                                    },
                                }}>
                                    <ChangePasswordButton />
                                    <Button variant="contained"
                                            type="submit"
                                            disabled={isLoading}
                                            color="secondary">
                                        {t('customerportal.form.save')}
                                    </Button>
                                </Box>
                            </form>
                        </FormProvider> :
                        <FormFieldsSkeleton count={6}/>
                    }
                </Grid>
                <Grid xs={12}
                      lg={4}
                      sx={{
                          position: 'relative',
                      }}
                      item>
                    <Typography variant={'h2'} sx={{
                        position: {
                            lg: 'absolute',
                        },
                        top: {
                            lg: -40,
                        },
                        marginBottom: {
                            xs: 1.5,
                            lg: 0,
                        },
                        lineHeight: 1.75,
                    }}>
                        {t('customerportal.account.my_contact')}
                    </Typography>
                    <Supervisor/>
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export default AccountPersonalInformations;