import {createSlice} from '@reduxjs/toolkit';
import {
    addCompanyContact,
    deactivateCompanyContact,
    getCompanyContacts,
} from '../actions/companyContacts';

const initialState = {
    contacts: null,
    loading: false,
    count: 0,
    companyContactsError: false,
    addContactForm: {
        success: false,
        isLoading: false,
        hasError: false,
        errorMessage: '',
    }
};

const companyContactsSlice = createSlice({
    name: 'companyContacts',
    initialState,
    reducers: {
        resetAddContactDialog: (state, action) => {
            state.addContactForm = initialState.addContactForm;
        },
        resetCompanyContactsError: (state, action) => {
            state.companyContactsError = false;
        },
    },
    extraReducers(builder) {
        builder
        .addCase(getCompanyContacts.pending, (state) => {
            state.loading = true;
        })
        .addCase(getCompanyContacts.fulfilled, (state, action) => {
            state.loading = false;
            state.contacts = action.payload;
        })
        .addCase(getCompanyContacts.rejected, (state) => {
            state.loading = false;
            state.contacts = null;
        })
        .addCase(deactivateCompanyContact.pending, (state) => {
            state.companyContactsError = false;
        })
        .addCase(deactivateCompanyContact.fulfilled, (state, action) => {
            state.companyContactsError = false;
        })
        .addCase(deactivateCompanyContact.rejected, (state) => {
            state.companyContactsError = true;
        })
        .addCase(addCompanyContact.pending, (state) => {
            state.addContactForm.isLoading = true;
        })
        .addCase(addCompanyContact.fulfilled, (state, action) => {
            state.addContactForm.isLoading = false;
            state.addContactForm.success = true;
        })
        .addCase(addCompanyContact.rejected, (state, action) => {
            state.addContactForm.isLoading = false;
            state.addContactForm.success = false;
            state.addContactForm.hasError = true;
            state.addContactForm.errorMessage = action.payload.errorMessage;
        });
    },
});

export const {resetAddContactDialog, resetCompanyContactsError} = companyContactsSlice.actions;
export default companyContactsSlice.reducer;
