import React from 'react';
import {Button, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useMsal} from '@azure/msal-react';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';


function LogoutButton() {
    const {instance} = useMsal();
    const {i18n, t} = useTranslation();

    const handleLogout = () => {
        instance.logoutRedirect({
            extraQueryParameters: {
                lang: i18n.language,
            },
        });
    };

    return (
        <Grid item
              xs={12}
              lg={8}
        >
            <Button variant={'contained'}
                    disableElevation={true}
                    color={'secondary'}
                    startIcon={<LogoutOutlinedIcon/>}
                    onClick={handleLogout}
            >
                <Typography>{t('customerportal.auth.logout')}</Typography>
            </Button>
        </Grid>
    );
}

export default LogoutButton;
