export const getLanguage = (pathname) => {
    return pathname.split('/').filter((val) => val.length > 0)[0];
};

export const getClient = (location, account) => {
    if (account) {
        if (account.idTokenClaims && account.idTokenClaims.extension_Mandant) {
            const activeClient = account.idTokenClaims.extension_Mandant;

            if(/(.*)Rathgeber GmbH & Co. KG/g.test(activeClient)) {
                return {
                    client: 'rathgeber',
                    azureClient: 'rathgeber'
                }
            } else if(/(.*)Rathgeber k.s./g.test(activeClient)) {
                return {
                    client: 'rathgeber-cz',
                    azureClient: 'rathgeber-cz'
                }
            } else if(/(.*)Rathgeber sp. z o.o./g.test(activeClient)) {
                return {
                    client: 'rathgeber-pl',
                    azureClient: 'rathgeber-pl'
                }
            } else if(/(.*)smart-TEC/g.test(activeClient)) {
                return {
                    client: 'smart-tec',
                    azureClient: 'smart-tec'
                }
            }
        } else {
            const splitLocation = location.split('.');
            return splitLocation[splitLocation.length - 2];
        }
    } else {
        const splitLocation = location.split('.');
        return splitLocation[splitLocation.length - 2];
    }
};