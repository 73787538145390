import React from 'react';
import CorrectionDrawingsSkeletonItem from './CorrectionDrawingsSkeletonItem';

function CorrectionDrawingsSkeletonList({count = 0}) {
    if (count > 0) {
        return Array.from(Array(count).keys()).map((key, i) => (
            <CorrectionDrawingsSkeletonItem key={key} bgBlue={i < 5} />
        ))
    }
}

export default CorrectionDrawingsSkeletonList;