import React from "react";
import {List, ListItem, ListItemText, Skeleton} from '@mui/material';

function DialogListSkeleton({count = 0}) {
    if (count > 0) {
        return (
            <List sx={{pt: 0}}>
                {Array.from(Array(count).keys()).map((key) => (

                    <ListItem key={key}>
                        <ListItemText>
                            <Skeleton variant="text" animation={'wave'} sx={{fontSize: 'body1', width: '40%'}}/>
                            <Skeleton variant="text" animation={'wave'} sx={{fontSize: 'body2', width: '60%'}}/>
                        </ListItemText>
                        <Skeleton variant='circular'
                                  animation={'wave'}
                                  sx={{
                                      width: {
                                          xs: 25,
                                      },
                                      height: {
                                          xs: 25,
                                      }
                                  }}
                        />
                    </ListItem>

                ))}
            </List>
        )
    }
}

export default DialogListSkeleton;
