import React from 'react';
import OrderSkeletonItem from './OrderSkeletonItem';

function OrderSkeletonList({count = 0}) {
    if (count > 0) {
        return Array.from(Array(count).keys()).map((key, i) => (
            <OrderSkeletonItem key={key} />
        ))
    }
}

export default OrderSkeletonList;
