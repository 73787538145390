import {createAsyncThunk} from '@reduxjs/toolkit';
import Api from '../../api/Api';

export const sendGetOfferForm = createAsyncThunk('pimcore-custom/send-get-offer-form', async (payload = false, {rejectWithValue}) => {
    try {
        const formData = new FormData();
        formData.append('language', payload.language);
        formData.append('client', payload.client);
        formData.append('customerEmail', payload.customerEmail);
        formData.append('customerName', payload.customerName);
        formData.append('salutation', payload.salutation);
        formData.append('firstname', payload.firstname);
        formData.append('lastname', payload.lastname);
        formData.append('supervisorEmail', !!process.env.REACT_APP_TEST_EMAILS ? 'OH-SMBOX-TEAM-ERP@rathgeber.eu' : payload.supervisorEmail);
        formData.append('formData', JSON.stringify(payload.form));

        await Api.sendGetOffer(formData);
        return true;

    } catch (err) {
        return rejectWithValue({
            errorMessage: err.response.data.detail,
        }, {});
    }
});
