import React, {useEffect} from 'react';
import PageWrapper from "../utils/PageWrapper";
import {useDispatch, useSelector} from "react-redux";
import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import FormSection from "../form/FormSection";
import FormRow from "../form/FormRow";
import {formRowSizes} from "../../config/form/formRowSizes";
import {getCustomerAddresses} from "../../redux/actions/azure";
import {useMsal} from "@azure/msal-react";
import Api from "../../api/Api";
import FormFieldsSkeleton from "../skeletons/FormFieldSkeleton";
import {groupAccountCompanyDataSubSites, routeAccount} from "../../config/routeItems";
import {formRowTypes} from "../../config/form/formRowTypes";
import {FormProvider, useForm} from "react-hook-form";
import SubpageNavigation from "../ui/SubpageNavigation";
import AdditionalAddresses from "../address/AdditionalAddresses";
import Supervisor from "../contactPersons/Supervisor";
import ChangeDataPanel from "../contactPersons/ChangeDataPanel";

function AccountBillingAddress() {
    const {t, i18n} = useTranslation();
    const billingAddress = useSelector((state) => state.azure.customerAddresses.billingAddress);
    const dispatch = useDispatch();
    const methods = useForm({});
    const {instance, accounts} = useMsal();

    useEffect(() => {
        if (!billingAddress) {
            Api.acquireToken(instance, accounts[0]).then(() => {
                dispatch(getCustomerAddresses(i18n.language))
            });
        }
        // eslint-disable-next-line
    }, [billingAddress]);

    return (
        <PageWrapper siteTitle={'customerportal.account.billing_address'} backRoute={routeAccount} switchClient={true}>
            <Grid container
                  spacing={2.5}
            >
                <Grid item xs={12} lg={8}>
                    <SubpageNavigation items={groupAccountCompanyDataSubSites}/>
                    <Typography variant="body1" sx={{marginBottom: 2.5}}>
                        {t('customerportal.company_billing_address.helper_text')}
                    </Typography>

                    {billingAddress ?
                        <FormProvider {...methods}>
                            <form noValidate onSubmit={null}>
                                <FormSection>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.street',
                                        defaultValue: billingAddress.street,
                                        disabled: true,
                                        id: 'street',
                                        type: formRowTypes.text
                                    }}/>

                                    {billingAddress.addressAddition &&
                                        <FormRow field={{
                                            rowSize: formRowSizes.full,
                                            label: 'customerportal.form.addressAddition',
                                            defaultValue: billingAddress.addressAddition,
                                            disabled: true,
                                            id: 'address-addition',
                                            type: formRowTypes.text
                                        }}/>
                                    }

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.zip',
                                        defaultValue: billingAddress.zip,
                                        disabled: true,
                                        id: 'zip',
                                        type: formRowTypes.text
                                    }}/>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.place',
                                        defaultValue: billingAddress.place,
                                        disabled: true,
                                        id: 'place',
                                        type: formRowTypes.text
                                    }}/>

                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.country',
                                        defaultValue: billingAddress.country,
                                        disabled: true,
                                        id: 'country',
                                        type: formRowTypes.text
                                    }}/>

                                </FormSection>

                                {billingAddress?.additionalAddresses && (
                                    <AdditionalAddresses addressType="billing"
                                                         addresses={billingAddress.additionalAddresses}/>
                                )}
                            </form>
                        </FormProvider> :
                        <FormFieldsSkeleton count={5}/>
                    }
                </Grid>
                <Grid xs={12}
                      lg={4}
                      sx={{
                          position: 'relative',
                      }}
                      item
                >
                    <Supervisor/>

                    <ChangeDataPanel />
                </Grid>
            </Grid>
        </PageWrapper>
    )
}

export default AccountBillingAddress;