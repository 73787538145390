import React from 'react';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import {supportedLngs} from "../../i18n";
import {Button, MenuItem, MenuList, Popover} from "@mui/material";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {useNavigate} from "react-router-dom";

function SwitchLanguage() {
    const {i18n, t} = useTranslation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageSwitch = (lng) => {
        window.localStorage.setItem('i18nextLng', lng);
        i18n.changeLanguage(lng, () => {
            handlePopoverClose();
            navigate(`/${lng}/dashboard`);
        });
    };

    const open = Boolean(anchorEl);

    return (
        <Box sx={(theme) => ({
            position: 'absolute',
            zIndex: 1,
            left: {
                xs: 'auto',
                md: theme.spacing(3.5)
            },
            right: {
                xs: theme.spacing(0.5),
                md: 'auto'
            },
            top: {
                xs: theme.spacing(0.75),
                md: theme.spacing(1.5),
            },
        })}>
            <Button onClick={handlePopoverOpen}>
                {t('customerportal.switch_language.label')}:&nbsp;
                <Box component={'span'}
                     sx={{
                         textTransform: 'uppercase',
                     }}>
                    {i18n.language}
                </Box>
                <KeyboardArrowDownRoundedIcon/>
            </Button>
            <Popover open={open}
                     anchorEl={anchorEl}
                     onClose={handlePopoverClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'left',
                     }}
            >
                <MenuList>
                    {supportedLngs.map((lng) => (
                        <MenuItem key={lng} sx={{textTransform: 'uppercase'}} onClick={() => handleLanguageSwitch(lng)}>
                            {lng}
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>
        </Box>
    );
}

export default SwitchLanguage;
