import React from 'react';
import {Alert, Snackbar, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

function ApiErrorSnackbar({open, handleClose, errorMessage}) {

    const {t} = useTranslation();

    return (
        <Snackbar open={open} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
            <Alert severity="error" sx={{width: '100%'}} onClose={handleClose}>
                <Typography mr={1} component={'strong'}>{t('customerportal.general.server_error')}</Typography>
                <Typography component={'code'} style={{fontFamily: 'monospace'}}>{errorMessage}</Typography>
            </Alert>
        </Snackbar>
    );
}

export default ApiErrorSnackbar;
