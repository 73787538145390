import {createAsyncThunk} from '@reduxjs/toolkit';
import Api from '../../api/Api';

export const changeDeliveryDate = createAsyncThunk('pimcore-custom/change-delivery-date', async (payload = false, {rejectWithValue}) => {
    try {
        const formData = new FormData();
        formData.append('language', payload.language);
        formData.append('client', payload.client);
        formData.append('customerEmail', payload.customerEmail);
        formData.append('customerName', payload.customerName);
        formData.append('salutation', payload.salutation);
        formData.append('lastname', payload.lastname);
        formData.append('firstname', payload.firstname);
        formData.append('orderNumber', payload.orderNumber);
        formData.append('orderManagerEmail', !!process.env.REACT_APP_TEST_EMAILS ? 'OH-SMBOX-TEAM-ERP@rathgeber.eu' : payload.orderManagerEmail);
        formData.append('formData', JSON.stringify(payload.form));

        await Api.changeDeliveryDate(formData);
        return true;

    } catch (err) {
        return rejectWithValue({
            errorMessage: err.response.data.detail,
        }, {});
    }
});
