import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    DialogActions,
    Button,
    ListItemText,
    DialogContentText,
    DialogContent,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {DownloadOutlined} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import {getOrderDocuments} from '../../redux/actions/azure';
import {useMsal} from '@azure/msal-react';
import moment from 'moment';
import DialogListSkeleton from '../skeletons/DialogListSkeleton';
import Api from '../../api/Api';

function DialogDocuments({order, open, handleClose, orderType}) {
    const dispatch = useDispatch();
    const documents = useSelector(state => state.azure.orderDocuments.items);
    const orderDocuments = documents?.find(d => d.orderNumber === order.orderNumber)?.documents;
    const {instance, accounts} = useMsal();
    const {t} = useTranslation();
    const [internalLoading, setInternalLoading] = useState(true);

    useEffect(() => {
        if (!orderDocuments || orderDocuments.length < 1) {
            Api.acquireToken(instance, accounts[0]).then((res) => {
                dispatch(getOrderDocuments({orderNumber: order.orderNumber, orderType: orderType}));
                setInternalLoading(false);
            });
        } else {
            setInternalLoading(false);
        }

        // eslint-disable-next-line
    }, []);

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="xs"
            onClose={handleClose}>
            <DialogTitle>
                {t('customerportal.order.documents')}
            </DialogTitle>

            {internalLoading || !orderDocuments ? (
                <DialogListSkeleton count={3}/>
            ) : orderDocuments.length > 0 ? (
                <List sx={{pt: 0}}>

                    {orderDocuments.map(d => (
                        <ListItem key={d.number} autoFocus button onClick={() => {
                            if (window.dataLayer) {
                                window.dataLayer.push({
                                    event: 'pdf-download',
                                    docname: `${t(`customerportal.order.documents.type.${d.type}`)} ${d.number}`
                                });
                            }
                            window.location.assign(d.url);
                        }}>
                            <ListItemText
                                primary={t(`customerportal.order.documents.type.${d.type}`)}
                                secondary={`${moment(d.date).format('DD.MM.YYYY')} | ${d.number}`}
                                sx={{paddingLeft: 0.5}}
                            />
                            <DownloadOutlined color="primary"/>
                        </ListItem>
                    ))}

                </List>
            ) : (
                <DialogContent>
                    <DialogContentText>{t('customerportal.order.documents.no_documents')}</DialogContentText>
                </DialogContent>
            )}

            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    {t('customerportal.dialog.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogDocuments;
