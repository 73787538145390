import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import Api from '../../api/Api';
import {getOrders} from '../../redux/actions/azure';
import {useMsal} from '@azure/msal-react';
import {Grid, Pagination, TextField, Typography} from '@mui/material';
import SearchFieldSkeleton from '../skeletons/SearchFieldSkeleton';
import OrderSkeletonList from '../skeletons/OrderSkeletonList';
import {orderPaginationCount} from '../../config/paginationConfig';
import {SearchOutlined} from '@mui/icons-material';
import OrderItem from './OrderItem';
import {useTranslation} from 'react-i18next';
import OrderTableHead from './OrderTableHead';
import {useDebouncedEffect} from '../../hooks/useDebouncedEffect';
import searchFunc from '../../utils/searchFunc';
import OrderTableHeadSimple from './OrderTableHeadSimple';
import OrderItemSimple from './OrderItemSimple';
import {ORDER_VIEW_SIMPLE, QUOTE_TYPE_CLOSED, QUOTE_TYPE_OPEN} from '../../config/orderConfig';
import OrderSkeletonTableHead from '../skeletons/OrderSkeletonTableHead';


function OrderList({orderType, view}) {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orders = useSelector(state => state.azure.orders[orderType]);
    const {instance, accounts} = useMsal();
    const [internalLoading, setInternalLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [renderedOrders, setRenderedOrders] = useState([]);
    const [searchValueInit, setSearchValueInit] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleSearchInput = (event) => {
        setSearchValue(event.target.value);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useDebouncedEffect(() => {
        if (!searchValueInit) {
            setSearchValueInit(true);

            const urlParams = new URLSearchParams(window.location.search);
            const q = urlParams.get('q');

            if (q) {
                setSearchValue(q.trim());
            }

            return;
        }

        if (searchValue.length > 3 && orders) {
            setPage(1);
            setRenderedOrders(searchFunc(
                orders.items,
                searchValue.trim(),
                [
                    'articlesSearchString',
                    'orderNumberCustomer',
                    'orderNumber',
                ]),
            );
        } else {
            setRenderedOrders(orders.items);
        }
    }, [searchValue, orders], 500);

    useEffect(() => {
        setRenderedOrders(orders.items);

        // eslint-disable-next-line
    }, [orders]);


    useEffect(() => {
        if (!orders.items || orders.items.length < 1) {
            Api.acquireToken(instance, accounts[0]).then((res) => {
                dispatch(getOrders({orderType: orderType}));
                setInternalLoading(false);
            });
        } else {
            setInternalLoading(false);
        }

        // eslint-disable-next-line
    }, []);

    return (
        <Grid container spacing={0.625}>
            {(orders.loading || internalLoading) ? (
                <>
                    <SearchFieldSkeleton/>
                    <OrderSkeletonTableHead/>
                    <OrderSkeletonList count={orderPaginationCount}/>
                </>
            ) : (
                (orders.items && orders.items.length > 0) ? (
                    <Grid item xs={12}>
                        <Grid container spacing={0.75}>
                            <Grid item xs={12} sx={{
                                marginBottom: 3,
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}>
                                <SearchOutlined sx={{
                                    marginRight: 1,
                                    color: 'primary',
                                }}/>
                                <TextField label={t('customerportal.form.search')}
                                           value={searchValue}
                                           variant="standard"
                                           onChange={handleSearchInput}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{display: {xs: 'none', lg: 'block'}}}>
                                {view === ORDER_VIEW_SIMPLE ? (
                                    <OrderTableHeadSimple orderType={orderType}/>
                                ) : (
                                    <OrderTableHead orderType={orderType}/>
                                )}
                            </Grid>

                            {(renderedOrders && renderedOrders.length > 0) && renderedOrders.slice(orderPaginationCount * (page - 1), orderPaginationCount * page).map((order) => (
                                <Grid item key={order.orderNumber} xs={12}>
                                    {view === ORDER_VIEW_SIMPLE ? (
                                        <OrderItemSimple orderItem={order}/>
                                    ) : (
                                        <OrderItem orderItem={order}
                                                   orderType={orderType}
                                                   searchValue={searchValue}
                                                   initialOpen={order.orderNumber === orders.items[0].orderNumber}/>
                                    )}
                                </Grid>
                            ))}

                            {renderedOrders && renderedOrders.length > orderPaginationCount && (
                                <Pagination count={Math.ceil(renderedOrders.length / orderPaginationCount)}
                                            disabled={orders.loading}
                                            sx={{
                                                marginTop: 3,
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                            }}
                                            page={page}
                                            onChange={handlePageChange}
                                            color={'primary'}
                                />
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item sx={{marginY: 1}}>
                        <Typography variant={'body1'}>{t((orderType === QUOTE_TYPE_CLOSED || orderType === QUOTE_TYPE_OPEN) ? 'customerportal.quote.no_items' : 'customerportal.order.no_items')}</Typography>
                    </Grid>
                )
            )}

            {searchValue.length > 0 && renderedOrders < 1 && (
                <Grid item sx={{marginY: 1}}>
                    <Typography variant={'body1'}>{t((orderType === QUOTE_TYPE_CLOSED || orderType === QUOTE_TYPE_OPEN) ? 'customerportal.quote.no_items' : 'customerportal.order.no_items')}</Typography>
                </Grid>
            )}

        </Grid>
    );
}

export default OrderList;
