import {createSlice} from '@reduxjs/toolkit';
import {sendGetOfferForm} from '../actions/offerForm';

const initialState = {
    success: false,
    isLoading: false,
    hasError: false,
    errorMessage: '',
};

const offerFormSlice = createSlice({
    name: 'offerForm',
    initialState,
    reducers: {
        resetGetOfferDialog: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(sendGetOfferForm.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendGetOfferForm.fulfilled, (state, action) => {
                state.isLoading = false;
                state.success = true;
            })
            .addCase(sendGetOfferForm.rejected, (state, action) => {
                state.isLoading = false;
                state.success = false;
                state.hasError = true;
                state.errorMessage = action.payload.errorMessage;
            });
    },
});

export const {resetGetOfferDialog} = offerFormSlice.actions;
export default offerFormSlice.reducer;
