import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useMsal} from '@azure/msal-react';
import Api from '../../api/Api';
import {getCompanyContacts} from '../../redux/actions/companyContacts';
import {useDebouncedEffect} from '../../hooks/useDebouncedEffect';
import searchFunc from '../../utils/searchFunc';
import {Button, Grid, Pagination, Snackbar, TextField, Typography} from '@mui/material';
import SearchFieldSkeleton from '../skeletons/SearchFieldSkeleton';
import {companyContactsPaginationCount} from '../../config/paginationConfig';
import CompanyContactsSkeletonTableHead from '../skeletons/CompanyContactsSkeletonTableHead';
import CompanyContactsSkeletonList from '../skeletons/CompanyContactsSkeletonList';
import {SearchOutlined} from '@mui/icons-material';
import CompanyContactsTableHead from './CompanyContactsTableHead';
import CompanyContactsItem from './CompanyContactsItem';
import AddCompanyContactModal from "./AddCompanyContactModal";
import Alert from "@mui/material/Alert";
import {resetCompanyContactsError} from "../../redux/slices/companyContacts";

function CompanyContactsList() {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {contacts, loading, companyContactsError} = useSelector(state => state.companyContacts);
    const {instance, accounts} = useMsal();
    const [internalLoading, setInternalLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [renderedContacts, setRenderedContacts] = useState([]);
    const [addContactModalOpen, setAddContactModalOpen] = useState(false);
    const [searchValueInit, setSearchValueInit] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleSearchInput = (event) => {
        setSearchValue(event.target.value);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useDebouncedEffect(() => {
        if (!searchValueInit) {
            setSearchValueInit(true);
            return;
        }

        if (searchValue.length > 3) {
            setPage(1);
            setRenderedContacts(searchFunc(
                contacts,
                searchValue.trim(),
                [
                    'firstname',
                    'lastname',
                    'phone',
                    'email',
                ]),
            );
        } else {
            setRenderedContacts(contacts);
        }
    }, [searchValue], 500);

    useEffect(() => {
        if (!contacts || contacts.length < 1) {
            Api.acquireToken(instance, accounts[0]).then((res) => {
                dispatch(getCompanyContacts());
                setInternalLoading(false);
            });
        } else {
            setInternalLoading(false);
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setRenderedContacts(contacts);

        // eslint-disable-next-line
    }, [contacts]);

    return (
        <>
            <Grid container spacing={0.625}>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        {t('customerportal.company_contacts.helper_text')}
                    </Typography>

                    <Button variant={'contained'}
                            sx={{
                                my: 1
                            }}
                            color={'secondary'}
                            onClick={() => setAddContactModalOpen(true)}
                            size={'small'}>
                        {t('customerportal.company_contacts.add_contact')}
                    </Button>
                </Grid>
                {(loading || internalLoading) ? (
                    <>
                        <SearchFieldSkeleton/>
                        <CompanyContactsSkeletonTableHead/>
                        <CompanyContactsSkeletonList count={companyContactsPaginationCount}/>
                    </>
                ) : (
                    <Grid item xs={12}>
                        <Grid container spacing={0.75}>
                            <Grid item xs={12} sx={{
                                marginBottom: 3,
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}>
                                <SearchOutlined sx={{
                                    marginRight: 1,
                                    color: 'primary',
                                }}/>
                                <TextField label={t('customerportal.form.search')}
                                           value={searchValue}
                                           variant="standard"
                                           onChange={handleSearchInput}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{display: {xs: 'none', lg: 'block'}}}>
                                <CompanyContactsTableHead/>
                            </Grid>

                            {(renderedContacts && renderedContacts.length > 0) ? renderedContacts.slice(companyContactsPaginationCount * (page - 1), companyContactsPaginationCount * page).map((contact) => (
                                <Grid item key={contact.contactNumber} xs={12}>
                                    <CompanyContactsItem contact={contact}/>
                                </Grid>
                            )) : <></>}

                            {renderedContacts && renderedContacts.length > companyContactsPaginationCount && (
                                <Pagination count={Math.ceil(renderedContacts.length / companyContactsPaginationCount)}
                                            disabled={loading}
                                            sx={{
                                                marginTop: 3,
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                            }}
                                            page={page}
                                            onChange={handlePageChange}
                                            color={'primary'}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <AddCompanyContactModal title={t('customerportal.company_contacts.add_contact_modal.title')}
                                    open={addContactModalOpen}
                                    handleClose={() => setAddContactModalOpen(false)}
            />
            <Snackbar open={companyContactsError}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                      onClose={() => dispatch(resetCompanyContactsError())}
                      autoHideDuration={6000}>
                <Alert
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {t('customerportal.company_contacts.error')}
                </Alert>
            </Snackbar>
        </>
    );
}

export default CompanyContactsList;