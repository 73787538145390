import React from 'react';
import {Controller} from 'react-hook-form';
import {FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup} from '@mui/material';
import {useTranslation} from 'react-i18next';

const ReactHookFormRadio = ({
                                name,
                                label,
                                control,
                                defaultValue,
                                children,
                                disabled,
                                rules,
                                items,
                                ...props
                            }) => {
    const {t} = useTranslation();

    return (
        <Controller
            rules={rules}
            control={control}
            name={name}
            render={({field, fieldState}) => {
                return (
                    <FormControl component="fieldset"
                                 error={!!fieldState.error}
                    >
                        <FormLabel component="legend">{label}</FormLabel>
                        <RadioGroup value={field.value ? field.value : ''}>
                            {items.map((item) => (
                                <FormControlLabel
                                    key={item.value}
                                    value={item.value}
                                    onChange={field.onChange}
                                    control={<Radio size="small"/>}
                                    label={t(item.label)}
                                />
                            ))}
                            {fieldState.error &&
                                <FormHelperText>{t(fieldState.error.message)}</FormHelperText>
                            }
                        </RadioGroup>
                    </FormControl>
                );
            }}
        />
    );
};

export default ReactHookFormRadio;