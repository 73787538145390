import React from 'react';
import {Button} from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

function SubpageNavigation(props) {
    const location = useLocation();
    const {i18n, t} = useTranslation();

    return (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: {
                xs: 'column',
                lg: 'row',
            },
            pb: 1.4,
            borderBottom: '5px solid',
            borderColor: 'primary.main',
            gap: 0.6,
            mb: 1.4
        }}>
            {props.items.map((item, i) => (
                <Button component={RouterLink}
                        to={`/${i18n.language}${item.url}`}
                        color={location.pathname.includes(`/${i18n.language}${item.url}`) ? 'primary' : 'white'}
                        key={item.key}
                        variant={'contained'}>
                    {t(item.label)}
                </Button>
            ))}
        </Box>
    );
}

SubpageNavigation.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        icon: PropTypes.element.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
};

export default SubpageNavigation;