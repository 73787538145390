import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Badge} from '@mui/material';
import {useEffect} from 'react';
import {getCorrectionDrawingsCount} from '../../redux/actions/azure';
import {useMsal} from '@azure/msal-react';
import Api from '../../api/Api';

function BadgeCountLayoutRelease(props) {
    const correctionDrawings = useSelector((state) => state.azure.correctionDrawings);
    const dispatch = useDispatch();
    const {instance, accounts} = useMsal();

    useEffect(() => {
        Api.acquireToken(instance, accounts[0]).then((res) => {
            dispatch(getCorrectionDrawingsCount());
        });

        // eslint-disable-next-line
    }, []);

    return (
        <Badge badgeContent={correctionDrawings.count ? correctionDrawings.count : 0}
               color="secondary"
               sx={{
                   display: 'block',
                   width: '100%',
               }}
        >
            {props.children}
        </Badge>
    );
}

export default BadgeCountLayoutRelease;