import React from 'react';
import {Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import colors from '../../theme/colors';

function CompanyContactsTableHead() {
    const {t} = useTranslation();

    return (
        <Grid container
              sx={{
                  paddingLeft: 1,
                  paddingRight: {
                      xs: 4,
                      xl: 3.5
                  },
              }}
              spacing={0.5}
              alignItems="flex-end">
            <Grid item lg={2}>
                <Typography variant="body2" color={colors.primary900}>
                    {t('customerportal.form.salutation')}
                </Typography>
            </Grid>
            <Grid item lg={3} xl={2}>
                <Typography variant="body2" color={colors.primary900}>
                    {t('customerportal.form.lastname')}
                </Typography>
            </Grid>
            <Grid item lg={3} xl={2}>
                <Typography variant="body2" color={colors.primary900}>
                    {t('customerportal.form.firstname')}
                </Typography>
            </Grid>
            <Grid item lg={3} xl={2}>
                <Typography variant="body2" color={colors.primary900}>
                    {t('customerportal.form.position')}
                </Typography>
            </Grid>
            <Grid item
                  sx={{
                      display: {
                          xs: 'none',
                          xl: 'flex',
                      },
                  }}
                  xl={1.5}>
            </Grid>
            <Grid item
                  sx={{
                      display: {
                          xs: 'none',
                          xl: 'flex',
                      },
                      justifyContent: 'end',
                      textAlign: 'right'
                  }}
                  xs={12}
                  xl={2.5}>
                <Typography variant="body2" color={colors.primary900}>
                    {t('customerportal.company_contacts.headers.activate')}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default CompanyContactsTableHead;
