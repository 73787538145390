import React, {useState, useEffect, useRef} from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import {ClickAwayListener, Grow, Link, MenuItem, MenuList, Paper, Popper} from '@mui/material';
import {groupSideNavMore, routeChooseClient} from '../../../config/routeItems';
import {useMsal} from '@azure/msal-react';
import {useSelector} from 'react-redux';

function NavGroup({sidebarOpen}) {
    const {i18n, t} = useTranslation();
    const {accounts} = useMsal();

    const navigate = useNavigate();
    const client = useSelector((state) => state.azure.client);
    const moreRef = useRef(null);

    const [moreOpen, setMoreOpen] = useState(false);
    const prevOpen = useRef(sidebarOpen);

    const handleNavigationClick = (newValue) => {
        navigate(newValue, {replace: true});
    };

    const handleMoreClick = (e, newValue) => {
        handleClose(e);
        handleNavigationClick(newValue);
    };


    const handleClose = (event) => {
        if (moreRef.current && moreRef.current.contains(event.target)) {
            return;
        }

        setMoreOpen(false);
    };

    const handleMoreToggle = () => {
        setMoreOpen((prevOpen) => !prevOpen);
    };

    useEffect(() => {
        if (prevOpen.current === true && sidebarOpen === false) {
            moreRef.current.focus();
        }

        prevOpen.current = sidebarOpen;
    }, [sidebarOpen]);

    window.addEventListener('resize', () => moreOpen ? setMoreOpen(false) : null);

    return (
        <>
            <ListItem disablePadding
                      aria-controls={sidebarOpen ? 'composition-menu' : undefined}
                      aria-expanded={sidebarOpen ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleMoreToggle}
                      ref={moreRef}
                      sx={{
                          display: 'block',
                          color: 'grey.200',
                          '& svg': {
                              color: 'grey.200',
                          },
                          '&:hover': {
                              backgroundColor: 'primary.dark',
                          },
                      }}
            >
                <ListItemButton sx={{
                    minHeight: 48,
                    justifyContent: sidebarOpen ? 'initial' : 'center',
                    px: 1.25,
                }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: sidebarOpen ? 1.875 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <MoreHorizOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t('customerportal.navigation.more')}
                                  sx={{opacity: sidebarOpen ? 1 : 0}}
                    />
                </ListItemButton>
            </ListItem>
            <Popper open={moreOpen}
                    anchorEl={moreRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    sx={{
                        zIndex: 9999,
                    }}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper sx={{
                            mx: 1.25,
                            width: '100%',
                        }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={sidebarOpen}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                >
                                    {(accounts[0] && accounts[0].idTokenClaims.extension_Mandanten?.split('|').length > 1) &&
                                    <MenuItem key={routeChooseClient.key}
                                              onClick={(e) => handleMoreClick(e, `/${i18n.language}${routeChooseClient.url}`)}
                                    >
                                        {t(routeChooseClient.label)}
                                    </MenuItem>
                                    }
                                    {groupSideNavMore.map((groupItem) => (
                                        <MenuItem key={groupItem.key}
                                                  sx={{
                                                      padding: 0,
                                                  }}
                                        >
                                            <Link href={t(`${groupItem.url}.${client}`)}
                                                  target={'_blank'}
                                                  underline={'none'}
                                                  sx={{
                                                      color: 'common.black',
                                                      px: 1,
                                                      py: 0.375,
                                                      width: '100%',
                                                      height: '100%',
                                                  }}
                                            >
                                                {t(groupItem.label)}
                                            </Link>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

export default NavGroup;
