import React, {useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {NO_DATA_STRING} from '../../config/orderConfig';
import {breakpoints} from '../../theme/baseTheme';
import {ExpandMore} from '@mui/icons-material';
import {useDispatch} from 'react-redux';
import {deactivateCompanyContact} from '../../redux/actions/companyContacts';

function CompanyContactsItem({contact, initialOpen}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(window.innerWidth > breakpoints.values.md ? initialOpen : false);

    const onAccordionChange = (ev, expanded) => {
        setIsOpen(expanded);
    };

    const handleDeactivation = (event) => {
        event.stopPropagation();

        dispatch(deactivateCompanyContact({
            id: contact.contactNumber,
            customerportal: !contact.customerportal
        }))
    }

    return (
        <>
            <Accordion
                defaultExpanded={window.innerWidth > breakpoints.values.md ? initialOpen : false}
                onChange={onAccordionChange}
                sx={{
                    backgroundColor: isOpen ? 'blue.100' : 'common.white',
                    transition: 'background .3s ease',
                    '.MuiAccordionSummary-expandIconWrapper': {
                        paddingX: 0.5,
                    },
                }}>
                <AccordionSummary
                    sx={{paddingY: 0.5, paddingX: 1}}
                    expandIcon={<ExpandMore/>}>

                    <Grid container alignItems="center" spacing={0.5}>
                        <Grid item xs={12} lg={2}>
                            <Typography variant="body1" sx={{lineHeight: '1.25'}}>
                                <>
                                    <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                        {t('customerportal.form.salutation')}:&ensp;
                                    </Box>
                                    <Box component="span" sx={{hyphens: 'auto'}}>
                                        {t(`customerportal.salutation.${contact.salutation}`)}
                                    </Box>
                                </>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} xl={2}>
                            <Typography variant="body1">
                                <>
                                    <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                        {t('customerportal.form.lastname')}:&ensp;
                                    </Box>
                                    {contact.lastname}
                                </>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} xl={2}>
                            <Typography variant="body1">
                                <>
                                    <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                        {t('customerportal.form.firstname')}:&ensp;
                                    </Box>
                                    {contact.firstname}
                                </>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} xl={2}>
                            <Typography variant="body1">
                                <>
                                    <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                        {t('customerportal.form.position')}:&ensp;
                                    </Box>
                                    {contact.position ? t(`customerportal.position.${contact.position}`) : NO_DATA_STRING}
                                </>
                            </Typography>
                        </Grid>
                        <Grid item
                              sx={{
                                  display: {
                                      xs: 'none',
                                      xl: 'flex',
                                  },
                              }}
                              xs={12}
                              xl={4}>
                            <Grid container spacing={1}>
                                <Grid item
                                      xs={12}
                                      xl={4}
                                      sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                      }}>
                                    {/*<IconButton color="primary">*/}
                                    {/*    <DeleteOutlineOutlined/>*/}
                                    {/*</IconButton>*/}
                                    {/*<IconButton color="primary">*/}
                                    {/*    <EditOutlined/>*/}
                                    {/*</IconButton>*/}
                                </Grid>
                                <Grid item xs={12} xl={8} sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    paddingLeft: 0.5,
                                }}>
                                    <Button variant={'contained'}
                                            onClick={handleDeactivation}
                                            color={contact.customerportal ? 'primary' : 'secondary'}
                                            size={'small'}>
                                        {t(contact.customerportal ? 'customerportal.company_contacts.deactivate' : 'customerportal.company_contacts.activate')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails sx={{paddingTop: 0, paddingBottom: 1, paddingX: 1}}>


                    <Box component="div">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={0.5}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" sx={{lineHeight: '1.25'}}>
                                            <>
                                                <Box component="span">
                                                    {t('customerportal.form.phone')}:&ensp;
                                                </Box>
                                                <Box component="span" sx={{hyphens: 'auto'}}>
                                                    {contact.phone || NO_DATA_STRING}
                                                </Box>
                                            </>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" sx={{lineHeight: '1.25'}}>
                                            <>
                                                <Box component="span">
                                                    {t('customerportal.form.email')}:&ensp;
                                                </Box>
                                                <Box component="span" sx={{hyphens: 'auto'}}>
                                                    {contact.email ? (
                                                        <a href={`mailto:${contact.email}`} title={contact.email}>
                                                            {contact.email}
                                                        </a>
                                                    ) : NO_DATA_STRING}
                                                </Box>
                                            </>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{display: {xs: 'block', xl: 'none'}}}>
                                <Grid container spacing={1}>
                                    <Grid item
                                          xs={6}
                                          sx={{
                                              display: 'flex',
                                              justifyContent: 'flex-start',
                                              alignItems: 'center',
                                          }}>
                                        {/*<IconButton color="primary">*/}
                                        {/*    <DeleteOutlineOutlined/>*/}
                                        {/*</IconButton>*/}
                                        {/*<IconButton color="primary">*/}
                                        {/*    <EditOutlined/>*/}
                                        {/*</IconButton>*/}
                                    </Grid>
                                    <Grid item xs={6} sx={{
                                        display: 'flex',
                                        paddingLeft: 0.5,
                                    }}>
                                        <Button variant={'contained'}
                                                color={contact.customerportal ? 'primary' : 'secondary'}
                                                onClick={handleDeactivation}
                                                size={'small'}>
                                            {t(contact.customerportal ? 'customerportal.company_contacts.deactivate' : 'customerportal.company_contacts.activate')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                </AccordionDetails>

            </Accordion>
        </>
    )
        ;
}

export default CompanyContactsItem;