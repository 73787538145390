import React, {useEffect} from 'react';
import AppBody from './layout/AppBody';
import AppSidebar from './layout/AppSidebar';
import AppBottomNav from './layout/AppBottomNav';
import Box from '@mui/material/Box';
import {useLocation} from 'react-router-dom';
import {getClient, getLanguage} from '../utils/routing';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {setClient} from '../redux/slices/azure';
import {getUser} from '../redux/actions/azure';
import {Helmet} from 'react-helmet';
import Api from '../api/Api';
import {useMsal} from '@azure/msal-react';
import {supportedLngs} from "../i18n";

function App({updateClient}) {
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const {instance, accounts} = useMsal();
    const client = useSelector((state) => state.azure.client);
    const activeLanguage = i18n.language;
    const urlLanguage = getLanguage(location.pathname);

    useEffect(() => {
        dispatch(setClient(getClient(window.location.host, accounts[0])));

        if (supportedLngs.includes(window.localStorage.getItem('i18nextLng'))) {
            i18n.changeLanguage(window.localStorage.getItem('i18nextLng'));
        } else if (activeLanguage !== urlLanguage) {
            i18n.changeLanguage(urlLanguage);
        }

        Api.acquireToken(instance, accounts[0]).then(() => {
            dispatch(getUser(i18n.language));
        });
        // eslint-disable-next-line
    }, []);
    
    useEffect(() =>  {
        updateClient(client);
        // eslint-disable-next-line
    }, [client]);

    return (
        <>
            <Helmet htmlAttributes={{
                lang: i18n.language,
            }}>
                <title>{t('customerportal.pwa_site_title')} {t(`customerportal.clients.${client}`)}</title>
            </Helmet>
            <Box sx={{
                display: 'flex',
                overflowY: 'auto',
                height: '100%',
            }}>
                <AppSidebar/>
                <AppBody/>
            </Box>
            <AppBottomNav/>
        </>
    );
}

export default App;
