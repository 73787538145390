import {Box, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Button} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {ExpandMore, LocalShippingOutlined, FilePresentOutlined} from '@mui/icons-material';
import OrderItemDetails from './OrderItemDetails';
import {NO_DATA_STRING, ORDER_TYPE_OPEN, QUOTE_TYPE_OPEN, QUOTE_TYPE_CLOSED, ORDER_TYPE_CLOSE} from '../../config/orderConfig';
import DialogDocuments from './DialogDocuments';
import DialogDelivery from './DialogDelivery';
import {breakpoints} from '../../theme/baseTheme';
import {format, isAfter} from 'date-fns';

function OrderItem({orderItem, initialOpen, orderType, searchValue}) {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(window.innerWidth > breakpoints.values.md ? initialOpen : false);
    const [firstTimeOpen, setFirstTimeOpen] = useState(initialOpen);
    const [dialogDocumentsOpen, setDialogDocumentsOpen] = useState(false);
    const [dialogDeliveryOpen, setDialogDeliveryOpen] = useState(false);

    const handleClickDelivery = (ev) => {
        ev.stopPropagation();
        setDialogDeliveryOpen(true);
    };

    const handleClickDocuments = (ev) => {
        ev.stopPropagation();
        setDialogDocumentsOpen(true);
    };

    const handleDialogDeliveryClose = () => {
        setDialogDeliveryOpen(false);
    };

    const handleDialogDocumentsClose = () => {
        setDialogDocumentsOpen(false);
    };

    const onAccordionChange = (ev, expanded) => {
        setIsOpen(expanded);
        if (expanded) {
            setFirstTimeOpen(true);
        }
    };

    return (
        <>
            <Accordion
                defaultExpanded={window.innerWidth > breakpoints.values.md ? initialOpen : false}
                onChange={onAccordionChange}
                sx={{
                    backgroundColor: isOpen ? 'blue.100' : 'common.white',
                    transition: 'background .3s ease',
                    '.MuiAccordionSummary-expandIconWrapper': {alignSelf: 'start', marginTop: 1},
                }}>
                <AccordionSummary
                    sx={{paddingY: 0.5, paddingX: 1}}
                    expandIcon={<ExpandMore/>}>

                    <Grid container>

                        <Grid item xs={12} sm={7} lg={12}>

                            <Grid container alignItems="center" columnSpacing={2}>
                                <Grid item xs={12} lg={2.5} xl={2}>
                                    <Typography variant="subtitle1" sx={{lineHeight: '1.25'}}>
                                        <>
                                            <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                                {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_title' : 'customerportal.order.order_title')}:&ensp;
                                            </Box>
                                            <Box component="span" sx={{textTransform: 'uppercase', hyphens: 'auto'}}>
                                                {orderItem.orderNumberCustomer ?? `${t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_title_fallback' : 'customerportal.order.order_title_fallback')}${orderItem.orderNumber}`}
                                            </Box>
                                        </>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} lg={1.5}>
                                    <Typography variant="body2">
                                        <>
                                            <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                                {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_date' : 'customerportal.order.order_date')}:&ensp;
                                            </Box>
                                            {orderItem.orderDate ? moment(orderItem.orderDate).format('DD.MM.YYYY') : NO_DATA_STRING}
                                        </>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} lg={1.5}>
                                    <Typography variant="body2">
                                        <>
                                            <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                                {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_number' : 'customerportal.order.order_number')}:&ensp;
                                            </Box>
                                            {orderItem.orderNumber ?? NO_DATA_STRING}
                                        </>
                                    </Typography>
                                </Grid>

                                {(orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) && (
                                    <Grid item xs={12} sx={{display: {lg: 'none'}}}>
                                        <Typography variant="body2" sx={{
                                            display: 'flex',
                                        }}>
                                            <>
                                                <Box sx={{display: {xs: 'inline', lg: 'none'}}} component="span">
                                                    {t('customerportal.quote.valid_till')}:&ensp;
                                                </Box>
                                                {(orderItem.quoteValidTill && isAfter(new Date(orderItem.quoteValidTill), new Date())) ?
                                                    format(new Date(orderItem.quoteValidTill), 'dd.MM.y')
                                                    : t('customerportal.quote.quote_expired')}
                                            </>
                                        </Typography>
                                    </Grid>
                                )}

                                {orderType === ORDER_TYPE_OPEN &&
                                    <Grid item xs={12} lg={2} xl={1.5}>
                                        <Typography variant="body2" sx={{display: {xs: 'inline', lg: 'none'}}}
                                                    component="span">
                                            {t('customerportal.order.order_total')}:&ensp;
                                        </Typography>
                                        <Typography variant="body2"
                                                    sx={{display: {xs: 'inline', lg: 'block'}, textAlign: 'right'}}>
                                            {orderItem.orderTotal ? `${orderItem.orderTotal} ${orderItem.currencyCode}` : NO_DATA_STRING}
                                        </Typography>
                                    </Grid>
                                }

                                <Grid item xl={2.5} sx={{display: {xs: 'none', xl: 'block'}}}>
                                    <></>
                                </Grid>

                                {(orderType === ORDER_TYPE_OPEN || orderType === ORDER_TYPE_CLOSE)?
                                    <Grid item lg={2} xl={1.5} sx={{marginLeft: 'auto', display: {xs: 'none', lg: 'block'}}}>
                                        <Button onClick={handleClickDelivery} startIcon={<LocalShippingOutlined/>}>
                                            {t('customerportal.order.track_delivery.short')}
                                        </Button>
                                    </Grid> :
                                    <Grid item lg={1.5} sx={{display: {xs: 'none', lg: 'block'}}}>
                                        <></>
                                    </Grid>
                                }

                                <Grid item lg={2} xl={1.5} sx={{display: {xs: 'none', lg: 'block'}}}>
                                    {(orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? (
                                        <Typography variant="body2"
                                                    sx={{
                                                        display: {
                                                            xs: 'inline',
                                                            lg: 'block',
                                                        },
                                                    }}>
                                            {(orderItem.quoteValidTill && isAfter(new Date(orderItem.quoteValidTill), new Date())) ?
                                                format(new Date(orderItem.quoteValidTill), 'dd.MM.y')
                                                : <Box component={'span'}
                                                       sx={{
                                                           p: 0.25,
                                                           mt: -0.25,
                                                           display: 'inline-block',
                                                           backgroundColor: 'grey.250',
                                                       }}>
                                                    {t('customerportal.quote.quote_expired')}
                                                </Box>
                                            }
                                        </Typography>
                                    ) : (
                                        <Button onClick={handleClickDocuments} startIcon={<FilePresentOutlined/>}>
                                            {t('customerportal.order.show_documents.short')}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item xs={12} sm={5} sx={{display: {xs: 'none', sm: 'block', lg: 'none'}}}>
                            {(orderType === ORDER_TYPE_OPEN || orderType === ORDER_TYPE_CLOSE) &&
                                <Button onClick={handleClickDelivery} startIcon={<LocalShippingOutlined/>}>
                                    {t('customerportal.order.track_delivery')}
                                </Button>
                            }
                            {!(orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) && (
                                <Button onClick={handleClickDocuments} startIcon={<FilePresentOutlined/>}>
                                    {t('customerportal.order.show_documents')}
                                </Button>
                            )}
                        </Grid>

                    </Grid>

                </AccordionSummary>

                <AccordionDetails sx={{paddingTop: 0, paddingBottom: 1, paddingX: 1}}>

                    {firstTimeOpen && (
                        <>
                            <Box component="div" sx={{display: {xs: 'block', sm: 'none'}}}>
                                {(orderType === ORDER_TYPE_OPEN || orderType === ORDER_TYPE_CLOSE) &&
                                    <Button onClick={handleClickDelivery} startIcon={<LocalShippingOutlined/>}
                                            sx={{marginRight: 0.5}}>
                                        {t('customerportal.order.track_delivery')}
                                    </Button>
                                }
                                {!(orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) && (
                                    <Button onClick={handleClickDocuments} startIcon={<FilePresentOutlined/>}>
                                        {t('customerportal.order.show_documents')}
                                    </Button>
                                )}
                            </Box>

                            <OrderItemDetails orderItem={orderItem}
                                              searchValue={searchValue}
                                              orderType={orderType}/>

                        </>
                    )}

                </AccordionDetails>

            </Accordion>

            {dialogDocumentsOpen && (
                <DialogDocuments order={orderItem} open={dialogDocumentsOpen} orderType={orderType}
                                 handleClose={handleDialogDocumentsClose}/>
            )}

            {dialogDeliveryOpen && (
                <DialogDelivery order={orderItem} open={dialogDeliveryOpen} orderType={orderType}
                                handleClose={handleDialogDeliveryClose}/>
            )}

        </>
    );
}

export default OrderItem;
