import {Button, Grid, Link, Paper, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export default function ChangeDataPanel() {
    const {t} = useTranslation();
    const contactPersons = useSelector((state) => state.azure.contactPersons);

    return (
        <Paper elevation={1} sx={{
            paddingY: 1,
            paddingX: 1.25,
            marginTop: 1,
        }}>
            <Grid container spacing={1.25}>
                <Grid item>
                    <InfoOutlinedIcon sx={{
                        width: 40,
                        height: 40,
                    }}/>
                </Grid>
                <Grid item sx={{
                    flex: 1,
                    paddingBottom: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Typography variant="body1">
                        {t('customerportal.contact_persons.change_company_data.text')}
                    </Typography>
                </Grid>
            </Grid>
            {contactPersons.find(p => p.type === 'orderManager')?.phone && (
                <Button variant={'contained'}
                        sx={{
                            width: '100%',
                            marginBottom: 0.5,
                        }}
                        component={Link}
                        href={`tel:${contactPersons.find(p => p.type === 'orderManager')?.phone}`}
                        color={'secondary'}>
                    {t('customerportal.contact_persons.change_company_data.phone')}
                </Button>
            )}
            <Button variant={'contained'}
                    sx={{
                        width: '100%',
                    }}
                    component={Link}
                    href={`mailto:${contactPersons.find(p => p.type === 'orderManager')?.email}`}
                    color={'secondary'}>
                {t('customerportal.contact_persons.change_company_data')}
            </Button>
        </Paper>
    )
}