import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    DialogActions,
    Button,
    ListItemText,
    DialogContentText,
    DialogContent,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {DownloadOutlined} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import {getOrderDeliveries} from '../../redux/actions/azure';
import {useMsal} from '@azure/msal-react';
import moment from 'moment';
import DialogListSkeleton from '../skeletons/DialogListSkeleton';
import Api from '../../api/Api';

function DialogDelivery({order, open, handleClose}) {
    const orderNumber = order.orderNumber;
    // order number for testing: 6109612273

    const dispatch = useDispatch();
    const deliveries = useSelector(state => state.azure.orderDeliveries.items);
    const user = useSelector(state => state.azure.user);
    const orderDeliveries = deliveries?.find(d => String(d.orderNumber) === String(orderNumber))?.deliveries;

    const {instance, accounts} = useMsal();
    const {t} = useTranslation();
    const [internalLoading, setInternalLoading] = useState(true);

    useEffect(() => {
        if (!orderDeliveries || orderDeliveries.length < 1) {
            Api.acquireToken(instance, accounts[0]).then((res) => {
                dispatch(getOrderDeliveries({orderNumber: orderNumber}));
                setInternalLoading(false);
            });
        } else {
            setInternalLoading(false);
        }

        // eslint-disable-next-line
    }, []);

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="xs"
            onClose={handleClose}>
            <DialogTitle>
                {t('customerportal.order.delivery')}
            </DialogTitle>

            {internalLoading || !orderDeliveries ? (
                <DialogListSkeleton count={1}/>
            ) : orderDeliveries.length > 0 ? (
                <List sx={{pt: 0}}>

                    {orderDeliveries.map(d => (
                        <ListItem key={d.number} autoFocus button onClick={() => {
                            if (window.dataLayer) {
                                window.dataLayer.push({
                                    event: 'bestellungverfolgen',
                                    auftragsnummer: orderNumber,
                                    artikel: d.number,
                                    login: user.companyName,
                                    'e-mail': user.email,
                                    kundennummer: user.companyNumber
                                });
                            }
                            window.open(d.url);
                        }}>
                            <ListItemText
                                primary={moment(d.date).format('DD.MM.YYYY')}
                                secondary={d.number}
                                sx={{paddingLeft: 0.5}}
                            />
                            <DownloadOutlined color="primary"/>
                        </ListItem>
                    ))}

                </List>
            ) : (
                <DialogContent>
                    <DialogContentText>{t('customerportal.order.delivery.no_deliveries')}</DialogContentText>
                </DialogContent>
            )}

            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    {t('customerportal.dialog.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogDelivery;
