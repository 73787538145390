import {createTheme} from "@mui/material/styles";
import {themeName, breakpoints, spacing, palette, typography, shape, props} from './baseTheme'
import overrideTheme from './overrideTheme';

const generateTheme = (client) => {
    const paletteItems = palette(client);
    let theme = createTheme({themeName, breakpoints, spacing, palette: paletteItems, typography, shape, props});
    theme = overrideTheme(theme);

    return theme;
}

export default generateTheme;
