import React from "react";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import ReactHookFormDatePicker from './rhf/ReactHookFormDatePicker';

function FormDatePicker({field}) {
    const {t} = useTranslation();

    return (
        <ReactHookFormDatePicker
            id={field.id}
            label={t(field.label)}
            labelExtended={t(field.labelExtended)}
            defaultValue={field.defaultValue}
            variant="filled"
            name={field.id}
            multiple={field.multiple}
            disabled={field.disabled}
            rules={field.rules}
            datePickerRules={field.datePickerRules}
        />
    )
}

FormDatePicker.propTypes = {
    field: PropTypes.shape({
        rowSize: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        defaultValue: PropTypes.any,
        multiline: PropTypes.bool,
        disabled: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired
}

export default FormDatePicker;