import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getPwaMessage, getPwaAdBanner} from '../../redux/actions/data';
import {Box, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Message from '../utils/Message';
import AdBanner from '../dashboard/AdBanner';
import PageWrapper from '../utils/PageWrapper';
import IconLinkList from '../ui/IconLinkList';
import {groupDashboardSubSites} from '../../config/routeItems';
import ContactPersonList from '../contactPersons/ContactPersonList';

function Dashboard() {
    const {i18n, t} = useTranslation();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.data);
    const client = useSelector((state) => state.azure.client);
    const user = useSelector((state) => state.azure.user);

    useEffect(() => {
        if (client) {
            dispatch(getPwaMessage({
                language: i18n.language,
                client: client,
            }));

            dispatch(getPwaAdBanner({
                language: i18n.language,
                client: client,
            }));
        }
        // eslint-disable-next-line
    }, [i18n.language, client]);

    return (
        <PageWrapper siteTitle={'customerportal.dashboard.site_title'}
                     desktopSiteTitleDisabled={true}
                     languageSwitch={true}
                     switchClient={true}>
            <Grid container
                  columnSpacing={5}
                  rowSpacing={2.5}
            >
                <Grid item xs={12} lg={8}>
                    <Message message={data.pwaMessage.message}/>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Typography variant={'h1'}
                                sx={{
                                    marginBottom: 1.5,
                                }}
                                component={'h2'}>
                        {t('customerportal.dashboard.welcome_text')}
                        {i18n.language !== 'cs' &&
                            <>
                                {' '}
                                <Typography variant={'h1'} component={'span'} sx={{whiteSpace: 'nowrap'}}>
                                    {user && user.salutation && t(`customerportal.salutation.${user.salutation}`)}{' '}
                                    {user && user.firstname}{' '}
                                    {user && user.lastname}
                                </Typography>
                            </>
                        }
                    </Typography>
                    <Box sx={{
                        marginBottom: 3,
                    }}>
                        <Typography sx={{
                            marginBottom: 2,
                        }} dangerouslySetInnerHTML={
                            {__html: t(`customerportal.dashboard${client && `.${client}`}.welcome_description`, {interpolation: {escapeValue: false}})}
                        }/>
                        {user ? (
                            <Typography variant={'h2'}
                                        sx={{
                                            marginBottom: 2.5,
                                        }}>
                                {user.companyName}{user.companyNumber ? `, ${t('customerportal.form.debtor_number')}: ${user.companyNumber}` : ''}
                            </Typography>
                        ) : <></>}
                        <IconLinkList items={groupDashboardSubSites}/>
                    </Box>
                    <AdBanner banner={data.pwaAdBanner.banner}/>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Typography variant={'h2'} sx={{
                        marginBottom: 1.5,
                        lineHeight: 1.75
                    }}>
                        {t('customerportal.account.my_contact')}
                    </Typography>
                    <ContactPersonList skeletonCount={2}
                                       showHeadlines={true}
                                       selection={[
                                           'orderManager',
                                           'supervisor',
                                       ]}
                    />
                </Grid>
            </Grid>
        </PageWrapper>
    );
}

export default Dashboard;
