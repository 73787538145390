import {createAsyncThunk} from '@reduxjs/toolkit';
import Api from '../../api/Api';

export const getProducts = createAsyncThunk('products/get-products', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getProducts();

        if (res.value && res.value.length > 0) {
            const items = [];

            for (const item of res.value) {
                const productItem = {
                    articleNumber: item.Item_No,
                    customerNumber: item.Customer_No,
                    referenceNumber: item.Reference_No,
                    vendorNumber: item.Vendor_No,
                    notation1: item.Description,
                    notation2: item.Description_2,
                    entryNumber: item.Entry_No,
                    alreadyProduced: item.Already_Produced,
                    blocked: item.Blocked,
                    loPath: item.lo_path,
                    quantity: item.Quantity
                }

                items.push(productItem);
            }

            return items;
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const getProductDetails = createAsyncThunk('products/get-product-details', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getProductDetails(payload.articleNumber);

        if (res.value) {
            const productDetail = {
                contentTable: res.value,
            };

            if (payload.loPath) {
                const correctionDrawing = await Api.getCorrectionDrawingsPDF(payload.loPath);

                if (correctionDrawing) {
                    productDetail.file = correctionDrawing;
                }
            }

            return {
                details: productDetail,
                articleNumber: payload.articleNumber,
            };
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});