import React, {useEffect, useState} from 'react';
import CorrectionDrawingMobileStepper from './CorrectionDrawingMobileStepper';
import {Grid, Typography, Box, Button} from '@mui/material';
import FormRadio from '../form/FormRadio';
import {useForm, FormProvider} from 'react-hook-form';
import FormRow from '../form/FormRow';
import {formRowSizes} from '../../config/form/formRowSizes';
import {formRowTypes} from '../../config/form/formRowTypes';
import {useTranslation} from 'react-i18next';
import FormFileUpload from '../form/FormFileUpload';
import {useSelector} from 'react-redux';
import {maxTotalFileSize} from '../../utils/formValidation';
import PdfThumbnail from '../utils/PdfThumbnail';

function CorrectionDrawingLayout({handleNext, handleBack, activeStep, maxSteps, formData}) {
    const methods = useForm({
        defaultValues: formData,
    });
    const [isValid, setIsValid] = useState(false);
    const {t} = useTranslation();
    const values = methods.watch();
    const correctionDrawing = useSelector((state) => state.azure.correctionDrawingDetail.item);
    const layoutIncorrect = methods.watch('layout.correct') === 'incorrect';

    function onSubmit(data) {
        let tempData = null;

        if (data.layout.correct === 'correct') {
            tempData = {
                layout: {
                    correct: data.layout.correct,
                },
            };
        } else {
            tempData = {
                layout: {
                    correct: data.layout.correct,
                    files: data.layout.files,
                    changeRequests: data.layout.changeRequests,
                },
            };
        }

        handleNext(tempData['layout'], 'layout');
    }

    useEffect(() => {
        if (values.layout) {
            if (values.layout.correct === 'correct') {
                setIsValid(true);
            } else {
                if (values.layout.changeRequests) {
                    setIsValid(true);
                } else {
                    setIsValid(false);
                }
            }
        } else {
            setIsValid(false);
        }
    }, [values]);

    return (
        <>
            <Grid container
                  spacing={{
                      xs: 2,
                      lg: 1.25,
                  }}
            >
                <Grid item xs={12} lg={8}>
                    {(correctionDrawing.file && correctionDrawing.loPath) &&
                        <PdfThumbnail file={correctionDrawing.file} fileUrl={correctionDrawing.loPath}/>
                    }
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Box sx={{
                        position: 'sticky',
                        top: 20,
                    }}>
                        <Typography variant={'body2'}>
                            {t('customerportal.correction_drawings.layout.form_description')}
                        </Typography>
                        <Box sx={{
                            marginTop: 2,
                        }}>
                            <FormProvider {...methods}>
                                <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormRadio field={{
                                                label: '',
                                                disabled: false,
                                                id: 'layout.correct',
                                                rules: {
                                                    required: {
                                                        value: true,
                                                        message: 'customerportal.form.validation.required',
                                                    },
                                                },
                                                items: [
                                                    {
                                                        value: 'correct',
                                                        label: 'customerportal.correction_drawings.correct',
                                                    },
                                                    {
                                                        value: 'incorrect',
                                                        label: 'customerportal.correction_drawings.incorrect',
                                                    },
                                                ],
                                            }}/>
                                        </Grid>
                                        {layoutIncorrect &&
                                            <>
                                                <FormRow field={{
                                                    rowSize: formRowSizes.full,
                                                    label: 'customerportal.form.corrections',
                                                    multiline: true,
                                                    disabled: false,
                                                    id: 'layout.changeRequests',
                                                    rules: {
                                                        required: {
                                                            value: true,
                                                            message: 'customerportal.form.validation.required',
                                                        },
                                                    },
                                                    rows: 3,
                                                    type: formRowTypes.text,
                                                }}/>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant={'body2'}>{t('customerportal.form.corrections_text')}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant={'body1'}
                                                                sx={{
                                                                    marginBottom: 0.75,
                                                                }}
                                                    >
                                                        {t('customerportal.form.upload_documents')}
                                                    </Typography>
                                                    <FormFileUpload field={{
                                                        rowSize: formRowSizes.full,
                                                        label: 'customerportal.form.files',
                                                        labelExtended: 'customerportal.form.files_extended',
                                                        defaultValue: '',
                                                        disabled: false,
                                                        multiple: true,
                                                        id: 'layout.files',
                                                        rules: {
                                                            validate: v => maxTotalFileSize(v, 10485760, 'customerportal.form.validation.max_file_size_exceeded', false),
                                                        },
                                                        type: formRowTypes.fileUpload,
                                                    }}/>
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={12}>
                                            <Button variant={'contained'}
                                                    color={'secondary'}
                                                    sx={{
                                                        width: '100%',
                                                        display: {
                                                            xs: 'none',
                                                            md: 'block',
                                                        },
                                                    }}
                                                    disabled={!isValid}
                                                    onClick={methods.handleSubmit(onSubmit)}
                                            >
                                                {t('customerportal.correction_drawings.layout.advance')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </FormProvider>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <CorrectionDrawingMobileStepper activeStep={activeStep}
                                            maxSteps={maxSteps}
                                            handleBack={handleBack}
                                            handleNext={methods.handleSubmit(onSubmit)}
                                            nextDisabled={!isValid}
            />
        </>
    );
}

export default CorrectionDrawingLayout;