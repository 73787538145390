import React from 'react';
import {Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import colors from '../../theme/colors';
import {ORDER_TYPE_CLOSE, QUOTE_TYPE_CLOSED, QUOTE_TYPE_OPEN} from '../../config/orderConfig';

function OrderTableHeadSimple({orderType}) {
    const {t} = useTranslation();

    return (
        <Grid container sx={{paddingX: 1}}>
            <Grid item sx={{flexGrow: 1}}>

                <Grid container columnSpacing={2} alignItems="flex-end">

                    <Grid item lg={2.5} xl={2}>
                        <Typography variant="body2" color={colors.primary900}>
                            {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_title' : 'customerportal.order.order_title')}
                        </Typography>
                    </Grid>

                    <Grid item lg={2.5} xl={2}>
                        <Typography variant="body2" color={colors.primary900}>
                            {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_date' : 'customerportal.order.order_date')}
                        </Typography>
                    </Grid>

                    <Grid item lg={orderType === ORDER_TYPE_CLOSE ? 3 : 4} xl={orderType === ORDER_TYPE_CLOSE ? 5 : 3}>
                        <Typography variant="body2" color={colors.primary900}>
                            {t((orderType === QUOTE_TYPE_OPEN || orderType === QUOTE_TYPE_CLOSED) ? 'customerportal.quote.quote_number' : 'customerportal.order.order_number')}
                        </Typography>
                    </Grid>

                    {orderType === ORDER_TYPE_CLOSE ?
                        <Grid item lg={2} xl={1.5}>
                            <Typography variant="body2" color={colors.primary900}>
                                {t('customerportal.order.track_delivery.legend')}
                            </Typography>
                        </Grid> :
                        <Grid item xl={3.5} sx={{display: {xs: 'none', xl: 'block'}}}><></>
                        </Grid>
                    }

                    <Grid item lg={2} xl={orderType === ORDER_TYPE_CLOSE ? 1.5 : 1.5} sx={{alignSelf: 'end', textAlign: 'right'}}>
                        <Typography variant="body2" color={colors.primary900}>
                            {t('customerportal.order.show_documents.legend')}
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    );
}

export default OrderTableHeadSimple;
