import {createAsyncThunk} from '@reduxjs/toolkit';
import Api from '../../api/Api';
import {getCountry} from '../../service/country.service';
import {getCorrectionDrawingStatus} from '../../utils/getCorrectionDrawingStatus';
import {
    ORDER_DEFAULT_CURRENCY,
    ORDER_TYPE_CLOSE,
    ORDER_TYPE_OPEN,
    QUOTE_TYPE_CLOSED,
    QUOTE_TYPE_OPEN,
} from '../../config/orderConfig';
import moment from 'moment';

export const getContactPersons = createAsyncThunk('azure/get-contact-persons', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getAllContactPersons();
        const value = res.value[0];

        if (value) {
            let contactPersons = [];

            if (value.orderManagerName) {
                contactPersons.push({
                    name: value.orderManagerName,
                    email: value.orderManagerEMail,
                    phone: value.orderManagerTelefon,
                    role: value.orderManagerJobDescription,
                    type: 'orderManager',
                });
            }

            if (value.betreuerName) {
                contactPersons.push({
                    name: value.betreuerName,
                    email: value.betreuerEMail,
                    phone: value.betreuerTelefon,
                    role: value.betreuerJobDescription,
                    type: 'supervisor',
                });
            }

            if (value.vertreterName) {
                contactPersons.push({
                    name: value.vertreterName,
                    email: value.vertreterEMail,
                    phone: value.vertreterTelefon,
                    type: 'representative',
                });
            }

            try {
                for (const person of contactPersons) {
                    const i = contactPersons.indexOf(person);
                    const res = await Api.getTeamMemberImage(person.email);
                    const tempPerson = {...person};

                    if (res.data) {
                        if (res.data.image && res.data.image.path) {
                            tempPerson.image = res.data.image.path;
                        }
                        if (res.data.link && res.data.link.path) {
                            tempPerson.onlineConsulting = res.data.link.path;
                        }
                    }

                    contactPersons[i] = tempPerson;

                    if (i === contactPersons.length - 1) {
                        return {
                            contactPersons: contactPersons,
                        };
                    }
                }
            } catch (err) {
                return rejectWithValue({
                    error: err,
                }, err);
            }
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const getUser = createAsyncThunk('azure/get-user', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getUser(payload);
        res['anrede'] = res['anrede'] === 'Herr' ? 'm' : res['anrede'] === 'Frau' ? 'f' : res['anrede'];

        const user = {
            salutation: res.anrede === 'Herr' ? 'm' : res.anrede === 'Frau' ? 'f' : res.anrede,
            contactNumber: res.kontaktnummer,
            companyNumber: res.unternehmensNummer,
            companyName: `${res.unternehmensName} ${res.unternehmensName2}`,
            firstname: res.vorname,
            lastname: res.nachname,
            street: res.strasse,
            addressAddition: res.adresszusatz,
            countryCode: res.laendercode,
            zip: res.postleitzahl,
            place: res.ort,
            mobile: res.telefonMobil,
            phone: res.telefonFestnetz,
            email: res.emailadresse,
            website: res.internetseite,
            languageCode: res.sprachcode,
            position: res.position,
            leadership: res.positionLeitung,
        };

        if (res) {
            return {
                user: user,
            };
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const getCustomerAddresses = createAsyncThunk('azure/get-customer-addresses', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getCustomerAddresses(payload);
        let addresses = null;

        if (res.value && res.value[0]) {
            addresses = {};

            addresses.deliveryAddress = {
                street: res.value[0].lieferungAnAdresse,
                zip: res.value[0].lieferungAnPLZ,
                place: res.value[0].lieferungAnOrt,
                country: res.value[0].rechnungAnLaendercode ? getCountry(res.value[0].lieferungAnLaendercode, payload) : null,
                additionalAddresses: res.value[0].kp_api_adressenlieferadressen ? res.value[0].kp_api_adressenlieferadressen.map(item => ({
                    addressName: item.name,
                    street: item.adresse,
                    addressAddition: item.adresse2,
                    zip: item.postleitzahl,
                    place: item.stadt,
                    country: item.laendercode ? getCountry(item.laendercode, payload) : null,
                })) : [],
            };

            addresses.billingAddress = {
                street: res.value[0].rechnungAnAdresse,
                addressAddition: res.value[0].rechnungAnAdresse2,
                zip: res.value[0].rechnungAnPLZ,
                place: res.value[0].rechnungAnOrt,
                country: res.value[0].rechnungAnLaendercode ? getCountry(res.value[0].rechnungAnLaendercode, payload) : null,
                additionalAddresses: res.value[0].kp_api_adressenrechnungsadressen ? res.value[0].kp_api_adressenrechnungsadressen.map(item => ({
                    addressName: item.name,
                    street: item.adresse,
                    addressAddition: item.adresse2,
                    zip: item.postleitzahl,
                    place: item.stadt,
                    country: item.laendercode ? getCountry(item.laendercode, payload) : null,
                })) : [],
            };
        }

        if (addresses) {
            return {
                customerAddresses: addresses,
            };
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const getRecipientEmails = createAsyncThunk('azure/get-recipient-emails', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getRecipientEmails(payload);

        let emails = null;

        if (res.value && res.value.length > 0) {
            emails = {};
            const advancePaymentEmail = res.value.filter((mail) => mail.belegart === 'VK-VRECHNUNG')[0];
            const billingEmail = res.value.filter((mail) => mail.belegart === 'VK-RECHNUNG')[0];
            const offerEmail = res.value.filter((mail) => mail.belegart === 'VK-ANGEBOT')[0];
            const orderConfirmationEmail = res.value.filter((mail) => mail.belegart === 'VK-AUFTRAG')[0];
            const creditEmail = res.value.filter((mail) => mail.belegart === 'VK-GUTSCHRIFT')[0];
            const deliveryConfirmationEmail = res.value.filter((mail) => mail.belegart === 'VK-LT')[0];
            const orderDeliveryConfirmationEmail = res.value.filter((mail) => mail.belegart === 'AB-LT')[0];

            if (advancePaymentEmail) {
                emails.advancePaymentEmail = {
                    email: advancePaymentEmail.eMailAdresse,
                    label: advancePaymentEmail.belegart,
                };
            }

            if (billingEmail) {
                emails.billingEmail = {
                    email: billingEmail.eMailAdresse,
                    label: billingEmail.belegart,
                };
            }

            if (offerEmail) {
                emails.offerEmail = {
                    email: offerEmail.eMailAdresse,
                    label: offerEmail.belegart,
                };
            }

            if (orderConfirmationEmail) {
                emails.orderConfirmationEmail = {
                    email: orderConfirmationEmail.eMailAdresse,
                    label: orderConfirmationEmail.belegart,
                };
            }

            if (creditEmail) {
                emails.creditEmail = {
                    email: creditEmail.eMailAdresse,
                    label: creditEmail.belegart,
                };
            }

            if (deliveryConfirmationEmail) {
                emails.deliveryConfirmationEmail = {
                    email: deliveryConfirmationEmail.eMailAdresse,
                    label: deliveryConfirmationEmail.belegart,
                };
            }

            if (orderDeliveryConfirmationEmail) {
                emails.orderDeliveryConfirmationEmail = {
                    email: orderDeliveryConfirmationEmail.eMailAdresse,
                    label: orderDeliveryConfirmationEmail.belegart,
                };
            }

            if (Object.keys(emails).length < 1) {
                emails = null;
            }
        }

        if (emails) {
            return {
                recipientEmails: emails,
            };
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const changeClient = createAsyncThunk('azure/change-client', async (payload = false, {rejectWithValue}) => {
    try {
        await Api.changeClient(payload.client);

        try {
            await payload.instance.logout({
                postLogoutRedirectUri: payload.postLogoutRedirectUri,
            });
        } catch (err) {
            return rejectWithValue({
                error: err,
            }, err);
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const getCorrectionDrawingsCount = createAsyncThunk('azure/get-correction-drawings-count', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getCorrectionDrawingsCount(payload);

        if (res['@odata.count']) {
            return res['@odata.count'];
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const getCorrectionDrawings = createAsyncThunk('azure/get-correction-drawings', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getCorrectionDrawings(payload);

        if (res.value && res.value.length > 0) {
            const items = [];

            res.value.forEach((item) => {
                items.push({
                    entryNumber: item.Entry_No,
                    itemNumber: item.artikelnummer,
                    debtorNumber: item.debitorennummer,
                    originTable: item.herkunfstTabelle,
                    originDocumentNumber: item.herkunftsBelegnummer,
                    processType: item.prozessart,
                    processStatus: getCorrectionDrawingStatus(item.process_status),
                    processCreationDate: item.prozessAnlagedatum,
                    process: item.vorgang,
                    contactNumber: item.kontaktnummer,
                    internalDoneDate: item.erledigtInternAm,
                    externalDoneDate: item.erledigtExternAm,
                    languageCode: item.sprachcode,
                    externalDueDate: item.faelligkeitesdatumExtern,
                    loPath: item.lo_path,
                    client: item.mandant,
                    clientOrderNumber: item.kundenBestellnummer,
                    clientItemNumber: item.kundenArtikelnummer,
                    clientItemDescription: item.kundenArtikelbezeichnung,
                    clientDrawingNumber: item.kundenZeichnungsnummer,
                    clientDate: item.kundenInfoDatum,
                });
            });

            return items;
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const getCorrectionDrawingDetail = createAsyncThunk('azure/get-correction-drawings-detail', async (payload = false, {rejectWithValue}) => {
    try {
        const item = await Api.getCorrectionDrawingsQuery(payload);

        if (item.value && item.value.length > 0) {
            try {
                const res = await Api.getCorrectionDrawingsDetail(payload, item.value[0].sprachcode ? item.value[0].sprachcode : '');

                if (res.value && res.value.length > 0) {
                    const correctionDrawing = {
                        entryNumber: item.value[0].Entry_No,
                        itemNumber: item.value[0].artikelnummer,
                        debtorNumber: item.value[0].debitorennummer,
                        originTable: item.value[0].herkunfstTabelle,
                        originDocumentNumber: item.value[0].herkunftsBelegnummer,
                        processType: item.value[0].prozessart,
                        processStatus: getCorrectionDrawingStatus(item.value[0].process_status),
                        processCreationDate: item.value[0].prozessAnlagedatum,
                        process: item.value[0].vorgang,
                        contactNumber: item.value[0].kontaktnummer,
                        internalDoneDate: item.value[0].erledigtInternAm,
                        externalDoneDate: item.value[0].erledigtExternAm,
                        languageCode: item.value[0].sprachcode,
                        externalDueDate: item.value[0].faelligkeitesdatumExtern,
                        loPath: item.value[0].lo_path,
                        client: item.value[0].mandant,
                        clientOrderNumber: item.value[0].kundenBestellnummer,
                        clientItemNumber: item.value[0].kundenArtikelnummer,
                        clientItemDescription: item.value[0].kundenArtikelbezeichnung,
                        clientDrawingNumber: item.value[0].kundenZeichnungsnummer,
                        clientDate: item.value[0].kundenInfoDatum,
                        contentTable: res.value,
                    };

                    try {
                        if (correctionDrawing.loPath) {
                            correctionDrawing.file = await Api.getCorrectionDrawingsPDF(correctionDrawing.loPath);

                            return correctionDrawing;
                        } else {
                            return correctionDrawing;
                        }
                    } catch (err) {
                        return rejectWithValue({
                            error: 'Error message',
                        }, {});
                    }
                } else {
                    return rejectWithValue({
                        error: 'Error message',
                    }, {});
                }
            } catch (err) {
                return rejectWithValue({
                    error: err,
                }, err);
            }
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const sendCorrectionDrawingDetail = createAsyncThunk('azure/send-correction-drawing-detail', async (payload = false, {rejectWithValue}) => {
    try {
        const orderManagerEmail = payload.orderManagerEmail ?? null;

        if (!orderManagerEmail) {
            return rejectWithValue({
                errorMessage: 'no orderManager email',
            });
        }

        const formData = new FormData();
        formData.append('client', payload.client);
        formData.append('language', payload.language);
        formData.append('customerNumber', payload.customerNumber);
        formData.append('customerName', payload.customerName);
        formData.append('orderNumber', payload.orderNumber);
        formData.append('itemNumber', payload.itemNumber);
        formData.append('salutation', payload.salutation);
        formData.append('firstname', payload.firstname);
        formData.append('lastname', payload.lastname);
        formData.append('orderManagerEmail', !!process.env.REACT_APP_TEST_EMAILS ? 'OH-SMBOX-TEAM-ERP@rathgeber.eu' : orderManagerEmail);
        formData.append('customerEmail', payload.customerEmail);
        formData.append('formData', JSON.stringify(payload.formData));

        if (payload.formData.layout.files) {
            Array.from(payload.formData.layout.files).forEach((file, i) => {
                if (file instanceof Blob) {
                    formData.append(`file_${i}`, file);
                }
            });
        }

        await Api.sendCorrectionDrawingDetail(formData);

    } catch (err) {
        return rejectWithValue({
            errorMessage: err.response.data.message,
        }, {});
    }
});

export const getOrders = createAsyncThunk('azure/get-orders', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getOrders(payload.orderType);

        if (res.value && res.value.length > 0) {

            let articlesPropertyKey = '';

            switch (payload.orderType) {
                // case QUOTE_TYPE_CLOSED:
                // articlesPropertyKey = 'kp_api_auftraege_abgeschlossenkp_api_auftrag_abgesch_zeilen';
                // break;
                case QUOTE_TYPE_CLOSED:
                case QUOTE_TYPE_OPEN:
                    articlesPropertyKey = 'kp_api_angebote_offenkp_api_angebot_offen_zeilen';
                    break;
                case ORDER_TYPE_CLOSE:
                    articlesPropertyKey = 'kp_api_auftraege_abgeschlossenkp_api_auftrag_abgesch_zeilen';
                    break;
                case ORDER_TYPE_OPEN:
                default:
                    articlesPropertyKey = 'kp_api_auftraege_offenkp_api_auftrag_offen_zeilen';
                    break;
            }

            const articlesStatusMap = {
                'Auftrag erfasst': 'entered',
                'Produktion in Planung': 'production_planning',
                'versendet': 'shipped',
                'Vorauszahlung erwartet': 'prepayment_expected',
            };

            const items = [];

            let resultItems = [...res.value];

            resultItems.forEach((item) => {
                items.push({
                    orderNumber: item.belegnummer,
                    debtorNumber: item.debitorennummer,
                    orderNumberCustomer: item.externeBelegnummer || null,
                    orderTotal: item.order_Sum?.toFixed(2) || null,
                    currencyCode: item.waehrungscode && item.waehrungscode !== '' ? item.waehrungscode : ORDER_DEFAULT_CURRENCY,
                    orderDate: item.belegDatum || null,
                    validTill: item.faelligkeitsdatum ? item.faelligkeitsdatum : null,
                    quoteValidTill: item.angebotGueltigBis ? item.angebotGueltigBis : null,
                    deliveryAddress: {
                        company: item.lieferungAnUnternehmensName || null,
                        companyAddition: item.lieferungAnUnternehmensName2 || null,
                        address: item.lieferungAnAdresse || null,
                        addressAddition: item.lieferungAnAdresse2 || null,
                        zip: item.lieferungAnPLZ || null,
                        city: item.lieferungAnOrt || null,
                        country: item.lieferungAnLaendercode || null,
                        contact: item.lieferungAnKontakt || null,
                    },
                    billingAddress: {
                        company: item.rechnungAnUnternehmensName || null,
                        companyAddition: item.rechnungAnUnternehmensName2 || null,
                        address: item.rechnungAnAdresse || null,
                        addressAddition: item.rechnungAnAdresse2 || null,
                        zip: item.rechnungAnPLZ || null,
                        city: item.rechnungAnOrt || null,
                        country: item.rechnungAnLaendercode || null,
                        contact: item.rechnungAnKontakt || null,
                    },
                    articlesSearchString: item[articlesPropertyKey].map(item => {
                        return `${item.beschreibung} ${item.beschreibung2} ${item.ihreArtikelnummer} ${item.artikelnummer}`;
                    }).join(' '),
                    articles: item[articlesPropertyKey].map(item => ({
                        description: item.beschreibung || null,
                        descriptionAddition: item.beschreibung2 || null,
                        articleNumber: item.artikelnummer || null,
                        articleNumberCustomer: item.ihreArtikelnummer || null,
                        quantity: item.menge || null,
                        price: item.vkPreis || null,
                        status: articlesStatusMap[item.line_status] || null,
                        isRealArticle: item.art === 'Artikel',
                        deliveryDate: item.geplantesLieferdatum && item.geplantesLieferdatum !== '' && item.geplantesLieferdatum !== '0001-01-01' ? item.geplantesLieferdatum : null,
                    })),

                });
            });

            items.sort((a, b) => {
                return moment(a.orderDate).isAfter(moment(b.orderDate)) ? -1 : 1;
            });

            return items;
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const getOrderDocuments = createAsyncThunk('azure/get-order-documents', async (payload = false, {rejectWithValue}) => {
    try {
        const res = (payload.orderType === QUOTE_TYPE_OPEN || payload.orderType === QUOTE_TYPE_CLOSED) ? await Api.getQuotesDocuments(payload.orderNumber) : await Api.getOrderDocuments(payload.orderNumber);

        if (res) {

            let documents = [];

            if (res.assignmentConfirmations) {
                documents = documents.concat(
                    res.assignmentConfirmations.map(r => ({
                        number: r.assignmentNumber || null,
                        date: r.assignmentDate || null,
                        url: r.url ? `${Api.endpoints.getFileContent}&DocumentURL=${r.url}` : null,
                        type: 'assignment_confirmation',
                    })));
            }

            if (res.deliveryNotes) {
                documents = documents.concat(res.deliveryNotes.map(r => ({
                    number: r.deliveryNoteNumber || null,
                    date: r.deliveryNoteDate || null,
                    url: r.url ? `${Api.endpoints.getFileContent}&DocumentURL=${r.url}` : null,
                    type: 'delivery_note',
                })));
            }

            if (res.invoices) {
                documents = documents.concat(res.invoices.map(r => ({
                    number: r.invoiceNumber || null,
                    date: r.invoiceDate || null,
                    url: r.url ? `${Api.endpoints.getFileContent}&DocumentURL=${r.url}` : null,
                    type: 'invoice',
                })));
            }

            if (res.quotes) {
                documents = documents.concat(res.quotes.map(r => ({
                    number: r.quotenNumber || null,
                    date: r.quotationDate || null,
                    url: r.url ? `${Api.endpoints.getFileContent}&DocumentURL=${r.url}` : null,
                    type: 'quote',
                })));
            }

            return {
                orderNumber: payload.orderNumber,
                documents: documents,
            };
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});

export const getOrderDeliveries = createAsyncThunk('azure/get-order-deliveries', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getOrderDeliveries(payload.orderNumber);

        if (res) {

            const deliveries = [];

            res?.value.forEach(day => {
                day.kp_api_versandtrackingkp_api_versandtrackingdetails.forEach(parcel => {
                    deliveries.push({
                        url: parcel.trackinglink,
                        date: day.versanddatum,
                        number: parcel.trackingnummer,
                    });
                });
            });

            return {
                orderNumber: payload.orderNumber,
                deliveries: deliveries,
            };
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});


export const getPossibleSalutations = createAsyncThunk('azure/get-possible-salutations', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getPossibleSalutations();

        if (res) {
            return {
                salutations: res.value,
            };
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});


export const getPossiblePositions = createAsyncThunk('azure/get-possible-positions', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getPossiblePositions();

        if (res) {
            return {
                positions: res.value,
            };
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});


export const getCompanyData = createAsyncThunk('azure/get-company-data', async (payload = false, {rejectWithValue, dispatch}) => {
    try {
        const res = await Api.getCompanyData();
        await dispatch(getCustomerAddresses(payload));

        if (res) {
            return {
                debtorNumber: res.value[0].debitorenNummer,
                deliveryNumber: res.value[0].lieferantenNummer,
                ustId: res.value[0].ustId,
                companyName: res.value[0].unternehmensName,
                street: res.value[0].strasse,
                country: res.value[0].laendercode ? getCountry(res.value[0].laendercode, payload) : null,
                zip: res.value[0].postleitzahl,
                city: res.value[0].ort,
                website: res.value[0].internetseite,
                phone: res.value[0].telefonFestnetz,
                paymentTerms: res.value[0].zahlungsziel,
                contactPerson: {
                    id: res.value[0].hauptansprechpartner,
                    name: res.value[0].hauptansprechpartnerName,
                    email: res.value[0].hauptansprechpartnerEmail,
                },
                deliveryData: {
                    standardDeliveryWay: res.value[0].zustellertransportart,
                    standardDeliveryCondition: res.value[0].lieferbedingung,
                }
            };
        } else {
            return rejectWithValue({
                error: 'Error message',
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: err,
        }, err);
    }
});


export const updateUser = createAsyncThunk('azure/update-user', async (payload = false, {rejectWithValue, dispatch}) => {
    try {
        const formData = {
            anrede: payload.formData.salutation,
            emailadresse: payload.formData.email,
            nachname: payload.formData.lastname,
            position: payload.formData.position || '',
            positionLeitung: payload.formData.leadership,
            telefonFestnetz: payload.formData.phone,
            vorname: payload.formData.firstname,
        };

        const res = await Api.updateUser(formData);

        if (!res.error) {
            await dispatch(getUser());

            return {
                positions: res.value,
            };
        } else {
            return rejectWithValue({
                error: res.error.message,
            }, {});
        }
    } catch (err) {
        return rejectWithValue({
            error: 'Server Error',
        }, err);
    }
});
