import React from "react";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import ReactHookFormFileUpload from "./rhf/ReactHookFormFileUpload";

function FormFileUpload({field}) {
    const {t} = useTranslation();

    return (
        <ReactHookFormFileUpload
            id={field.id}
            label={t(field.label)}
            labelExtended={t(field.labelExtended)}
            defaultValue={field.defaultValue}
            variant="filled"
            name={field.id}
            multiple={field.multiple}
            disabled={field.disabled}
            rules={field.rules}
        />
    )
}

FormFileUpload.propTypes = {
    field: PropTypes.shape({
        rowSize: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        labelExtended: PropTypes.string.isRequired,
        defaultValue: PropTypes.string,
        multiline: PropTypes.bool,
        disabled: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired
}

export default FormFileUpload;