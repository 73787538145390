import React from "react";
import {Grid} from "@mui/material";

function FormSection(props) {
    return (
        <Grid container spacing={1.25}>
            {props.children}
        </Grid>
    )
}

export default FormSection;