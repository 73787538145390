import React from 'react';
import {Alert, Grid, Typography, AlertTitle} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useForm, FormProvider} from 'react-hook-form';
import FormRow from '../form/FormRow';
import {formRowSizes} from '../../config/form/formRowSizes';
import {formRowTypes} from '../../config/form/formRowTypes';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from 'react-redux';
import {LoadingButton} from '@mui/lab';
import {sendCorrectionDrawingDetail} from '../../redux/actions/azure';
import moment from 'moment';

function CorrectionDrawingRelease({handleNext, handleBack, activeStep, maxSteps, formData}) {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const correctionDrawingSending = useSelector(state => state.azure.correctionDrawingDetail.sending);
    const correctionDrawing = useSelector(state => state.azure.correctionDrawingDetail.item);
    const user = useSelector(state => state.azure.user);
    const azureClient = useSelector(state => state.azure.azureClient);
    const contactPersons = useSelector(state => state.azure.contactPersons);
    const processCreationDateString = correctionDrawing.processCreationDate ? moment(correctionDrawing.processCreationDate).format('DD.MM.YYYY') : null;
    const methods = useForm({
        defaultValues: formData,
    });

    function onSubmit(data) {
        data.firstname = user.firstname;
        data.lastname = user.lastname;
        data.salutation = t(`customerportal.salutation.${user.salutation}`);
        data.company = user.companyName;

        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'layoutfreigabe',
                formulartyp: 'layoutfreigabe',
                login: user.companyName,
                'e-mail': user.email,
                freigabe: (formData.layout.correct === 'correct' && formData.description.correct === 'correct'),
                kundennummer: user.companyNumber
            });
        }

        dispatch(sendCorrectionDrawingDetail({
            language: i18n.language,
            client: azureClient,
            customerNumber: correctionDrawing.debtorNumber,
            customerName: user.companyName,
            customerEmail: user.email,
            orderNumber: correctionDrawing.originDocumentNumber,
            itemNumber: correctionDrawing.itemNumber,
            orderManagerEmail: contactPersons.find(p => p.type === 'orderManager')?.email,
            formData: data,
            salutation: t(`customerportal.salutation.${user.salutation}`),
            firstname: user.firstname,
            lastname: user.lastname,
        }));
    }

    return (
        <Grid container>
            <Grid item xs={12} md={10}>
                <Box sx={{
                    marginBottom: 2,
                }}>
                    <Typography variant={'body1'}>{t('customerportal.correction_drawings.release.description')}</Typography>
                    <Typography
                        variant={'subtitle1'}>{t(`customerportal.correction_drawings.release.${formData.layout.correct === 'correct' && formData.description.correct === 'correct' ? 'correct' : 'incorrect'}`)}</Typography>
                </Box>
                <FormProvider {...methods}>
                    <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            {(formData.layout.correct === 'correct' && formData.description.correct === 'correct') &&
                                <>
                                    <Grid item xs={12}>
                                        <Grid container spacing={0.25}>
                                            <FormRow field={{
                                                rowSize: formRowSizes.mid,
                                                label: 'customerportal.correction_drawings.release.layout_correct',
                                                multiline: true,
                                                disabled: false,
                                                id: 'release.layout_correct',
                                                rules: {
                                                    required: {
                                                        value: true,
                                                        message: 'customerportal.form.validation.checkbox.required',
                                                    },
                                                },
                                                type: formRowTypes.checkbox,
                                            }}/>
                                            <FormRow field={{
                                                rowSize: formRowSizes.mid,
                                                label: 'customerportal.correction_drawings.release.production_release',
                                                multiline: true,
                                                disabled: false,
                                                id: 'release.production_release',
                                                rules: {
                                                    required: {
                                                        value: true,
                                                        message: 'customerportal.form.validation.checkbox.required',
                                                    },
                                                },
                                                type: formRowTypes.checkbox,
                                            }}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Alert severity={'info'}>
                                            <AlertTitle>{t('customerportal.correction_drawings.release.related_references')}</AlertTitle>
                                            <Typography
                                                variant={'body2'}>{t('customerportal.item_number')}: {correctionDrawing.itemNumber}</Typography>
                                            <Typography
                                                variant={'body2'}>{t('customerportal.order')}: {correctionDrawing.originDocumentNumber}</Typography>
                                            <Typography
                                                variant={'body2'}>{t('customerportal.date')}: {processCreationDateString}</Typography>
                                            <Typography
                                                variant={'body2'}>{t('customerportal.debtor_number')}: {correctionDrawing.debtorNumber}</Typography>
                                            <Typography
                                                variant={'body2'}>{t('customerportal.correction_drawings.your_ordernumber')} {correctionDrawing.clientOrderNumber}</Typography>
                                        </Alert>
                                    </Grid>
                                </>
                            }
                            <FormRow field={{
                                rowSize: formRowSizes.full,
                                label: 'customerportal.form.comments',
                                multiline: true,
                                disabled: false,
                                id: 'release.comments',
                                rules: {},
                                rows: 3,
                                type: formRowTypes.text,
                            }}/>
                            <Grid item xs={12}>
                                <LoadingButton variant={'contained'}
                                               loading={false}
                                               disableElevation={true}
                                               disabled={correctionDrawingSending}
                                               color={'primary'}
                                               onClick={handleBack}
                                               type={'submit'}
                                               sx={{
                                                   marginRight: {
                                                       sm: 0.625,
                                                   },
                                                   marginBottom: {
                                                       xs: 0.625,
                                                       sm: 0,
                                                   },
                                               }}
                                >
                                    <Typography>{t('customerportal.back')}</Typography>
                                </LoadingButton>
                                <LoadingButton variant={'contained'}
                                               loading={correctionDrawingSending}
                                               disableElevation={true}
                                               color={'secondary'}
                                               type={'submit'}
                                >
                                    <Typography>{t(`customerportal.correction_drawings.send.${(formData.layout.correct === 'correct' && formData.description.correct === 'correct') ? 'correct' : 'incorrect'}`)}</Typography>
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </Grid>
        </Grid>
    );
}

export default CorrectionDrawingRelease;
