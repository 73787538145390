import Box from "@mui/material/Box";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormSection from "../form/FormSection";
import FormRow from "../form/FormRow";
import {formRowSizes} from "../../config/form/formRowSizes";
import {formRowTypes} from "../../config/form/formRowTypes";
import React from "react";
import {useTranslation} from "react-i18next";

function AdditionalAddresses({addresses, addressType}) {
    const {t} = useTranslation();
    return (
        <Box sx={{
            marginTop: 2
        }}>
            <Accordion sx={{
                marginTop: 1
            }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant={'subtitle1'}>{t(`customerportal.account.address.${addressType}.additional_addresses`)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {addresses.map((address, index) => (
                        <Box key={index} sx={{
                            mb: index === addresses.length - 1 ? 0 : 3,
                        }}>
                            <Typography variant={'subtitle1'}
                                        sx={{
                                            mb: 0.5
                                        }}>
                                {address.addressName}
                            </Typography>
                            <FormSection>
                                <FormRow field={{
                                    rowSize: formRowSizes.full,
                                    label: 'customerportal.form.street',
                                    defaultValue: address.street,
                                    disabled: true,
                                    id: 'street',
                                    type: formRowTypes.text
                                }}/>

                                {address.addressAddition &&
                                    <FormRow field={{
                                        rowSize: formRowSizes.full,
                                        label: 'customerportal.form.addressAddition',
                                        defaultValue: address.addressAddition,
                                        disabled: true,
                                        id: 'address-addition',
                                        type: formRowTypes.text
                                    }}/>
                                }

                                <FormRow field={{
                                    rowSize: formRowSizes.full,
                                    label: 'customerportal.form.zip',
                                    defaultValue: address.zip,
                                    disabled: true,
                                    id: 'zip',
                                    type: formRowTypes.text
                                }}/>

                                <FormRow field={{
                                    rowSize: formRowSizes.full,
                                    label: 'customerportal.form.place',
                                    defaultValue: address.place,
                                    disabled: true,
                                    id: 'place',
                                    type: formRowTypes.text
                                }}/>

                                <FormRow field={{
                                    rowSize: formRowSizes.full,
                                    label: 'customerportal.form.country',
                                    defaultValue: address.country,
                                    disabled: true,
                                    id: 'country',
                                    type: formRowTypes.text
                                }}/>
                            </FormSection>
                        </Box>
                    ))}
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default AdditionalAddresses;