import React, {useState, useEffect} from 'react';
import {routeLayoutRelease} from '../../config/routeItems';
import PageWrapper from '../utils/PageWrapper';
import {Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from 'react-redux';
import CorrectionDrawingLayout from '../correctionDrawingsDetail/CorrectionDrawingLayout';
import CorrectionDrawingDescription from '../correctionDrawingsDetail/CorrectionDrawingDescription';
import CorrectionDrawingRelease from '../correctionDrawingsDetail/CorrectionDrawingRelease';
import CorrectionDrawingStepper from '../correctionDrawingsDetail/CorrectionDrawingStepper';
import {resetCorrectionDrawingDetail, resetCorrectionDrawingDetailError} from '../../redux/slices/azure';
import {useParams} from 'react-router-dom';
import {getContactPersons, getCorrectionDrawingDetail} from '../../redux/actions/azure';
import {useMsal} from '@azure/msal-react';
import Api from '../../api/Api';
import CorrectionDrawingDetailSkeleton from '../skeletons/CorrectionDrawingDetailSkeleton';
import ApiErrorSnackbar from '../utils/ApiErrorSnackbar';
import CorrectionDrawingSuccess from '../correctionDrawingsDetail/CorrectionDrawingSuccess';
import moment from 'moment';

function LayoutReleaseDetail() {
    const [steps, setSteps] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [internalLoading, setInternalLoading] = useState(true);
    const contactPersons = useSelector((state) => state.azure.contactPersons);
    const [formData, setFormData] = useState({
        layout: undefined,
        description: undefined,
        release: undefined,
    });
    const dispatch = useDispatch();
    const correctionDrawingDetail = useSelector((state) => state.azure.correctionDrawingDetail);
    const {correctionDrawingId} = useParams();
    const maxSteps = steps ? steps.length : 0;
    const {instance, accounts} = useMsal();
    const {t, i18n} = useTranslation();

    const processCreationDateString = (correctionDrawingDetail.item && correctionDrawingDetail.item.clientDate) ? moment(correctionDrawingDetail.item.clientDate).format('DD.MM.YYYY') : null;

    useEffect(() => {
        if (correctionDrawingId) {
            dispatch(resetCorrectionDrawingDetail());

            Api.acquireToken(instance, accounts[0]).then((res) => {
                setInternalLoading(false);
                dispatch(getCorrectionDrawingDetail(correctionDrawingId));
            });
        }

        if (contactPersons.length === 0) {
            Api.acquireToken(instance, accounts[0]).then(() => {
                dispatch(getContactPersons(i18n.language));
            });
        }

        if(!steps) {
            setSteps([
                {
                    key: 'layout',
                    label: 'customerportal.correction_drawings.form.steps.layout',
                    component: (props) => <CorrectionDrawingLayout activeStep={props.activeStep}
                                                                   maxSteps={props.maxSteps}
                                                                   handleBack={props.handleBack}
                                                                   handleNext={props.handleNext}
                                                                   formData={props.formData}
                    />,
                },
                {
                    key: 'description',
                    label: 'customerportal.correction_drawings.form.steps.description',
                    component: (props) => <CorrectionDrawingDescription activeStep={props.activeStep}
                                                                        maxSteps={props.maxSteps}
                                                                        handleBack={props.handleBack}
                                                                        handleNext={props.handleNext}
                                                                        formData={props.formData}
                    />,
                },
                {
                    key: 'release',
                    label: 'customerportal.correction_drawings.form.steps.release',
                    component: (props) => <CorrectionDrawingRelease activeStep={props.activeStep}
                                                                    maxSteps={props.maxSteps}
                                                                    handleBack={props.handleBack}
                                                                    handleNext={props.handleNext}
                                                                    formData={props.formData}
                    />,
                },
            ]);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (correctionDrawingDetail.sentSuccess) {
            setActiveStep(maxSteps);
        }
        // eslint-disable-next-line
    }, [correctionDrawingDetail.sentSuccess]);

    const handleStepSubmit = (data, datasetName) => {
        const tempFormData = formData;
        tempFormData[datasetName] = data;
        setFormData(tempFormData);
        handleNext();
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepBack = (index) => {
        setActiveStep(index);
    };

    useEffect(() => {
        document.querySelector('main').scrollTo(0,0)
    }, [activeStep]);

    return (
        <PageWrapper siteTitle={'customerportal.correction_drawings.detail_title'}
                     backRoute={routeLayoutRelease}
                     desktopSiteTitleDisabled={true}
        >
            {correctionDrawingDetail.error &&
                <ApiErrorSnackbar open={!!correctionDrawingDetail.error}
                                  errorMessage={correctionDrawingDetail.error.errorMessage}
                                  handleClose={() => dispatch(resetCorrectionDrawingDetailError())}
                />
            }
            {correctionDrawingDetail.loading || internalLoading ?
                <CorrectionDrawingDetailSkeleton/> : correctionDrawingDetail.item ?
                    <>
                        <Box>
                            <Typography
                                variant={'h2'}>{t('customerportal.order')} {correctionDrawingDetail.item.originDocumentNumber}, {t('customerportal.item_number')} {correctionDrawingDetail.item.itemNumber}</Typography>
                            <Typography variant={'h3'}
                                        sx={{
                                            marginTop: 0.375,
                                            display: {
                                                xs: 'none',
                                                sm: 'block',
                                            },
                                        }}
                            >
                                {correctionDrawingDetail.item.clientOrderNumber && <>{t('customerportal.correction_drawings.your_ordernumber')} {correctionDrawingDetail.item.clientOrderNumber},&nbsp;</>}
                                {correctionDrawingDetail.item.clientItemNumber && <>{t('customerportal.correction_drawings.your_itemnumber')} {correctionDrawingDetail.item.clientItemNumber},&nbsp;</>}
                                {correctionDrawingDetail.item.clientDrawingNumber && <>{t('customerportal.correction_drawings.your_drawingnumber')} {correctionDrawingDetail.item.clientDrawingNumber}</>}
                            </Typography>
                            <Typography variant={'subtitle2'}
                                        sx={{
                                            marginTop: 0.375,
                                            display: {
                                                xs: 'block',
                                                sm: 'none',
                                            },
                                        }}
                            >
                                {correctionDrawingDetail.item.clientOrderNumber && <>{t('customerportal.correction_drawings.your_ordernumber')} {correctionDrawingDetail.item.clientOrderNumber}<br/></>}
                                {correctionDrawingDetail.item.clientItemNumber && <>{t('customerportal.correction_drawings.your_itemnumber')} {correctionDrawingDetail.item.clientItemNumber}<br/></>}
                                {correctionDrawingDetail.item.clientDrawingNumber && <>{t('customerportal.correction_drawings.your_drawingnumber')} {correctionDrawingDetail.item.clientDrawingNumber}</>}
                            </Typography>
                            <Typography variant={'h3'}
                                        sx={{
                                            marginTop: {
                                                xs: 0.625,
                                                sm: 1.75,
                                            },
                                            typography: {
                                                xs: 'subtitle2',
                                                sm: 'h3',
                                            },
                                        }}
                                        color={'grey.500'}
                            >
                                {processCreationDateString}
                            </Typography>
                        </Box>
                        <Grid container>
                            <Grid item md={12} lg={7}>
                                <CorrectionDrawingStepper activeStep={activeStep} steps={steps} disabled={correctionDrawingDetail.sentSuccess} handleBack={(i) => handleStepBack(i)}/>
                            </Grid>
                        </Grid>
                        {correctionDrawingDetail.sentSuccess ?
                            <CorrectionDrawingSuccess formData={{...formData}}/> :
                            <Box sx={{
                                marginTop: 2,
                                marginBottom: 4,
                            }}>
                                {steps && steps[activeStep ? activeStep : 0].component({
                                    handleNext: handleStepSubmit,
                                    handleBack: handleBack,
                                    activeStep: activeStep,
                                    maxSteps: maxSteps,
                                    formData: {...formData},
                                })}
                            </Box>
                        }
                    </> :
                    'no item found'
            }
        </PageWrapper>
    );
}

export default LayoutReleaseDetail;