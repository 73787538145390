import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Api from '../../api/Api';
import {getCorrectionDrawings, getCorrectionDrawingsCount} from '../../redux/actions/azure';
import {useMsal} from '@azure/msal-react';
import CorrectionDrawingsItem from './CorrectionDrawingsItem';
import {Grid, TextField, Typography, Pagination} from '@mui/material';
import {useTranslation} from 'react-i18next';
import CorrectionDrawingsSkeletonList from '../skeletons/CorrectionDrawingsSkeletonList';
import SearchFieldSkeleton from '../skeletons/SearchFieldSkeleton';
import {SearchOutlined} from '@mui/icons-material';
import {correctionDrawingsPaginationCount} from '../../config/paginationConfig';
import searchFunc from '../../utils/searchFunc';
import {useDebouncedEffect} from '../../hooks/useDebouncedEffect';

function CorrectionDrawingsList() {
    const correctionDrawings = useSelector(state => state.azure.correctionDrawings);
    const items = useSelector(state => state.azure.correctionDrawings.items);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [internalLoading, setInternalLoading] = useState(true);
    const [searchValueInit, setSearchValueInit] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(1);
    const [renderedCorrectionDrawings, setRenderedCorrectionDrawings] = useState(items);
    const {instance, accounts} = useMsal();

    useEffect(() => {
        setRenderedCorrectionDrawings(correctionDrawings.items);

        Api.acquireToken(instance, accounts[0]).then((res) => {
            dispatch(getCorrectionDrawingsCount());
            dispatch(getCorrectionDrawings());
            setInternalLoading(false);
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setRenderedCorrectionDrawings(items);
        // eslint-disable-next-line
    }, [items]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    useDebouncedEffect(() => {
        if (!searchValueInit) {
            setSearchValueInit(true);
            return;
        }

        if (searchValue) {
            if (searchValue.length > 3) {
                setPage(1);
                setRenderedCorrectionDrawings(searchFunc(
                    items,
                    searchValue.trim(),
                    [
                        'originDocumentNumber',
                        'itemNumber',
                        'clientOrderNumber',
                        'clientItemNumber',
                        'clientDrawingNumber',
                        'clientItemDescription',
                    ]),
                );
            } else {
                if (items) {
                    setRenderedCorrectionDrawings(items);
                }
            }
        } else {
            if (items) {
                setRenderedCorrectionDrawings(items);
            }
        }
    }, [searchValue], 500);

    const handleSearchInput = (event) => {
        setSearchValue(event.target.value);
    };

    return (
        <Grid container spacing={0.625}>
            {(correctionDrawings.loading || internalLoading) ?
                <>
                    <SearchFieldSkeleton/>
                    <CorrectionDrawingsSkeletonList count={correctionDrawingsPaginationCount}/>
                </> :
                (
                    (correctionDrawings.items && correctionDrawings.items.length > 0) ?
                        <>
                            <Grid item xs={12} sx={{
                                marginBottom: 3,
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}>
                                <SearchOutlined sx={{
                                    marginRight: 1,
                                    color: 'primary',
                                }}/>
                                <TextField label={t('customerportal.form.search')}
                                           value={searchValue}
                                           variant="standard"
                                           onChange={handleSearchInput}
                                />
                            </Grid>
                            {(renderedCorrectionDrawings && renderedCorrectionDrawings.length > 0) ? renderedCorrectionDrawings.slice(correctionDrawingsPaginationCount * (page - 1), correctionDrawingsPaginationCount * page).map((correctionDrawing) => (
                                    <CorrectionDrawingsItem correctionDrawing={correctionDrawing}
                                                            key={correctionDrawing.entryNumber}/>
                                )) :
                                <Grid item xs={12}>
                                    <Typography variant={'body1'}>{t('customerportal.correction_drawings.no_items')}</Typography>
                                </Grid>
                            }
                            {renderedCorrectionDrawings && renderedCorrectionDrawings.length > correctionDrawingsPaginationCount &&
                                <Pagination
                                    count={Math.ceil(renderedCorrectionDrawings.length / correctionDrawingsPaginationCount)}
                                    disabled={correctionDrawings.loading}
                                    sx={{
                                        marginTop: 3,
                                    }}
                                    page={page}
                                    onChange={handleChange}
                                    color={'primary'}
                                />
                            }
                        </> :
                        <Grid item xs={12}>
                            <Typography variant={'body1'}>{t('customerportal.correction_drawings.no_items')}</Typography>
                        </Grid>
                )
            }
        </Grid>
    );
}

export default CorrectionDrawingsList;
