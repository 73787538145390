import React, { useEffect } from 'react';
import Api from '../../api/Api';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { getContactPersons } from '../../redux/actions/azure';
import ContactPersonItem from './ContactPersonItem';
import { Grid } from '@mui/material';
import ContactPersonSkeletonList from '../skeletons/ContactPersonSkeletonList';

function ContactPersonList({selection, bigImg, skeletonCount = 3, col, showHeadlines}) {
    const {instance, accounts} = useMsal();
    const contactPersons = useSelector((state) => state.azure.contactPersons);
    const dispatch = useDispatch();

    useEffect(() => {
        if (contactPersons.length === 0) {
            Api.acquireToken(instance, accounts[0]).then(() => {
                dispatch(getContactPersons());
            });
        }
        // eslint-disable-next-line
    }, []);

    if (contactPersons.length > 0) {
        if (selection) {
            const selectedPerson = contactPersons.filter((person) => selection.includes(person.type));

            if (selectedPerson.length > 0) {
                return (
                    <Grid container
                          spacing={2.5}
                    >
                        {selectedPerson.map((person, i) => (
                            <ContactPersonItem key={i} index={i} person={person} bigImg={bigImg} col={col}
                                               showHeadline={showHeadlines}/>
                        ))}
                    </Grid>
                );
            }
        } else {
            return (
                <Grid container
                      spacing={1}
                >
                    {contactPersons.map((person, i) => (
                        <ContactPersonItem key={i} index={i} person={person} bigImg={bigImg} col={col}
                                           showHeadline={showHeadlines}/>
                    ))}
                </Grid>
            );
        }
    } else {
        return (
            <ContactPersonSkeletonList count={skeletonCount} bigImg={bigImg} col={col}/>
        );
    }
}

export default ContactPersonList;
