import React from 'react';
import {Grid, Paper, Skeleton} from '@mui/material';

function OrderSkeletonItem() {
    return (
        <Grid item xs={12}>
            <Paper elevation={1} sx={{
                paddingY: 0.75,
                paddingX: 1,
                backgroundColor: 'common.white',
            }}>
                <Grid container spacing={1} alignItems={{lg: 'center'}} sx={{paddingY: 1}}>

                    <Grid item xs={10} md={11}>

                        <Grid spacing={1} container>

                            <Grid item xs={12} md={8}>
                                <Skeleton animation="wave"/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Skeleton animation="wave"/>
                            </Grid>

                        </Grid>

                    </Grid>

                    <Grid item xs={2} md={1} sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Skeleton variant="circular"
                                  animation={'wave'}
                                  sx={{
                                      width: {
                                          xs: 24,
                                      },
                                      height: {
                                          xs: 24,
                                      },
                                  }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default OrderSkeletonItem;
